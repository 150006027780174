import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { Table } from "../../components/Table";
import ConfirmPopup from "../../layout/ConfirmPopup";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import { StoreClass } from '../../store/Store';
import { ShowToast } from "../../services/UserActions";
import { SaveOrgFile, GetOrgFiles, DeleteOrgFileByFileId } from "../../services/OrgServices";
import { removeSuccessStatus } from "../../services/ContactsService";
import Moment from 'moment';
import Editor from "../../components/Editor";
import { GetProfileAvatarsList } from "../../services/MediaService";


interface formError {
    message: string;
}

interface OrgFile {
    FileId: number;
    Title: string;
    File: any;
    FileUrl: string;
    FileName: string;
    Type: string;
    IsActive: boolean;
    OrgId: number;
    OrgGuid: string;
    Sequence: number;
    EmployeeGuid: string;
    selectedDate: Date | null,
    Content: string,
    BackgroundImageUrl: string,
    BackgroundImageFile: any,
}
const initialOrgFileState: OrgFile = {
    FileId: 0,
    Title: "",
    File: null,
    FileUrl: "",
    FileName: "",
    Type: "",
    IsActive: false,
    OrgId: 0,
    OrgGuid: "",
    Sequence: 0,
    EmployeeGuid: "",
    selectedDate: new Date(),
    Content: "",
    BackgroundImageUrl: "",
    BackgroundImageFile: null,
};

var deleteFileId: string;
var deleteText: string = '';
let statusTest:string = '';

interface props {
    type: string,
    createLabel: string,
    header: string,
    noDataMsg: string

}
interface CustomBlob extends Blob {
    name: string;
}

function OrgFilesComponent({ type, createLabel, header, noDataMsg }: props) {
    const dispatch = useDispatch();
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const MAXFILESIZE = process.env.REACT_APP_MAXFILESIZE as string;

    let empId = localStorage.getItem('EmpGuid') as string;
    let orgGuid = localStorage.getItem('OrgGuid') as string;
    const [errors, setErrors] = useState({} as formError);
    const errormsg = (<div className='row'> <div className="col-12"><div className="alert alert-danger text-center">
        {errors.message}
    </div></div></div>);

    const [orgFilesList, setOrgFilesList] = useState<any[]>([]);
    const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());

    const [values, setValues] = useState({} as OrgFile);
    const [showConfirmDeleteOrgFile, setShowConfirmDeleteOrgFile] = useState(false);
    const [showOrgFilePopup, setShowOrgFilePopup] = useState(false);
    const [isSaveInprogress, setSaveInprogress] = useState(false);
    const [showContentBackgroundModal, setShowContentBackgroundModal] = useState(false);
    const formattedDate = process.env.REACT_APP_DATE_FORMAT

    const [selectedBackgroundImage, setSelectedBackgroundImage] = useState('' as string);
    const [selectedBackgroundImagePopUp, setSelectedBackgroundImagePopUp] = useState('' as string);

    const maxSequence = 1000;

    const handleCloseOrgFilePopup = () => {
        setValues(initialOrgFileState);
        setErrors({ message: '' });
        setShowOrgFilePopup(false);
        setSaveInprogress(false);
        setSelectedBackgroundImage("");
    }
    const handleShowOrgFilePopup = () => {
        setValues({ OrgGuid: orgGuid, EmployeeGuid: empId, Type: type } as OrgFile);
        setShowOrgFilePopup(true);
    }

    useEffect(() => {
        setValues({ OrgGuid: orgGuid, EmployeeGuid: empId, Type: type } as OrgFile);
        dispatch(GetOrgFiles(orgGuid, type, null) as any);
    }, [])

    useEffect(() => {
        if (user.orgFiles && user.orgFiles.length > 0) {
            user.orgFiles.forEach((orgFile: any, i: any) => {
                orgFile.TotalCount = user.orgFiles?.length, orgFile.RowNumber = i + 1
            });

            // setOrgFilesList(user.orgFiles.filter((orgFile: any) => 
            //     (orgFile.RowNumber <= 8)));
            setOrgFilesList(user?.orgFiles?.filter((orgFile: any) =>
                orgFile.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
                && orgFile.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
            );
        } else
            setOrgFilesList([]);
    }, [user.orgFiles])

    useEffect(() => {
        console.log(user.orgFiles)
        if (user.orgFiles && user.orgFiles.length > 0) {
            const orgFiles = user.orgFiles.filter((orgFile: any) =>
                (orgFile.Title.toLowerCase().includes(filesTableParams.Search.toLowerCase())) ||
                (orgFile.ExpiryDate !== null && new Date(orgFile.ExpiryDate).getDate() < new Date().getDate() ?  'expired'.startsWith(filesTableParams.Search.toLowerCase()) // Check for "exp" using includes()              
                : orgFile.IsActive
                    ? 'published'.startsWith(filesTableParams.Search.toLowerCase()) // Check for "pub" using includes()
                    : 'unpublished'.startsWith(filesTableParams.Search.toLowerCase()) // Check for "unp" using includes()
                )    
            );

            orgFiles.forEach((orgFile: any, i: any) => {
                orgFile.TotalCount = orgFiles.length, orgFile.RowNumber = i + 1
            });

            setOrgFilesList(orgFiles.filter((orgFile: any) =>
                orgFile.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
                && orgFile.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
            );
        }
    }, [filesTableParams])

    const handleChange = (e: any) => {
        if(e.target)
            e.target.value=e.target.value.trimStart();
        let name: any = '';
        let value: any = '';
        if (typeof e.persist === 'function') {
            e.persist();
        }
        const target = e.target || e;
        if (e.target) {
            // const { name, value } = target;
            if (target.name === "Sequence") {
                name = target.name
                value = parseInt(target.value)
            } else {
                name = target.name
                value = target.value
            }
        }
        else {
            name = 'Content'
            value = target
        }
        // Check if the input field is "Sequence"
        if (name === "Sequence") {
            console.log('handle change',e.target.value)
            // Ensure the value is a number and not negative 
            if (value === '' || (!isNaN(value) && parseInt(value) >= 0)) {
                setValues(values => ({
                    ...values,
                    [name]: value
                }));
            } else {
                setValues(values => ({
                    ...values,
                    [name]: ''
                }));
            }
        } else {
            // Handle other input fields
            setValues(values => ({
                ...values,
                [name]: value || null
            }));
        }

        setErrors({ message: '' });
    };


    function fileSelectHandler(e: any) {
        setErrors({ message: '' });
        if (e.currentTarget.files.length > 0 && (e.currentTarget.files[0].size / 1024) / 1024 > Number(MAXFILESIZE)) {
            setErrors({ message: "File maximum size " + MAXFILESIZE + " MB" });
            e.target.value = null;
        }
        e.preventDefault();
        if (e.currentTarget.files.length > 0) {
            setValues({ ...values, File: e.currentTarget.files[0], FileName: e.currentTarget.files[0].name });
        }
        else {
            setValues(values => ({
                ...values,
                File: '', FileName: ''
            }));
        }
    }
    function backgroundImageSelectHandler(e: any) {
        setErrors({ message: '' });
        if (e.currentTarget.files.length > 0 && (e.currentTarget.files[0].size / 1024) / 1024 > Number(MAXFILESIZE)) {
            setErrors({ message: "File maximum size " + MAXFILESIZE + " MB" });
            e.target.value = null;
        }
        e.preventDefault();
        if (e.currentTarget.files.length > 0) {
            setValues({ ...values, BackgroundImageUrl: "", BackgroundImageFile: e.currentTarget.files[0] });
            setSelectedBackgroundImage(URL.createObjectURL(e.target.files[0]));
            setShowContentBackgroundModal(false);
        }
    }

    function validateData() {
        let errrs = [] as any;
        let required = [] as any;
        setErrors({ message: '' });

        if (!values.Title || values.Title.length === 0) {
            required.push('Title');
        }

        if ((!values.FileName || values.FileName.length === 0) && !values.Content) {
            required.push('File or Content');
        }

        if (!values.Sequence || values.Sequence === 0) {
            required.push('Sequence');
        }

        if (!values.selectedDate && type === 'bulletin') {
            required.push('Expiry Date');
        }

        if (errrs.length > 0 || required.length > 0) {
            var error: string = '';
            if (errrs.length > 1)
                error = errrs.join(', ') + " are invalid!";
            else if (errrs.length == 1)
                error = errrs[0] + " is invalid!";
            if (required.length > 0)
                error = "Please enter data for " + required.join(', ') + '!\n\r' + error;

            setErrors({ message: error });
            setSaveInprogress(false);
            return false;
        }
        else
            return true;
    }

    const saveData = async (event: any) => {
        event.preventDefault();
        setSaveInprogress(true);
        if (validateData()) {
            const formData = new FormData();

            if (values.FileId != undefined) {
                formData.append('FileId', values.FileId.toString());
            }

            formData.append('OrgGuid', values.OrgGuid);
            formData.append('Title', values.Title);
            formData.append('File', values.File || "");
            formData.append('FileName', values.FileName || "");
            formData.append('Sequence', values.Sequence.toString());
            formData.append('Type', values.Type);
            formData.append('EmployeeGuid', values.EmployeeGuid);
            formData.append('Content', values.Content || "");
            formData.append('BackgroundImageUrl', values.BackgroundImageUrl || "");
            formData.append('BackgroundImageFile', values.BackgroundImageFile || "");
            let selectedUTCDate = values.selectedDate;
            if (selectedUTCDate) {
                selectedUTCDate.setUTCHours(0);
                selectedUTCDate.setUTCMinutes(0);
                selectedUTCDate.setUTCSeconds(0);
                selectedUTCDate.setUTCMilliseconds(0);

                const UTCDate = Date.UTC(
                    selectedUTCDate.getUTCFullYear(),
                    selectedUTCDate.getUTCMonth(),
                    selectedUTCDate.getUTCDate(),
                    selectedUTCDate.getUTCHours(),
                    selectedUTCDate.getUTCMinutes()
                );

                let localDate = new Date(UTCDate); // Add local timezone offset
                localDate.setDate(localDate.getDate() + 1);

                formData.append('ExpiryDate', localDate.toISOString().split('T')[0]);

            } else {
                formData.append('ExpiryDate', '');
            }
            // newEventObj.offset = new Date().getTimezoneOffset();
            dispatch(SaveOrgFile(formData) as any);
            setTimeout(() => {
                setSaveInprogress(false);
            }, 1000);
        }
         deleteText = '';
         statusTest = '';
    }


    useEffect(() => {
       let toast;
        if (user.success && (user.success == "orgfile success" || user.success == "orgfile updated successfully.")) {
            console.log("user.success", user.success)
            if(values.FileId > 0){
                statusTest = "Updated"
               }else{
                statusTest = "created"
               }

               // Declare toast in the outer scope              
               if(deleteText.length > 0) {                 
                   toast = {
                    type: 'Message',
                    title: 'Status',
                    message: `Document ${deleteText} successfully.`
                };
               }

               if (deleteText.length == 0 && statusTest.length > 0) {
                toast = {
                    type: 'Message',
                    title: 'Status',
                    message: `Document ${statusTest} successfully.`
                };
           }     
            handleCloseOrgFilePopup();
            dispatch(ShowToast(toast) as any);
            setValues({ OrgGuid: orgGuid, EmployeeGuid: empId, Type: type } as OrgFile);
            dispatch(GetOrgFiles(orgGuid, type, null) as any);
            dispatch(removeSuccessStatus() as any);
            deleteText = '';
            statusTest = '';
        }
    }, [user.success]);

    useEffect(() => {
        setErrors({ message: user.error?.message });
    }, [user.error]);

    const editOrgFile = (e: any, row: any) => {
        let selectedDate = null;
        if (row.ExpiryDate && row.ExpiryDate.trim() !== '') {
            const expiryDate = new Date(row.ExpiryDate);
            if (!isNaN(expiryDate.getTime())) {
                selectedDate = expiryDate;
            }
        }
        if (row.BackgroundImageUrl) {
            setSelectedBackgroundImage(row.BackgroundImageUrl)
        }

        setValues({
            OrgGuid: orgGuid,
            EmployeeGuid: empId,
            Type: 'Training',
            FileId: row.FileId,
            Title: row.Title,
            FileName: row.FileName,
            Sequence: row.Sequence.toString(),
            selectedDate: selectedDate,
            Content: row.Content,
            BackgroundImageUrl: row.BackgroundImageUrl
        } as OrgFile);
        setShowOrgFilePopup(true);
    };

    const handleShowDeleteOrgFile = (e: any, row: any) => {
        deleteFileId = row.FileId;
        deleteText = row.IsActive ? "Unpublish" : "Publish";
        setShowConfirmDeleteOrgFile(!showConfirmDeleteOrgFile);
    }

    const handleCloseDeleteOrgFile = (e: any) => {
        setShowConfirmDeleteOrgFile(!showConfirmDeleteOrgFile);
    }
    const deleteOrgFile = (e: any) => {
        dispatch(DeleteOrgFileByFileId(deleteFileId) as any);
        setShowConfirmDeleteOrgFile(!showConfirmDeleteOrgFile);
    }

    const handlingChangeDate = (date: any) => {
        setValues({ ...values, selectedDate: date })
    }

    const media = useSelector((store: any) => store.MediaReducer);

    useEffect(() => {

        dispatch(GetProfileAvatarsList('Background') as any);
    }, [])

    const handleSetBackgroundModal = (e: any) => {
        setShowContentBackgroundModal(true);

    }
    const handleSetBackgroundClose = () => {
        setShowContentBackgroundModal(false);
    }

    const selectBackgroundImage = (image: string) => {
        setSelectedBackgroundImage(image);

    }

    const handleSelectedBackgroundImage = () => {
        setValues(values => ({
            ...values,
            BackgroundImageUrl: selectedBackgroundImagePopUp
        }));
        setSelectedBackgroundImage(selectedBackgroundImagePopUp)
        setShowContentBackgroundModal(false);
    }


    function headerRender() {
        return (
            <tr>
                <th>Title</th>
                <th>File name</th>
                <th>Sequence</th>
                <th>{type === "bulletin" ? "Expiry date" : "Created date"}</th>
                <th>Status</th>
                <th className="mobile-none"></th>
            </tr>
        );
    }

    function rowRender(row: any, index: number) {
        return (
            <tr key={index}>
                {/* <td>{row.Title ?? ''}</td> */}
                <td dangerouslySetInnerHTML={{ __html: row.Title ?? '' }}></td>
                <td style={{ width: "320px" }}>{row.FileName ?? ''}</td>
                <td>{row.Sequence ?? ''}</td>
                <td>{type === "bulletin" ? (row.ExpiryDate !== null ? Moment(row.ExpiryDate).format(formattedDate) : '') : Moment(row.CreatedDt).format(formattedDate)}</td>
                {/* <td>{row.IsActive ? "Active" : "Inactive"}</td> */}
                <td>{row.ExpiryDate !== null && new Date(row.ExpiryDate) < new Date(new Date().toDateString())
                    ? "Expired"
                    : (row.IsActive) ? "Published" : "Unpublished"
                }
                </td>
                <td className="text-right dropdown mobile-none">
                    <svg id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                    </svg>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                        {row.IsActive ? <a onClick={e => editOrgFile(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Edit</a> : null}
                        <a onClick={e => handleShowDeleteOrgFile(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> {row.IsActive ? "Unpublish" : "Publish"}</a>
                    </div>
                </td>
            </tr>
        );
    }

    return (<>
        <ConfirmPopup show={showConfirmDeleteOrgFile} close={handleCloseDeleteOrgFile} okHandle={deleteOrgFile} message={"Do you want to " + deleteText.toLowerCase() + " this file?"} />
        <section className="myProfilePage position-relative d-flex">
            <div className="container-fluid">
                <div className="row mt-4 justify-content-center">
                    <Table
                        id='orgFiles'
                        data={orgFilesList || []}
                        headerRender={headerRender}
                        rowRender={rowRender}
                        onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
                        onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
                        createLabel={createLabel}
                        rootUrl="/admin"
                        onCreate={(e: any) => handleShowOrgFilePopup()}
                        header={header}
                        subHeader=""
                        noDataMsg={noDataMsg}>
                    </Table>
                </div>
            </div>
            <Modal show={showOrgFilePopup} onHide={handleCloseOrgFilePopup} backdrop="static">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="modal-title h6 color-white">Add/Edit File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errors.message && errormsg}
                    <div className="row">
                        <div className="col-sm-10 m-auto popup-settings">
                            <div className="card loginBox addTeamMemberBox mt-5 mb-5">
                                <div className="card-body">
                                    <div className="row m-0">
                                        <div className="col-12  text-white pl-0 mt-2">
                                            <label>Title <sup>*</sup></label>
                                        </div>
                                        <div className="col-12 row m-0 p-0">
                                            <input name="Title" value={values.Title || ''} onChange={handleChange} required maxLength={100} type="text" className="checkin-input SuteInputPrimary" style={{ height: '35px' }} />

                                        </div>

                                        {type === "bulletin" ? (
                                            <>
                                                <div className="col-12 text-white pl-0 mt-2 d-flex justify-content-between align-items-center mt-3 pr-0">
                                                    <h6 className="mt-3">Content</h6>
                                                    <button type="button" className="btn btn-SeaGreen add-background-image" onClick={handleSetBackgroundModal}>
                                                        Add background image
                                                    </button>
                                                </div>
                                                <div className="col-12 row m-0 p-0 mt-2 manage-bulletin-editor">
                                                    <Editor
                                                        backgroundImage={selectedBackgroundImage}
                                                        value={values.Content || ""}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </>
                                        ) : null}

                                        <div className="col-12  text-white pl-0 mt-2">
                                            <label>File <sup>*</sup></label>
                                        </div>
                                        <div className="col-12 row m-0 p-0 mt-2">
                                            <div className="col-10 pl-0">
                                                <input name="FileName" readOnly value={values.FileName || ''} type="text" className="checkin-input SuteInputPrimary" />
                                            </div>
                                            <div className="col-2 p-0">
                                                <button type="button" className="upload-btn">
                                                    <span>+</span><span className="ml-1"><img src="assets/media/images/file-icon.png" />
                                                        <input type="file" accept="image/*,video/*,.pdf" className="inputWithPhoto" id="orgfile" onChange={fileSelectHandler} onClick={(e: any) => e.target.value = null}/></span>
                                                </button>
                                            </div>
                                        </div>
                                        {type === "bulletin" ?
                                            <div className="form-group mb-1 col-12 p-0">                                               
                                                <label className="col-form-label" style={{ color: "white" }}>Expiry date:  <sup>*</sup></label>
                                                <DatePicker className="SuteInputPrimary checkin-input  h40px"
                                                    selected={values.selectedDate}
                                                    onChange={handlingChangeDate}
                                                    showIcon
                                                    toggleCalendarOnIconClick                                 
                                                    name="startDate"
                                                    dateFormat="dd-MM-yyyy"
                                                    minDate={new Date()}
                                                // placeholderText="Select Date"
                                                />
                                            </div> : null}
                                        <div className="col-12  text-white pl-0 mt-2">
                                            <label>Sequence <sup>*</sup></label>
                                        </div>
                                        <div className="col-12 row m-0 p-0">
                                            <input name="Sequence" min={1} max={100} value={values.Sequence == 0 ? "" : values.Sequence} onChange={handleChange} type="number" className="checkin-input SuteInputPrimary" style={{ height: '35px' }} />
                                        </div>
                                        {/* <div className="col-12 row m-0 p-0">
                                                <select
                                                    name="Sequence"
                                                    value={values.Sequence || ''}
                                                    onChange={handleChange}
                                                    className="checkin-input SuteInputPrimary"
                                                    style={{ height: '35px' }}
                                                >
                                                    <option value="">Select Sequence</option>
                                                    {[...Array(maxSequence)].map((_, index) => (
                                                    <option key={index + 1} value={index + 1}>
                                                        {index + 1}
                                                    </option>
                                                    ))}
                                                </select>
                                         </div> */}
                                    </div>
                                    <div className="form-group mt-3">
                                        <button onClick={saveData} disabled={isSaveInprogress} type='button' className="btn btn-SeaGreen btn-block  mt-2 mb-2">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showContentBackgroundModal} onHide={handleSetBackgroundClose}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="modal-title text-light small">Set Background Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <ul className="content-bg-image-ul">
                            {(media.profileAvatarsList.result || []).length > 0 ? (media.profileAvatarsList.result || []).map((image: any, index: number) => (
                                <li key={index} className={image.AvatarURL === selectedBackgroundImagePopUp ? "active" : ''}>
                                    <img className="content-bg-image" src={(process.env.REACT_APP_CDN_HOST || '') + '/' + image.AvatarURL} alt={`Background ${index}`} onClick={() => setSelectedBackgroundImagePopUp(image.AvatarURL)} />
                                </li>
                            )) : null}
                        </ul>
                        <div className="text-center float-right btn-sm align-self-center small btn-SeaGreen btn position-relative">Choose Your Own
                            <input type="file" accept="image/*" className="inputWithPhoto" id="addLogo" onChange={backgroundImageSelectHandler} onClick={(e: any) => e.target.value = null}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <button type="button" style={{ width: '80px' }} className="btn btn-secondary btn-sm" onClick={handleSetBackgroundClose}>Cancel</button>
                    <button type="button" style={{ width: '80px' }} className="btn btn-SeaGreen btn-sm" onClick={handleSelectedBackgroundImage}>Ok</button>
                </Modal.Footer>
            </Modal>
        </section>
    </>
    );
}

export default OrgFilesComponent;
