import React, { useEffect, useState } from 'react';
import clsx from 'classnames'
import * as moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Flag } from './flag.svg';
import { Rating } from 'react-simple-star-rating'
import { GetCheckins, updateRating } from '../../services/CheckinsServices';
import { StoreClass } from '../../store/Store';
import { globalStore } from "../../store/global_store";
import { removeSuccessStatus } from "../../services/ContactsService";
import Moment from 'moment';

function CheckinGrid(props: any) {
    const dispatch = useDispatch();
    const [currentItem, SetcurrentItem] = useState();
    const [objCheckins, SetObjCheckins] = useState([] as any);
    const [isNew, SetIsNews] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    let empGuid = localStorage.getItem('EmpGuid') as string;
    let empId = localStorage.getItem('EmpId') as string;
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const formattedDate = process.env.REACT_APP_DATE_FORMAT 

    useEffect(() => {
            dispatch(GetCheckins(empGuid) as any);
    }, []);

    useEffect(() => {
        if (props.selectEmp > 0) {
            const checkins = user.checkins?.filter((checkin: any) =>
                checkin.EmployeeId == props.selectEmp || props.selectEmp == "0"
            );
            SetObjCheckins(checkins);
        }
    }, [props.selectEmp,user?.checkins]);

    useEffect(() => {
        if (props.selectEmp < 0) {
            const checkins = user.checkins?.filter((checkin: any) =>
                checkin.EmployeeId == empId
            );
            SetObjCheckins(checkins);
        }
        if (isNew) {
            SetIsNews(false);
            const checkinsR = user.checkins?.filter((checkin: any) =>
                user.empListReportingTo && checkin?.EmployeeId == user.empListReportingTo[0].EmployeeId
            );
            SetObjCheckins(checkinsR);
        }
    }, [user.checkins]);

    useEffect(() => {
        if (user.empListReportingTo && user.empListReportingTo.length < 0) {
            const checkinsR = user.checkins?.filter((checkin: any) =>
                user.empListReportingTo && checkin?.EmployeeId == user.empListReportingTo[0].EmployeeId
            );
            SetObjCheckins(checkinsR);
        }    
    }, [user.empListReportingTo]);
    useEffect(() => {
        if (user.success && user.success == "update rating success") {
            SetIsNews(true)
            setIsSubmitted(true)
            globalStore.showToast({
                type: 'Message',
                title: "Feedback",
                message: "Feedback updated successfully"
            });
            // dispatch(GetCheckins(empGuid) as any);
            dispatch(removeSuccessStatus() as any);
        }
    }, [user.success]);
    function fd(dateTime: string | undefined) {
        if (dateTime !== undefined) {
            return moment.utc(dateTime).local().format('D MMMM, yyyy');
        }
    }

    const handleRating = (rate: number) => {
        if (currentItem != undefined && objCheckins) {
            let body = objCheckins[currentItem];
            body.Rating = rate;
            SetIsNews(true);
            dispatch(updateRating(body) as any);
        }
    }
    
    const handleFeedBack = (e: any, index: number) => {
        const value = e.target.value;
        const updatedCheckins = [...objCheckins]; // Create a copy of the array
        updatedCheckins[index].FeedBack = value; // Update the specific feedback value
        SetObjCheckins(updatedCheckins); // Update the state with the modified array
        setIsSubmitted(false)
    };


    const Submit = (index: number, feedbackData: string) => {
        if (!isSubmitted){
            dispatch(updateRating(objCheckins[index]) as any);
        }
    }

    const onPointerEnter = (chkin: any) => { SetcurrentItem(chkin) }

    return (
        <section style={{ width: '100%' }}>
            {(objCheckins || []).length > 0 ? (objCheckins || []).map((checkin: any, index: number) => {
                return (
                    <div className={clsx("reaction-box mt-2", index % 2 != 0 ? "color-2" : "")}>
                        <div className='row justify-content-between'> <div className="col-6">
                            <h6><span className="fontsize-30 align-sub"><img width="25px" src={checkin.Emoji} /></span><span className="inner-font ml-1">{Moment(fd(checkin.CreatedDt)).format(formattedDate)}</span></h6>
                        </div>
                            <div className="col-6 text-right">
                                {props.showZero && props.showZero == 'FALSE' && checkin.Rating == 0 ? '' :
                                    <Rating readonly={props.showZero && props.showZero == 'FALSE'}
                                        initialValue={checkin.Rating}
                                        onClick={handleRating}
                                        onPointerEnter={() => { onPointerEnter(index) }}
                                    /* Available Props */
                                    />
                                }

                            </div>
                        </div>
                        <div className='row justify-content-between mb-2'>
                            <div className="col-12">
                                <h6 className="text m-0"><span>Feedback: </span></h6>
                                {props.showZero && props.showZero == 'FALSE' && checkin && checkin?.FeedBack?.length > 0 ?
                                    <div className={"container-fluid col-11 " + clsx("reaction-box mt-2", index % 2 !== 0 ? "" : "color-2")} style={{ border: checkin.FeedBack.length > 0 ? "1px solid gray" : "none", height: checkin.FeedBack.length > 0 ? "auto" : "hide" }}>
                                        <div className="row">
                                            <div className="col">
                                                <p className="text" style={{ color: "white", height: "auto", overflow: "auto" }}>
                                                    {checkin.FeedBack}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    (props.showZero && props.showZero == 'FALSE' ? null :
                                        (<div className="col-12">
                                            <div className="row">
                                                <div className="col-10 p-0"> 
                                                    <textarea className={clsx("reaction-box mt-2", index % 2 != 0 ? "" : "color-2")}
                                                        value={objCheckins[index].FeedBack}
                                                        maxLength={500}
                                                        onChange={(e) => handleFeedBack(e, index)}
                                                        style={{ "height": "80px" }}
                                                    ></textarea>
                                                </div>
                                                <div className="col-2 mt-auto">
                                                    <button onClick={() => Submit(index, objCheckins[index].FeedBack)} className="btn btn-SeaGreen font-weight-bold fonsize-14" disabled={! objCheckins[index].FeedBack ||  objCheckins[index].FeedBack?.length === 0} >Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                            </div>
                        </div>
                        <div className="col-12 row m-0 p-0">
                          <span>Plans and objectives:&nbsp;</span>
                        {(checkin.Plans || []).map((Plan: any, index: number) => {
                            return (
                                <div className={clsx("", index > 0 ? "separation-border w-100" : "")} style={{width:"78.5%"}}>
                                   
                                        <span className="inner-font"> {Plan.txt}</span>
                                        <span  className="float-right ml-2">
                                        {
                                            Plan.Flag ? <span className="uploaded-files-btn">
                                                <span className={`flag-bubble ${Plan.Flag}`} width="25px" height="25px" fill={Plan.Flag}></span>
                                                </span>
                                                : ''
                                        }
                                    </span>
                                   
                                    <span className="float-right">
                                        {
                                            Plan.FileURL ?
                                                <a href={Plan.FileURL} target='_blank'>
                                                    <span className="uploaded-files-btn">
                                                        <img src="assets/media/images/file-img.png" />
                                                    </span>
                                                </a>
                                                : ''
                                        }
                                    </span>
                                 
                                </div>
                            )
                        })}
 </div>
                        <div className="col-12 row p-0 m-0 mt-2">
                            <span>Progress updates:&nbsp;</span>

                        {(checkin.Progresses || []).map((Progress: any, index: number) => {
                            return (
                                <div className={clsx("", index > 0 ? "separation-border w-100" : "")} style={{width: "81%", marginLeft:"3px"}}>
                                     <span className="inner-font">{Progress.txt}</span>
                                     <span className="float-right ml-2">
                                        {
                                            Progress.Flag ?
                                                <span className="uploaded-files-btn">
                                                    <span className={`flag-bubble ${Progress.Flag}`} width="25px" height="25px" fill={Progress.Flag}></span>
                                                </span>
                                                : ''
                                        }
                                    </span>
                                    <span className="float-right">
                                        {Progress.FileURL ?
                                            <a href={Progress.FileURL} target='_blank'>
                                                <span className="uploaded-files-btn">
                                                    <img src="assets/media/images/file-img.png" />
                                                </span>
                                            </a>
                                            : ''
                                        }
                                    </span>
                                   
                                </div>
                            )
                        })}
                              </div>
                        <div className="col-12 row m-0 p-0">
                            <div className="col-10 p-0 mt-2">
                                <h6 className="mb-0"><span>Questions or concerns:&nbsp;</span><span className="inner-font">{checkin.Problems}</span></h6>
                            </div>
                        </div>
                    </div>
                )
            }) : <div className="col-12 text-center pt-5 text-light">No Check-ins Found</div>}
        </section>
    );
}

export default CheckinGrid;
