import { getEmpDetailsByEmpGuid, updateUserStatus, getUserStatus } from '../../services/UserActions';
import { GetSessions } from '../../services/AnalyticsService';
import { StoreClass } from '../../store/Store';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { t } from '../../i18n';
import './Dashboard.css';
import EngagementPie from './EngagementPie';
import ProductiveLineGraph from './ProductiveLineGraph';
import ShowOrgFiles from '../orgFiles/ShowOrgFiles';

const staticStatus = [
    { id: "1", color: "green", title: "Available" },
    { id: "2", color: "orange", title: "Away" },
    // { id: "3", color: "#04f1b2", title: "In Office" },
    { id: "4", color: "red", title: "Busy" },
    { id: "5", color: "gray", title: "Appear Offline" }
]
function getStatusColor(status: string) {
    const sts = staticStatus.filter((s: any) => s.title === status);
    if (sts.length > 0)
        return sts[0].color;
    return "gray";
}
function MyProfile(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const { EMP } = useSelector((state: StoreClass) => ({ EMP: state?.EmployeeReducer }));
    const dispatch = useDispatch();
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const [message, setMessage] = useState("");
    const [avgSessionLen, setAvgSessionLen] = useState('0');
    const [weekCount, setWeekCount] = useState('0');
    const [showInductionFiles, setShowInductionFiles] = useState(0);
    const location = useLocation();
    useEffect(() => {
        if (!user.empData.EmpGuid || user.empData.EmpGuid === '') {
            dispatch(getEmpDetailsByEmpGuid(empGuid) as any);
        }
        dispatch(getUserStatus(empGuid) as any);
        setMessage(user.statusMsg);
        dispatch(GetSessions(empGuid) as any);
        if (EMP.sessionTotals) {
            setWeekCount(EMP.sessionTotals.LastMonthSessions ? (EMP.sessionTotals.LastMonthSessions / 4).toFixed(2) : '0');
            if (EMP.sessionTotals.LastMonthSessionMinutes && EMP.sessionTotals.LastMonthSessionMinutes > 0) {
                const avgSesion = EMP.sessionTotals.LastMonthSessionMinutes / (EMP.sessionTotals.LastMonthSessionMinutes * 60);
                setAvgSessionLen(avgSesion.toFixed(2));
            }
        }
    }, []);
    useEffect(() => {
        setMessage(user.statusMsg);
    }, [user.statusMsg]);

    useEffect(() => {
        if (EMP.sessionTotals && EMP.sessionTotals.LastMonthSessions > 0) {
            setWeekCount(EMP.sessionTotals.LastMonthSessions ? (EMP.sessionTotals.LastMonthSessions / 4).toFixed(2) : '0');
            if (EMP.sessionTotals.LastMonthSessionMinutes && EMP.sessionTotals.LastMonthSessionMinutes > 0) {
                const avgSesion = (EMP.sessionTotals.LastMonthSessionMinutes / 60) / EMP.sessionTotals.TotalSessions;
                /*(EMP.sessionTotals.LastMonthSessionMinutes *60)*/
                setAvgSessionLen(avgSesion.toFixed(2));
            }
        }
    }, [EMP.sessionTotals])

    const changeStatus = (status: string) => {
        updateStatus(status, user.statusMsg);
    }
    const updateStatusMsg = () => {
        updateStatus(user.status, message);
    }
    const updateStatus = (sts: string, msg: string) => {
        const form = new FormData();
        if (sts && sts === 'Appear Offline')
            sts = 'Offline';
        form.append('status', sts);
        form.append('statusMessage', msg);
        form.append("empGuid", empGuid);
        console.log(form);
        dispatch(updateUserStatus(form) as any);
    }

    const openInductionFiles = () => {
        setShowInductionFiles((showInductionFiles) => showInductionFiles + 1);
    }

    return (
        <section className="myProfilePage position-relative d-flex mh-webkit min-vh-100">
            <div className="container-fluid">
                <div className="row myProfileHeader bgCadet">
                    <div className="col text-left"> <Link to="/pod"><img src="assets/media/images/sute-logo1.png" /></Link> </div>
                    <div className="col text-right">
                        <img src="assets/media/images/file-icon.png" className="doc-img" onClick={() => openInductionFiles()} />
                        {/* <svg className="mt-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" height="20">
                            <path d="M1 10.5h13m-11.5 0v-5a5 5 0 0110 0v5m-7 1.5v.5a2 2 0 104 0V12" stroke="#fff"></path>
                        </svg> */}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-9">
                        <div className="row">
                            <div className="col-4">
                                <div className="card bgCadet">
                                    <div className="card-body">
                                        <p className="mb-2 text-muted small">{t('myprofile.total_sessions')}</p>
                                        <h4 className="m-0 text-white">{EMP.sessionTotals && EMP.sessionTotals.TotalSessions ? EMP.sessionTotals.TotalSessions : 0}</h4>
                                        <p className="m-0 text-muted small"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card bgCadet">
                                    <div className="card-body">
                                        <p className="mb-2 text-muted small">{t('myprofile.avg_sessions_week')}</p>
                                        <h4 className="m-0 text-white">{weekCount}</h4>
                                        <p className="m-0 text-muted small"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card bgCadet">
                                    <div className="card-body">
                                        <p className="mb-2 text-muted small">{t('myprofile.avg_session_length')}</p>
                                        <h4 className="m-0 text-white">{avgSessionLen} Hours</h4>
                                        <p className="m-0 text-muted small"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profileDGrid">
                            <div className="card bgCadet mt-3">
                                <div className="card-body">
                                    <h6 className="mb-2 text-white">{t('myprofile.sentiment_tracker')}</h6>
                                    <div className="sentimentInput">
                                        <label>{t('myprofile.good')}</label>
                                        <div className="sentiEmojis"> 
                                            <span><img className="emoji-img mr-2" src="https://em-content.zobj.net/source/apple/391/neutral-face_1f610.png"/></span>
                                            <span><img className="emoji-img mr-2" src="https://em-content.zobj.net/source/apple/391/slightly-smiling-face_1f642.png"/></span>
                                            <span><img className="emoji-img mr-2" src="https://em-content.zobj.net/source/apple/391/grinning-face_1f600.png"/></span>
                                            <span><img className="emoji-img" src="https://em-content.zobj.net/source/apple/391/smiling-face-with-heart-eyes_1f60d.png"/></span>
                                         </div>

                                    </div>
                                    <div className="col-12 text-center"> <img className="img-fluid" src="/assets/media/svg/profile/sentiment_tracker.PNG" /> </div>
                                </div>
                            </div>
                            <div className="card bgCadet mt-3">
                                <div className="card-body">
                                    <h6 className="mb-2 text-white">{t('myprofile.engagement_tracker')}</h6>
                                    <div className="col-12 text-center">
                                        <EngagementPie weekCount={weekCount} avgSessionLen={avgSessionLen} />
                                    </div>
                                </div>
                            </div>
                            <div className="card bgCadet mt-3 overflow-hidden">
                                <div className="card-body">
                                    <h6 className="mb-2 text-white">{t('myprofile.prod_vs_nonprod') + '(min)'}</h6>
                                    <div className="col-12 text-center">
                                        <ProductiveLineGraph />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card bgCadet h-100">
                            <div className="card-body">
                                <div className="col-12 text-center" >
                                    <Link to="/userProfile" state={location.pathname}><img className="myProfileUserPic" width="80px" height="80px" src={user.empData.ProfileImageURL ? user.empData.ProfileImageURL : '/assets/media/svg/profile/profile_picture.PNG'} /></Link>
                                </div>
                                <div className="col-12 text-center">
                                    <h6 className="mb-0 mt-3 text-white">{user.empData.FirstName}</h6>
                                    <div className="dropdown">
                                        <p className="small text-muted c-pointer" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className="hexUserStatus mr-1" style={{ backgroundColor: getStatusColor(user.status) }}></span>
                                            {user.status}</p>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                            {staticStatus.map((s: any) => {
                                                return (<a key={s.id} onClick={() => { changeStatus(s.title) }} className="dropdown-item position-relative small c-pointer"><span className="hexUserStatus" style={{ backgroundColor: s.color }}></span> {s.title}</a>)
                                            })}
                                        </div>
                                    </div>
                                    {/* <textarea onChange={(e: any) => { setMessage(e.target.value) }} className="input-group-text fs14 w-100 m-auto text-left myProfileUserStatus" value={message}></textarea>
                                    <button onClick={updateStatusMsg} type="button" className="btn btn-SeaGreen btn-sm btnSeaGreen mt-2 pl-3 pr-3">{t('button.save')}</button> */}
                                    <hr className="border-secondary" /> </div>
                                <div className="col-12 text-left">
                                    <h6 className="mt-3 text-white">{t('myprofile.about')}</h6>
                                    <p className="small text-white">{user.empData.About}</p>
                                    <p className="mb-0 small text-muted">{t('teamsignup.designation')}</p>
                                    <p className="small text-white">{user.empData.Designation}</p>
                                    <p className="mb-0 small text-muted">{t('myprofile.email')}</p>
                                    <p className="small text-white">{user.empData.OfficeEmail}</p>
                                    {/* <p className="mb-0 small text-muted">{t('myprofile.mobile')}</p>
                                    <p className="small text-white">{user.empData.Mobile}</p> */
                                    /* <p className="mb-0 small text-muted">{t('myprofile.dob')}</p>
                                    <p className="small text-white">{user.empData.DateOfBirth}</p> */}
                                    {/* <div className="sentimentInput">
                                        <label>{t('myprofile.good')}</label>
                                        <div className="sentiEmojis"> 🙂 😊 😀 😁 </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <ShowOrgFiles showFiles={showInductionFiles} title="Onboarding" type="training"></ShowOrgFiles>
        </section>
    );
}

export default MyProfile;