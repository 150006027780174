import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetContacts } from '../services/UserActions';
import { SendMeetingInvite } from '../services/RoomsService';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { StoreClass } from '../store/Store';
import { globalStore } from '../store/global_store';
import { removeSuccessStatus } from "../services/ContactsService";
//import { chatClient } from '../services/chat-api';
import { SaveActivity } from '../services/ActivityServices';

const hostName = process.env.REACT_APP_URL as string;
function ContactsSearch(props: any) {
    const dispatch = useDispatch();
    const [options, setOptions] = useState<any[]>([]);
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const uname = localStorage.getItem('FirstName');
    const empId = localStorage.getItem('EmpGuid') || '';
    const orgId = localStorage.getItem('OrgGuid') as string;
    const typeaheadRef = useRef<any>(null);
    const lock = useRef(false);
    useEffect(() => {
        if(!orgId) return;
        if ((!user.contacts || user.contacts.length === 0) && orgId) {
            dispatch(GetContacts(orgId, empId) as any);
        }
    }, [])
    useEffect(() => {
        if (user.success && user.success === 'SentMeetingLink') {
            globalStore.showToast({
                type: 'Message',
                title: "Invitation",
                message: "Invitation sent successfully"
            });
            dispatch(removeSuccessStatus()as any);
        }
    }, [user.success]);

    useEffect(() => {
        if (user.contacts && user.contacts.length > 0) {
            let uids: string[] = [];
            console.log(" cons 1", user.contacts);
            const options = user.contacts.filter((con: any) => {
                const exists = props.existingMem.filter((ext: any) => ext.EmpGuid === con.EmpGuid);
                if (exists.length > 0)
                    return false;
                if(empId === con.EmpGuid)
                    return false;
                return true;
            });
           
            setOptions(options);
            // if(lock.current == false){
            //     user.contacts.map((u: any) => {
            //         uids.push(u.EmpGuid);
            //     });
            //     chatClient.subscribeOnlineStatus(uids);
            //     dispatch(getUserStatusess(uids.join(",")) as any);
            //     lock.current = true;
            // }
        }
    }, [user.contacts]);

    const sendInvitation = (email: string, empGuid: string) => {
        console.log('sending invitation to meeting');
        const link = (props.roomName === 'Collaborate' ? '/conference/' : '/customrm/');
        let form = new FormData();
        form.append('toEmail', email);
        form.append("meetingLink", hostName + link + props.meetingLink);
        dispatch(SendMeetingInvite(form) as any);
        SaveActivity({msgType:"CALL_GROUP_INVITATION", l:link, meetingId:props.meetingId, reason:"Meeting Invite from group", fromEmpId: empId}, 'CALL', empGuid);
        setTimeout(() => typeaheadRef.current.clear(), 200);
    }
    // const sendNotice = (empGuid: string) => {
    //     console.log('sending notification to meeting');
    //     const link = (props.roomName === 'Collaborate' ? '/customrm/' : '/conference/');
    //     chatClient.sendCallMsg({ text: JSON.stringify({ t: '__CALL__', f: uname, l: link + props.meetingLink, rn: props.roomName }), messageType: "TEXT" }, empGuid);
    //     setTimeout(() => typeaheadRef.current.clear(), 200);
    //     SaveActivity({msgType:"CALL_GROUP_INVITATION", l:link, meetingId:props.meetingId, reason:"Meeting Invite from group", fromEmpId: empId}, 'CALL', empGuid);
    //     globalStore.showToast({
    //         type: 'Message',
    //         title: "Online Notification",
    //         message: "Invitation to the meeting online"
    //     });
    // }
    return (
        <div className="row">
            <div className="col-12 small ParticipantsSearch addTeamMemberBox mt-4 ">
                <Typeahead
                    flip={true}
                    ref={typeaheadRef}
                    id="async-example"
                    labelKey="OfficeEmail"
                    minLength={2}
                    options={options}
                    inputProps={{ type: 'search' }}
                    placeholder="Choose from contacts"
                    renderMenuItemChildren={( option, { text},idex) =>(
                            <div className="contactsSearch float-left p-2 w-100" title={(option as any).OfficeEmail}>
                                <div className="float-left">
                                <svg className={"ParticipantImage c-pointer "+ ((option as any).IsOnline ? ((option as any).status.length > 0 ?  (option as any).status.toLowerCase(): 'available') : 'Offline')} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <pattern id="01" patternUnits="userSpaceOnUse" width="100" height="100">
                                                <image xlinkHref="/assets/media/images/sute_user_default.png" x="-25" width="150" height="100" />
                                            </pattern>
                                        </defs>
                                        <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#01)"></polygon>
                                    </svg>
                                </div>
                                 <div className="float-left" style={{width: 'calc(100% - 55px)'}}  onClick={() => sendInvitation((option as any).OfficeEmail, (option as any).EmpGuid)}>
                                    <p className="ParticipantName"><span className="PNel"><Highlighter search={""}>{(option as any).FirstName}</Highlighter></span> 
                                   {/*     {user.aus[(option as any).EmpGuid]&&user.aus[(option as any).EmpGuid]==='ONLINE'? 
                                         <img onClick={() => sendNotice((option as any).EmpGuid)} title="Direct Call" alt="Direct Call" src="/assets/media/svg/profile/call.svg" />:
                                         <img onClick={() => sendInvitation((option as any).OfficeEmail, (option as any).EmpGuid)} title="Chat" alt="Chat" src="/assets/media/svg/profile/message.svg" />}
                                    */}
                                    </p>
                                    <p className="ParticipantDesignation">{(option as any).OfficeEmail}</p>
                                </div>
                            </div>)
                    }
                />
            </div>
        </div>
    );
}

export default ContactsSearch;