import React, { useEffect, useState } from 'react';
import InviteToMeeting from '../call/InviteToMeeting';
import ContactsSearch from '../../components/ContactsSearch';
import { removeSuccessStatus } from "../../services/ContactsService";
import { t } from '../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../../store/Store';
import { globalStore } from '../../store/global_store';

const URL = process.env.REACT_APP_URL as string;
const staticStatus = [
    { id: "1", color: "green", title: "Available" },
    { id: "2", color: "orange", title: "Away" },
    // { id: "3", color: "#04f1b2", title: "In Office" },
    { id: "4", color: "red", title: "Busy" }
]

function Participants(props: any) {
    const dispatch = useDispatch();
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [isInviteOpen, setInviteOpen] = useState(false);
    const [meetinglink, setMeetinglink] = useState('');
    useEffect(() => {
        const link = (props.roomName === 'Conference' ? '/conference/' : '/customrm/');
        setMeetinglink(link + props.meetingId);
        console.log(' DDDDD ', props.stream)
    }, [props.meetingId])
    useEffect(() => {
        if (user.success && user.success === 'SentMeetingLink') {
            globalStore.showToast({
                type: 'Message',
                title: "Invitation",
                message: "Invitation sent successfully"
            });
            dispatch(removeSuccessStatus() as any);
        }
    }, [user.success]);

    const showInvite = () => {
        setInviteOpen(true);
    }
    const hideInvite = () => {
        setInviteOpen(false);
    }

    const getStatus = (status: any) => {
        let clr = 'green';
        // const sts = staticStatus.filter((s: any) => s.title === status);
        // if (sts.length > 0) {
        //     clr = sts[0].color;

        // }
        return clr;
    }

    return (
        <div className="SideNav" id="ParticipantsSideNav" style={{ zIndex: 11 }}>
            <div className="sideNavHeader">
                <div className="CloseNav">
                    <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title={t('button.close')} onClick={props.closeSideBar}/>
                </div>
            </div>
            <div className="sideNavBody">
                {!isInviteOpen ? <>
                    {user.isAuthenticated && !props.isCalendarMeeting ? <><div className="row">
                        <div className="col-12 text-right text-light inviteParticipant small">
                            {t('invitetomeeting.invite_others')} <img onClick={showInvite} className="c-pointer" src="/assets/media/svg/share.svg" />
                        </div>
                    </div>
                        <ContactsSearch roomName={props.roomName} meetingLink={props.meetingId} existingMem={[]} />
                    </> : ''}
                    <div className="row mt-4 small ParticipantsAvatars">
                        {props.remoteReqs && props.remoteReqs.map((reqObj: any) => (
                            <div className="col-12 mb-2" key={reqObj.empGuid} >
                                <div className="float-left">
                                    <svg className="ParticipantImage" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ height: '40px', width: '40px' }}>
                                        <defs>
                                            <pattern id={`url(#${reqObj.empGuid})`} patternUnits="userSpaceOnUse" width="100" height="100">
                                                <image xlinkHref={reqObj.ProfileImageURL ? reqObj.ProfileImageURL : "/assets/media/images/sute_user_default.png"} x="-25" width="150" height="100" />
                                            </pattern>
                                        </defs>
                                        <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#${reqObj.empGuid})`} />
                                    </svg>
                                </div>
                                <div className="float-left">
                                    <p className="ParticipantName"><span className="participantSpeaking mr-1 active" style={{ backgroundColor: 'green' }}></span> {reqObj.name} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14"><path d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm2.95-2.679l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-8.78-8.78A5 5 0 0 1 17 6l.001 4a4.98 4.98 0 0 1-.534 2.251z" fill="currentColor" /></svg> <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="14" height="14"><path d="M5 2.5a2.5 2.5 0 015 0v4a2.5 2.5 0 01-5 0v-4z" fill="currentColor"></path><path d="M2 4v2.5a5.5 5.5 0 005 5.478V14H5v1h5v-1H8v-2.022A5.5 5.5 0 0013 6.5V4h-1v2.5a4.5 4.5 0 01-9 0V4H2z" fill="currentColor"></path></svg></p>
                                    <p className="ParticipantDesignation">{t('title.waiting_admit')}...</p>
                                    <div className="admitActions">
                                        <img onClick={() => { props.rejectRequestedUsr(reqObj.empGuid) }} className="declineIcon" src="/assets/media/images/collaborate/decline.svg" title={t('title.decline_reject')} />
                                        <img onClick={() => { props.admitRequestedUsr(reqObj.empGuid) }} className="admitIcon" src="/assets/media/images/collaborate/admit.svg" title={t('title.admit')} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="col-sm-6 text-center mb-3">
                            <svg className="ParticipantImage  m-auto active" viewBox="0 0 100 100" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <pattern id="local123" patternUnits="userSpaceOnUse" width="100" height="100">
                                        <image xlinkHref={user?.empData?.ProfileImageURL ? user.empData.ProfileImageURL : "/assets/media/images/sute_user_default.png"} x="-25" width="150" height="100" />
                                    </pattern>
                                </defs>
                                <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#${'local123'})`}></polygon>
                            </svg>
                            <p className="text-white mb-0"><span className="participantSpeaking mr-1 active" style={{ backgroundColor: getStatus(user.empData.Status) }}></span> {props.userDisplayName} {props.stream?.audio ? <svg className="ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14"><path d="M12 1a5 5 0 0 1 5 5v4a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11z" fill="currentColor" /></svg> :
                                props.stream?.audioErr ? <svg className="ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm2.95-2.679l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-8.78-8.78A5 5 0 0 1 17 6l.001 4a4.98 4.98 0 0 1-.534 2.251z" fill="red" />
                                </svg> : <svg className="ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                    <path d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm2.95-2.679l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-8.78-8.78A5 5 0 0 1 17 6l.001 4a4.98 4.98 0 0 1-.534 2.251z" fill="currentColor" />
                                </svg>}</p>
                        </div>
                        {props.remoteStreams && props.remoteStreams.map((rsobj: any) => (
                            <div className="col-sm-6 text-center mb-3" key={rsobj.uid}>
                                <svg className={"ParticipantImage m-auto active"} viewBox="0 0 100 100" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" onClick={() => props.openProfile(rsobj.profile, rsobj?.profile?.EmpGuid, rsobj.FirstName, rsobj.ProfileImageURL)}>
                                    <defs>
                                        <pattern id={rsobj.uid} patternUnits="userSpaceOnUse" width="100" height="100">
                                            <image xlinkHref={rsobj.profile && rsobj.profile.ProfileImageURL ? rsobj.profile.ProfileImageURL : "/assets/media/images/sute_user_default.png"} x="-25" width="150" height="100" />
                                        </pattern>
                                    </defs>
                                    <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#${rsobj.uid})`}></polygon>
                                </svg>
                                <p className="text-white mb-0"><span className="participantSpeaking mr-1 active" style={{ backgroundColor: getStatus(rsobj?.status) }}></span> {rsobj.FirstName} {rsobj.audio ? <svg className="ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14"><path d="M12 1a5 5 0 0 1 5 5v4a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11z" fill="currentColor" /></svg> :
                                    rsobj.audioErr ? <svg className="ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                        <path d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm2.95-2.679l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-8.78-8.78A5 5 0 0 1 17 6l.001 4a4.98 4.98 0 0 1-.534 2.251z" fill="red" />
                                    </svg> : <svg className="ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="14" height="14">
                                        <path d="M16.425 17.839A8.941 8.941 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11H5.07a7.002 7.002 0 0 0 9.87 5.354l-1.551-1.55A5 5 0 0 1 7 10V8.414L1.393 2.808l1.415-1.415 19.799 19.8-1.415 1.414-4.767-4.768zm2.95-2.679l-1.443-1.442c.509-.81.856-1.73.997-2.718h2.016a8.95 8.95 0 0 1-1.57 4.16zm-2.91-2.909l-8.78-8.78A5 5 0 0 1 17 6l.001 4a4.98 4.98 0 0 1-.534 2.251z" fill="currentColor" />
                                    </svg>}</p>
                            </div>
                        ))}


                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-right text-light small ParticipantsPagination d-none">
                            <img className="c-pointer" src="/assets/media/svg/left-arrow.svg" /> 1/3 <img className="c-pointer" src="/assets/media/svg/right-arrow.svg" />
                        </div>
                    </div>
                </> : ''}
                {isInviteOpen ? <InviteToMeeting meetingLink={URL + meetinglink} closeInvite={hideInvite} /> : ""}
            </div>
            <div className="sideNavFooter"></div>
        </div>
    );
}

export default React.memo(Participants);