import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as T from '../../actions/Types';
import { DeleteInviteGuid, getByInviteGuid, signupAction } from "../../services/UserActions";
import { connect } from "react-redux";
import clsx from "classnames";
import { removeSuccessStatus } from "../../services/ContactsService";
import { useSearchParams } from "react-router-dom";
import { PatternFormat } from "react-number-format";

interface SignupProps {
    InviteGuid : string;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber: string;
    OrgId: number;
    Designation : string;
    IsInvite : Boolean;
    IsRegistered : Boolean;
  }

  interface mailIdProps{
    EmailId : string;
    orgId : number;
  }

function registration(props: any) {
    const media = useSelector((store: any) => store.MediaReducer);
    const dispatch = useDispatch();
    const [fetchingInviteData, setFetchingInviteData] = useState({} as mailIdProps);
    const orgGuid = localStorage.getItem("OrgGuid");
    const [values, setValues] = useState<SignupProps>({
        InviteGuid: "",
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        OrgId: 0,
        Designation: "",
        IsInvite: false,
        IsRegistered :true
    });
    const [formErrors, setFormErrors] = useState<any>({});
    const [apiStatus, setApiStatus] = useState("");
    const [isError, setIsError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [registerSuccess,setRegisterSuccess] = useState(false);
    const [onboardedStatus,setOnboardedStatus] = useState(false)
    useEffect(() => {
     const InviteGide = searchParams.get("id")
		dispatch({
			type: T.ADD_LOADING
		})
		getByInviteGuid(InviteGide)
			.then((resp: any) => {
				setFetchingInviteData({...resp}); // Adjust based on whether result is array
        if (resp.EmailId == null) {
          setOnboardedStatus(true); // Adjust this as per your state logic
        }
				if (resp.status == "success") {         
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
				else if (resp == "Error") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
			})
			.catch((e: any) => {
				dispatch({
					type: T.REMOVE_LOADING
				})
	
				return "error"
			})
		dispatch({
			type: T.REMOVE_LOADING
		})
	}, [ ])

    useEffect(() => {
          setValues((prevValues) => ({
            ...prevValues,
            OrgId: fetchingInviteData?.orgId , // Keep previous value if OrgId is undefined
            Email: fetchingInviteData?.EmailId, // Keep previous value if Email is undefined
            IsInvite: false // Set IsInvite to true
        }));   
    }, [fetchingInviteData]);

    

    useEffect(() => {
        if (props.user.signupResponse) {
          if (props.user.signupResponse.message === "Success") {
            //setApiStatus(props.user.signupResponse.result[0]);
            setIsError(false);
            setValues({...values, FirstName: "", LastName: "", Email: "", PhoneNumber: "", Designation:""});
            setRegisterSuccess(true)
            setFormErrors({
                ...formErrors,
                FirstName: { cls: "", msg: "" },
                LastName: { cls: "", msg: "" },
                Email: { cls: "", msg: "" },
                Designation: { cls: "", msg: "" },
                PhoneNumber: { cls: "", msg: "" },
                // Add other form errors as needed
              });
                const clearErrors = () => {
                    // setApiStatus("")
                    // setIsError(false)
                  };
              
              const timeoutId = setTimeout(clearErrors, 5000);
              return () => clearTimeout(timeoutId); 
        } else if (props.user.error) {
          setApiStatus("Account is already exists");
          setIsError(true);
        }
      } else if (props.user.error && props.user.error.message) {
        setApiStatus(props.user.error.message);
        setIsError(true);
      } else if (
        props.user.error &&
        props.user.error.result &&
        props.user.error.result.length > 0
      ) {
        setApiStatus(props.user.error.result[0].Description);
        setIsError(true);
      } else if (props.user.error && props.user.error.statusCode !== 200) {
          setApiStatus(props.user.error);
          setIsError(true);
        }
        props.RemoveSuccessStatus();
      }, [props.user.signupResponse, props.user.error]);

    const handleChange = (e: any) => {
        e.persist();
        setApiStatus("");
       setIsError(false);
        e.target.value = e.target.value.trimStart();
        setValues((values) => ({
          ...values,
          [e.target.name]: e.target.value,
        }));
        let value = e.target.value;
        switch (e.target.name) {
          case "Email": {
            const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            setFormErrors({
              ...formErrors,
              Email: { cls: emailValid ? "is-valid" : "is-invalid", edited: true },
            });
            break;
          }
          case "FirstName": {
            // const validFirstname = value.match(/^([A-Za-z ])+$/);
            if (!value || value.length === 0) {
              setFormErrors({
                ...formErrors,
                FirstName: {
                  cls: value.length > 0 ? "is-valid" : "is-invalid",
                  edited: true,
                },
              });
            } else {
              setFormErrors({
                ...formErrors,
                FirstName: {
                  cls: value.length > 0 ? "is-valid" : "is-invalid",
                  edited: true,
                },
              });
            }
            break;
          }
    
          case "LastName": {
            // const validLastname = value.match(/^([A-Za-z ])+$/);
            if (!value || value.length === 0) {
              setFormErrors({
                ...formErrors,
                LastName: {
                  cls: value.length > 0 ? "is-valid" : "is-invalid",
                  edited: true,
                },
              });
            } else {
              setFormErrors({
                ...formErrors,
                LastName: {
                  cls: value.length > 0 ? "is-valid" : "is-invalid",
                  edited: true,
                },
              });
            }
            break;
          }
          case "Designation": {
            // const validLastname = value.match(/^([A-Za-z ])+$/);
            if (!value || value.length === 0) {
              setFormErrors({
                ...formErrors,
                Designation: {
                  cls: value.length > 0 ? "is-valid" : "is-invalid",
                  edited: true,
                },
              });
            } else {
              setFormErrors({
                ...formErrors,
                Designation: {
                  cls: value.length > 0 ? "is-valid" : "is-invalid",
                  edited: true,
                },
              });
            }
            break;
          }
          /*case "CompanyName": {
            if (!value || value.length === 0) {
              setFormErrors({ ...formErrors, [e.target.name]: { cls: "is-invalid", edited: true } });
            } else {
              setFormErrors({ ...formErrors, [e.target.name]: { cls: "is-valid", edited: true } });
            }
          }*/
          case "OrgId": {
            if (!value || value === "") {
              setFormErrors({
                ...formErrors,
                [e.target.name]: { cls: "is-invalid", edited: true },
              });
            } else {
              setFormErrors({
                ...formErrors,
                [e.target.name]: { cls: "is-valid", edited: true },
              });
            }
          }
          default:
            break;
        }
      };

    const signupHandler = (e: any) => {
        e.preventDefault();  
        let errrs = [];
    if (!values.FirstName || values.FirstName.length === 0) {
      //setFormErrors({ ...formErrors, 'FirstName': { cls: 'is-invalid', edited: true } });
      //formErrors['FirstName'] = "FirstName cannot be empty";
      formErrors["FirstName"] = { cls: "is-invalid", edited: true };
      errrs.push("First Name");
    }
    if (!values.LastName || values.LastName.length === 0) {
      //setFormErrors({ ...formErrors, 'LastName': { cls: 'is-invalid', edited: true } });
      //formErrors['LastName'] = "LastName cannot be empty";
      formErrors["LastName"] = { cls: "is-invalid", edited: true };
      errrs.push("Last Name");
    }
    if (!values.Email || values.Email.length === 0 || values.Email === null) {
        //setFormErrors({ ...formErrors, 'Email': { cls: 'is-invalid', edited: true } });
        formErrors["Email"] = { cls: "is-invalid", edited: true };
        errrs.push("Email");
      }
      else {
        const emailValid = values.Email.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        //formErrors['Email']  = emailValid ? '' : 'Email is invalid';
        // if (!emailValid) {
        //   errrs.push("Email");
        //   formErrors["Email"] = { cls: "is-invalid", edited: true };
        //   //setFormErrors({ ...formErrors, 'Email': { cls: emailValid ? '' : 'is-invalid', edited: true } });
        // }
      }
      if (!values.PhoneNumber || values.PhoneNumber.length < 10) {
        formErrors["PhoneNumber"] = { cls: "is-invalid", edited: true };
        errrs.push("Phone Number");
      }
      if (!values.Designation || values.Designation.length === 0) {
        //setFormErrors({ ...formErrors, 'LastName': { cls: 'is-invalid', edited: true } });
        //formErrors['LastName'] = "LastName cannot be empty";
        formErrors["Designation"] = { cls: "is-invalid", edited: true };
        errrs.push("Designation");
      }
      if (errrs.length == 0 ) {
        props.signupAction(values)  
        DeleteInviteGuid(searchParams.get("id"))
        setFormErrors({ PhoneNumber: { cls: "", msg: "" }});
      } else {
        var error: string = "";
         if (errrs.length > 0) {
          //error = "Please fill " + errrs.join(", ") + " !"
          error = "Please fill below required fields"
          setApiStatus(error)
          setIsError(true);
        }
        //else if (errrs.length == 1) error = errrs[0] + " is invalid!";      
       }   

      }

      const handlePhoneChange = (Pvalue: any) => {
        setApiStatus("");
        setIsError(false);
        const value = Pvalue.value;
        setValues((values) => ({
          ...values,
          "PhoneNumber": value,
        }));
    
        if (!value || value.length < 10 ) {
          setFormErrors({
            ...formErrors,
            "PhoneNumber": { cls: "is-invalid", edited: true },
          });
        } else {
          setFormErrors({
            ...formErrors,
            "PhoneNumber": { cls: "is-valid", edited: true },
          });
        }
      }

    return (<>
        <section className="myProfilePage position-relative h-100 register-bg">
            <div className="logo">
                <h5><img className="registration-logo" src="assets/media/images/sute-logo3.png" /></h5>
            </div>
            {(registerSuccess || onboardedStatus)? 
             (!fetchingInviteData.EmailId || fetchingInviteData.EmailId.length === 0)? (
                <div className="onboarding-text">
                  <h5>This employee is already onboarded</h5>
                </div>
              ) : (
                <div className="onboarding-text">
                  <h5>Employee is onboarded successfully</h5>
                </div>
              )
            
            :
            (<div className="container align-self-center h-100">
                <div className="row">
                    <div className="col-sm-6 m-auto">
                        <form >
                        {apiStatus ? (
                                        <p
                                        style={{ whiteSpace: "break-spaces" }}
                                        className={clsx(
                                            "alert mt-2 ",
                                            isError ? "alert-danger" : "alert-success"
                                        )}
                                        >
                                        {apiStatus}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                            <div className="card mt-2 loginBox register">
                                <div className="card-body">
                                <h5 className="text-center">
                                  <img className="register-logo" src="assets/media/images/sute-logo3.png" />
                                </h5>
                                <div className="login-box col-12">
                                    <div className="login-content">
                                        <label className="text-light small m-0">First name <sup>*</sup></label>
                                        <input type="text" className={ "form-control mb-2 " + (formErrors["FirstName"] &&
                                                 formErrors["FirstName"].cls  ? formErrors["FirstName"].cls : "")}
                                          value={values.FirstName || ""}  name="FirstName" onChange={handleChange}/>
                                          <label className="text-light small m-0">Last name <sup>*</sup></label>
                                        <input type="text" className={ "form-control mb-2 " + (formErrors["LastName"] &&
                                                 formErrors["LastName"].cls  ? formErrors["LastName"].cls : "")}
                                          value={values.LastName || ""} name="LastName" onChange={handleChange}/>
                                       <label className="text-light small m-0">Email <sup>*</sup></label>
                                        <input type="email" className={ "form-control mb-2 " + (formErrors["Email"] &&
                                                 formErrors["Email"].cls  ? formErrors["Email"].cls : "")}
                                          value={values.Email || ""}  readOnly  name="Email" onChange={handleChange}/>
                                      <label className="text-light small m-0">Phone number <sup>*</sup></label>
                                        <PatternFormat 
                                         format="###-###-####"                                     
                                         className={ "form-control mb-2 " + (formErrors["PhoneNumber"] &&
                                         formErrors["PhoneNumber"].cls  ? formErrors["PhoneNumber"].cls : "")} 
                                          value={values.PhoneNumber || ""}
                                           name="PhoneNumber"
                                         onValueChange={handlePhoneChange}/>
                                        <label className="text-light small m-0">Designation <sup>*</sup></label>
                                        <input type="text" className={ "form-control mb-2 " + (formErrors["Designation"] &&
                                                 formErrors["Designation"].cls  ? formErrors["Designation"].cls : "")}
                                           value={values.Designation || ""}  name="Designation" onChange={handleChange}/>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn btn-SeaGreen mt-2" onClick={signupHandler}>Begin</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
             </div>)}
        </section>
    </>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer,
  });

const mapDispatchToProps = (dispatch: any) => ({
    signupAction: (stateobj: any, history: any) => {
      dispatch(signupAction(stateobj));
    },
    RemoveSuccessStatus: () => {
        dispatch(removeSuccessStatus());
      },
})

export default connect(mapStateToProps, mapDispatchToProps)(registration);