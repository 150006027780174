import { Dispatch } from "redux";
import { postRequest, getRequest } from "../utils/http";
import * as T from "../actions/Types";
import { FilesTableBody } from "../interfaces/EmployeeData";
import Axios from "axios";
import authService from '../global/MsAuth';

const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;
const accounts_api_port = process.env.REACT_APP_ACCOUNTSAPI_PORT as string;
export interface LoginUserType {
    username: string;
    password: string;
}

export const Logout = (empId: string, loc: any) => (dispatch: Dispatch) => {

    var authType = localStorage.getItem('authType');
    //  localStorage.setItem("isAuthenticated", "false");
    //   localStorage.removeItem("EmpGuid");
    //   localStorage.removeItem("FirstName");
    const ver = localStorage.getItem('SUTE_APP_VERSION') || '0'
    localStorage.clear();
    localStorage.setItem("SUTE_APP_VERSION", ver);
    delete Axios.defaults.headers.common['Authorization']
    dispatch({
        type: T.LOGOUT
    });
    // if (chatClient._logined) {
    //     chatClient.logout().then(e => {
    //         console.log('mesage after logout', e);
    //     })
    // }
    // if (suteClient.joined && suteClient.localStream) {
    //     suteClient.leave();
    // }
    if (authType === 'custom') {
        //
    } else {
        authService.signOut();
    }
    try {
        getRequest(`${api_domian}${accounts_api_port}/api/user/UpdateLogout?EmployeeGuid=${empId}`)
            .then(() => {
                // ;

            });
    }
    catch (e) { }
    if (loc) {
        loc('/login', { replace: true });
        //  loc(0)
    }
    else
        window.location.href = "/login";
}

export const SetCurrentUser = (payload: any) => async (dispatch: Dispatch) => {
    dispatch({ type: T.SET_AUTHENTICATED, payload: payload });
}

export const GetContacts = (orgId: string, empId: string) => async (dispatch: Dispatch) => {
    try {
        console.log('loading org contacts');
        // dispatch({
        //     type: T.ADD_LOADING
        // })

        getRequest(`${api_domian}${api_port}/api/employee/GetEmployeeListByOrgAndEmp?orgGuid=${orgId}&empGuid=${empId}`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_CONTACTS_SUCCESS,
                    contacts: resp,

                });
                // dispatch({
                //     type: T.REMOVE_LOADING
                // })
            }).catch(() => {
                dispatch({
                    type: T.GET_ACCOUNTS_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_ACCOUNTS_FAILURE
        })
    }
};

export const GetTeamsUsers = (params: any) => async (dispatch: Dispatch) => {
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })

        let accesToken = localStorage.getItem('msAccessToken') || '';
        getRequest(`${api_domian}${api_port}/api/Teams/GetContacts?accesstoken=` + accesToken)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_CONTACTS_SUCCESS,
                    contacts: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.GET_ACCOUNTS_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_ACCOUNTS_FAILURE
        })
    }
};

export const GetJoinedTeams = (params: any) => async (dispatch: Dispatch) => {
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })

        let accesToken = localStorage.getItem('msAccessToken') || '';
        getRequest(`${api_domian}${api_port}/api/Teams/JoinedTeams?accesstoken=` + accesToken)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_TEAMS_SUCCESS,
                    teams: resp.value
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.GET_ACCOUNTS_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_ACCOUNTS_FAILURE
        })
    }
};

export const CreateTeamsMeeting = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })

        let accesToken = localStorage.getItem('msAccessToken') || '';
        getRequest(`${api_domian}${api_port}/api/Teams/CreateMeeting?accesstoken=` + accesToken)
            .then((resp: any) => {
                // ;          
                interface abc {
                    u: string;
                }
                var r: abc[] = [{ u: resp.joinWebUrl }];
                dispatch({
                    type: T.GET_CONTACTS_SUCCESS,
                    contacts: r
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.GET_TEAMMEETING_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_TEAMMEETING_FAILURE
        })
    }
};

export const GetTeamMembers = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {

        dispatch({
            type: T.ADD_LOADING
        })

        let accesToken = localStorage.getItem('msAccessToken') || '';
        let teamId = params.teamId || '';
        getRequest(`${api_domian}${api_port}/api/Teams/GetTeamMembersByTeamId?accesstoken=` + accesToken + '&teamId=' + teamId)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_TEAM_MEMBERS,
                    contacts: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.GET_ACCOUNTS_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_ACCOUNTS_FAILURE
        })
    }
};

export const CreateMeetingWithTeams = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })

        let accesToken = localStorage.getItem('msAccessToken') || '';
        let teamId = localStorage.getItem('teamId') || '';
        getRequest(`${api_domian}${api_port}/api/Teams/CreateMeetingWithChannel?accesstoken=` + accesToken + '&teamId=' + teamId)
            .then((resp: any) => {
                // ;          
                interface abc {
                    u: string;
                }
                var r: abc[] = [{ u: resp.joinWebUrl }];
                dispatch({
                    type: T.GET_CONTACTS_SUCCESS,
                    contacts: r
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.GET_TEAMMEETING_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_TEAMMEETING_FAILURE
        })
    }
};

export const GetTeamMessages = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })

        let accesToken = localStorage.getItem('msAccessToken') || '';
        let teamId = localStorage.getItem('teamId') || '';
        getRequest(`${api_domian}${api_port}/api/Teams/GetChannelMessages?accesstoken=` + accesToken + '&teamid=' + teamId)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_MESSAGES_SUCCESS,
                    messages: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.GET_ACCOUNTS_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_ACCOUNTS_FAILURE
        })
    }
};


export const createMeeting = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        console.log('Creating meeting', params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/employee/SaveMeeting`, params)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.CREATE_MEETING,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.CREATE_MEETING_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.CREATE_MEETING_FAILURE
        })
    }
};

export const activeMeetings = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/employee/GetActiveMeetingsList`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.ACTIVE_MEETINGS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.CREATE_MEETING_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.CREATE_MEETING_FAILURE
        })
    }
};

export const getMeetingInfo = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/employee/GetMeetingDetailsByMeetingId?meetingId=` + params.meetingId)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_MEETING_INFO,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.CREATE_MEETING_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.CREATE_MEETING_FAILURE
        })
    }
};

export const signupAction = (params: any) => async (dispatch: Dispatch) => {
    try {
        //console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${accounts_api_port}/api/user/OrgSignup`, params)
            .then((resp: any) => {
                if (resp.error) {
                    dispatch({
                        type: T.CREATE_ACCOUNT_FAILURE,
                        payload: resp
                    })
                } else {
                    dispatch({
                        type: T.CREATE_ACCOUNT_SUCCESS,
                        payload: resp
                    });
                }

            }).catch(() => {
                dispatch({
                    type: T.CREATE_ACCOUNT_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.CREATE_ACCOUNT_FAILURE
        })
    }
};


export const empsignupAction = (params: any) => async (dispatch: Dispatch) => {
    try {
        // console.log(params);

        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${accounts_api_port}/api/user/EmpSignUpFromInvitation`, params)
            .then((resp: any) => {
                if (resp.error) {
                    dispatch({
                        type: T.CREATE_ACCOUNT_FAILURE,
                        payload: resp
                    })
                } else {
                    dispatch({
                        type: T.CREATE_ACCOUNT_SUCCESS,
                        payload: resp
                    });
                }
            }).catch(e => {
                console.log(e);
                dispatch({
                    type: T.CREATE_ACCOUNT_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.CREATE_ACCOUNT_FAILURE
        })
    }
};

export const getEmployeeDetailsById = async (empGuid: string) => {
    return getRequest(`${api_domian}${api_port}/api/employee/GetEmployeeDetailsByEmpGuid?empGuid=` + empGuid)
        .then((resp: any) => {
            // ;
            return resp;
        }).catch(e => {
            console.log(e);
            return "";
        });
}

export const getEmailByInvitation = async (invitationId: string) => {
    var bodyFormData = new FormData();
    bodyFormData.append("invitationId", invitationId);
    return postRequest(`${api_domian}${accounts_api_port}/api/user/GetEmailByInvitation`, bodyFormData as any)
        .then((resp: any) => {
            return resp;
        }).catch(e => {
            console.log(e);
            return "";
        });
}

export const uploadLogoAction = (params: any) => async (dispatch: Dispatch) => {
    // 
    // const domain = 'https://localhost';
    //const port = '8220';
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/File/UploadOrgLogo`, params)
            .then((resp: any) => {
                // 
                if (resp.status == 'success') {
                    dispatch({
                        type: T.ORG_LOGO_UPLOAD_SUCCESS,
                        payload: resp
                    });
                    //setTimeout(() => {
                    //    history.push('/' + redirectTo)
                    //}, 3000);
                } else {
                    dispatch({
                        type: T.ORG_LOGO_UPLOAD_FAILURE,
                        payload: resp
                    })
                }
            }).catch(e => {
                dispatch({
                    type: T.ORG_LOGO_UPLOAD_FAILURE,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
}

export const UpdateuiComponentResponse = (value: any) => async (dispatch: Dispatch) => {
    //  
    if (value == 'UploadOrgDataResponse') {
        dispatch({
            type: T.ORG_LOGO_UPLOAD_SUCCESS,
            payload: ''
        })
    }
    if (value == 'UploadOrgDataError') {
        dispatch({
            type: T.ORG_LOGO_UPLOAD_FAILURE,
            payload: ''
        })
    }
    if (value == 'AddMembersResponse') {
        dispatch({
            type: T.SAVE_SUCCESS,
            success: ''
        })
    }

    if (value == 'UploadProfileImageResponse') {
        dispatch({
            type: T.PROFILE_IMAGE_UPLOAD_SUCCESS,
            payload: ''
        });
        dispatch({
            type: T.UPDATE_USER_PROFILE_SUCCESS,
            payload: ''
        })
    }

    if (value == 'saveAVSettingsResponse') {
        dispatch({
            type: T.ADD_AVSETTINGS_SUCCESS,
            payload: ''
        })
    }
    if (value == 'signupResponse') {
        dispatch({
            type: T.CREATE_ACCOUNT_SUCCESS,
            payload: ''
        })
    }
}

export const addMembersSendInvitationAction = (params: any) => async (dispatch: Dispatch) => {
    try {
        // console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${accounts_api_port}/api/user/SendEmpInvitations`, params)
            //postRequest(`${domain}:${port}/api/user/SendEmpInvitations`, params)
            .then(() => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "SendInvitationsSuccess"
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
                // setTimeout(() => {
                //     history.push('/calendarIntegration');
                // }, 3000);
            }).catch(() => {
                dispatch({
                    type: T.ADD_MEMBERS_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.ADD_MEMBERS_FAILURE
        })
    }
};

export const saveAVSettingsAction = (params: any, history: any) => async (dispatch: Dispatch) => {
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${accounts_api_port}/api/user/UpdateAVSettings`, params)
            .then((resp: any) => {
                dispatch({
                    type: T.ADD_AVSETTINGS_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
                setTimeout(() => {
                    history.push('/pod');
                }, 3000);
            }).catch(() => {
                dispatch({
                    type: T.ADD_AVSETTINGS_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.ADD_AVSETTINGS_FAILURE
        })
    }
};

export const uploadProfileImageAction = (params: any, redirectTo: any, history: any) => async (dispatch: Dispatch) => {
    try {
        // 
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${accounts_api_port}/api/user/UploadProfileImage`, params)
            .then((resp: any) => {
                // 
                console.log(resp);
                dispatch({
                    type: T.PROFILE_IMAGE_UPLOAD_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
                setTimeout(() => {
                    history('/' + redirectTo)
                }, 3000);

            }).catch(e => {
                console.log(e);
                dispatch({
                    type: T.PROFILE_IMAGE_UPLOAD_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.PROFILE_IMAGE_UPLOAD_FAILURE
        })
        dispatch({
            type: T.REMOVE_LOADING
        })

    }
}

export const getOrgDetailsByOrgId = (orgGuid: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        console.log('orgdata', orgGuid);
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/user/GetOrgDetailsByOrgId?orgGuid=` + orgGuid)
            .then((resp: any) => {
                console.log('orgDataonSuccess:', resp)
                dispatch({
                    type: T.GET_ORG_DETAILS_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_ORG_DETAILS_FAILURE,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const getOrgList = (params: FilesTableBody, IsDeleted: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/User/GetOrgList?IsDeleted=${IsDeleted}&search=${params.Search}`)
            .then((resp: any) => {
                dispatch({
                    type: T.GET_ORG_LIST_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(() => {
                dispatch({
                    type: T.GET_ORG_LIST_FAILURE,
                    payload: "Unable to get company list"
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const DeleteOrgByGuid = (orgGuid: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/user/DeleteOrgGetEmpDetailsListByGuid?orgGuid=` + orgGuid)
            .then(() => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "Updated successfully."
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
}


export const getEmpDetailsByEmpGuid = (empGuid: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        console.log('empdata', empGuid);
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/user/GetEmpDetailsByEmpGuid?empGuid=` + empGuid)
            .then((resp: any) => {
                if (!resp.UserTypeId)
                    resp["UserTypeId"] = 1;
                dispatch({
                    type: T.GET_EMP_DATA_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_EMP_DATA_FAILURE,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const getEmpDetailsList = (adminGuid: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/user/GetEmpDetailsList?adminGuid=${adminGuid}`)
            .then((resp: any) => {
                dispatch({
                    type: T.GET_EMP_LIST_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_EMP_LIST_FAILURE,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const getEmpListReportingTo = (adminGuid: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })

        getRequest(`${api_domian}${api_port}/api/employee/GetEmployeeListReportingTo?empGuid=${adminGuid}`)
            .then((resp: any) => {

                dispatch({
                    type: T.GET_EMP_LIST_REPORTINGTO_SUCCESS,
                    data: resp.result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_EMP_LIST_REPORTINGTO_FAILURE,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const getAsyncEmpDetailsByEmpGuid = async (empGuid: any) => {
    try {
        return getRequest(`${api_domian}${accounts_api_port}/api/user/GetEmpDetailsByEmpGuid?empGuid=` + empGuid)
            .then((resp: any) => {
                return resp;
            }).catch(() => {
                return "";
            });
    } catch (e) {
        return "";
    }
}


export const getUserStatus = (empGuid: any) => async (dispatch: Dispatch) => {
    try {
        // console.log('empdata', empGuid);
        //dispatch({type: T.ADD_LOADING})
        getRequest(`${api_domian}${api_port}/api/employee/GetEmployeeStatusDetails?empGuid=${empGuid}`)
            .then((res: any) => {
                let resp = res.result;
                dispatch({
                    type: T.GET_EMP_STATUS,
                    payload: { status: (resp.status ? resp.status : "Available"), statusMsg: resp.statusMessage }
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_EMP_DATA_FAILURE,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}
/**update status with statusMessage, status, empGuid */
export const updateUserStatus = (params: any) => async (dispatch: Dispatch) => {
    try {
        // console.log('status object', params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/employee/SaveStatus`, params as any)
            .then(() => {
                dispatch({
                    type: T.UPDATE_EMP_STATUS,
                    payload: { status: params.get('status'), statusMsg: params.get('statusMessage') }
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_EMP_DATA_FAILURE,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const updateProfileImageByURL = (imageURL: string, empId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/user/UploadImageUrlToS3?url=${imageURL}&employeeGuid=${empId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.PROFILE_IMAGE_UPLOAD_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
                //history.push('/intialSettings');
            }).catch(e => {
                console.log(e);
                dispatch({
                    type: T.PROFILE_IMAGE_UPLOAD_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.PROFILE_IMAGE_UPLOAD_FAILURE
        })
    }
}

export const UpdateUserProfile = (params: any, redirectTo: any, history: any) => async (dispatch: Dispatch) => {
    try {
        // 
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })

        postRequest(`${api_domian}${api_port}/api/File/UpdateUserProfile`, params)
            .then((resp: any) => {
                // 
                console.log(resp);
                dispatch({
                    type: T.GET_EMP_DATA_SUCCESS,
                    data: resp.result
                });
                setTimeout(() => {

                    history(redirectTo)
                }, 3000);
            }).catch(e => {
                console.log(e);
                dispatch({
                    type: T.UPDATE_USER_PROFILE_FAILURE,
                    paylaod: e
                })
            });

    } catch (e) {
        dispatch({
            type: T.UPDATE_USER_PROFILE_FAILURE,
            payload: e
        })
    }
}

export const DeleteEmployeeByEmployeeGuid = (empGuid: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        console.log('orgdata', empGuid);
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/user/RemoveUserFromOrgByEmpGuid?empGuid=` + empGuid)
            .then(() => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "Updated successfully."
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
}

export const SaveNewPassword = (params: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${accounts_api_port}/api/user/SaveRestPassword`, params)
            .then((resp: any) => {
                // 
                console.log(resp);
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp.result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
}

export const SendForgetPasswordEmail = (userEmail: string) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${accounts_api_port}/api/user/SendForgetPasswordEmail?userEmail=` + userEmail)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp.result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
}

export const UpdateCurrentPassword = (params: any) => async (dispatch: Dispatch) => {
    //  
    try {
        //const domain = 'https://localhost';
        //const port = '8220';
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${accounts_api_port}/api/user/CheckCurrentPassword`, params)
            .then((resp: any) => {
                // 
                console.log(resp);
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp.result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
}

export const ShowToast = (toast: any) => async (dispatch: Dispatch) => {
    console.log("UserActions", "ShowToast")
    try {
        dispatch({
            type: T.TOAST,
            payload: toast,
        });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
};

export const getOrgGroups = async (orgID: number) => {
    try {
        return getRequest(`${api_domian}${api_port}/api/Employee/GetBatchs?org_id=${orgID}`)
            .then((resp: any) => {
                return resp.result;
            }).catch(e => {
                console.log(e);
                return {};
            });
    } catch (e) {
        return {};
    }
}

export const SaveGroups = async (params: any) => {
    try {
        return postRequest(`${api_domian}${api_port}/api/employee/SaveBatch`, params as any)
            .then((resp: any) => {
                return resp;
            }).catch(e => {
                console.log(e);
                return {};
            });
    } catch (e) {
        return {};
    }
}

export const DeleteOrgGroups = async (id: any, isdelete: any) => {
    try {
        return postRequest(`${api_domian}${api_port}/api/employee/DeleteBatch?batchGroup_id=` + id + '&is_deleted=' + isdelete)
            .then((resp: any) => {
                return resp;
            }).catch(e => {
                console.log(e);
                return {};
            });
    } catch (e) {
        return {};
    }
}

export const getOrgDesignations = async (orgGuid: any) => {
    try {
        return getRequest(`${api_domian}${api_port}/api/Employee/GetDesignationbyOrganisationList?orgGuid=${orgGuid}`)
            .then((resp: any) => {
                return resp.result;
            }).catch(e => {
                console.log(e);
                return {};
            });
    } catch (e) {
        return {};
    }
}

export const sendEmails = async (mails:any) =>{
    try {
        return postRequest(`${api_domian}${api_port}/api/employee/saveInvitePeople?mails=`, mails)
        .then((resp:any)=>{
            return resp;
        }).catch(e=>{
            console.log(e)
        })
    } catch (error) {
        return {}
    }
}

export const getByInviteGuid = async (orgGuid: any) => {
    try {
        return getRequest(`${api_domian}${api_port}/api/Employee/GetInviteGuid?inviteGuid=${orgGuid}`)
            .then((resp: any) => {
                return resp.result;
            }).catch(e => {
                console.log(e);
                return {};
            });
    } catch (e) {
        return {};
    }
}

export const DeleteInviteGuid = async (inviteGuid:any) =>{
    try {
        return postRequest(`${api_domian}${api_port}/api/employee/DeleteInviteGuid?inviteGuid=${inviteGuid}`)
        .then((resp:any)=>{
            return resp;
        }).catch(e=>{
            console.log(e)
        })
    } catch (error) {
        return {}
    }
}