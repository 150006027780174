import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { StoreClass } from '../../store/Store';
import CheckinGrid from './CheckinGrid';
import CheckinsRightMenu from './CheckinsRightMenu';
import Select from 'react-select';
import { getEmpListReportingTo } from '../../services/UserActions';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

function TeamCheckinHistory(props: any) {   
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [selectedEmp, setSelectedEmp] = useState(0 as any);
    const [optionsEmpListReportingTo,setOptionsEmpListReportingTo] = useState<any>([]);
    const [EmpListReportingTo,setEmpListReportingTo] = useState<any>();
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const [disableCheckinGrid,setDisableCheckinGrid] = useState(false)
    const dispatch = useDispatch();

     useEffect(() =>{
        dispatch(getEmpListReportingTo(empGuid) as any);
     },[])
     
     useEffect(() => {  
        if (user.empListReportingTo && user.empListReportingTo.length > 0) {

            const options = user.empListReportingTo.map((emp: any) => ({
                value: emp.EmployeeId,
                label: emp.FirstName + ' ' + emp.LastName
            }));
    
            // Add placeholder option at the beginning of the options array
            options.unshift({ value: '' , label: 'Select' });          
            setOptionsEmpListReportingTo(options);

        }
    }, [user.empListReportingTo]);

    // useEffect(() => {
    //     if (user.empListReportingTo && user.empListReportingTo.length > 0) {
    //         setEmpListReportingTo(user.empListReportingTo[0].EmployeeId);
    //         setSelectedEmp(user.empListReportingTo[0].EmployeeId);
    //     }
    // },[])

    const GetEmployeeCheckins = (employee: any) => {
        setSelectedEmp(employee.value)
        setEmpListReportingTo(employee.value);
        if(employee.value === ''){
            setDisableCheckinGrid(false)
        }
    }
    
	const _getOptionEmpListReportingTo = (reportingto: any) => {
        if (optionsEmpListReportingTo && reportingto) {
            const selectedOption = optionsEmpListReportingTo.find((emp: any) => emp.value === reportingto);           
                if (selectedOption) {
                    if (!disableCheckinGrid) {
                         setDisableCheckinGrid(true)
                    }
                }
    
            return selectedOption; 
        } else {
            return undefined;
        }
    }
    
    
    return (
		<section className="bgCadet position-relative d-flex pt-4 minh-100">
        <div className="col-sm-8 popup-settings ml-auto">
				<CheckinsRightMenu path={window.location.pathname} />
            <div className="row">
                <div className="col-sm-10 m-auto popup-settings">
               
                    <div className="card loginBox addTeamMemberBox mt-3 overflow-box">
                        <div className="card-body pl-4 pr-4">
                        <div className="col-12 p-0 d-flex mb-3">
                                <span className="tab-btn-parent">
                                <Link to="/checkins">
                                        <button type="button" className="btn btn-SeaGreen mb-block d-none mr-2">Check-Ins</button>
                                    </Link>
                                    <Link to="/CheckinHistory">
                                        <button type="button" className="btn btn-SeaGreen mb-block d-none mr-2">Check-In history</button>
                                    </Link>
                                    {/* <Link to="/TeamCheckinHistory">
                                        <button type="button" className="btn btn-tab mb-block d-none">Team check-ins</button>
                                    </Link> */}
                                </span>
                            </div>
                            <div className="row m-auto">
                                <div className="col-12 mb-3 text-white p-0">
                                    <h5>Team Check-In History</h5> 
								</div>
                                <Select  options={optionsEmpListReportingTo} onChange={GetEmployeeCheckins} value={_getOptionEmpListReportingTo(EmpListReportingTo)}
                                    styles={{
                                                    control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "650px",
                                                }),
                                            }}
                                ></Select>
                               {disableCheckinGrid && (<CheckinGrid selectEmp={selectedEmp}></CheckinGrid>)}
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
		</section>
    );
}

export default TeamCheckinHistory;