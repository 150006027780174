import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import * as T from "../../actions/Types";
import { ShowToast } from "../../services/UserActions";
import { GetStepChallenges, SaveStepChallenge, GetChatMessages, SaveChallengeChatMessages, GetLeaderBoard } from "./../../services/WellnessServices";
import { Modal } from 'react-bootstrap';
import React from 'react'; // Importing React as default
import DatePicker from 'react-datepicker';
import { FaDiamond } from "react-icons/fa6";
import { formatUtcDate } from "../../utils/helper";
import ChallangeChat from "./challengeChatTranscrit";
import { UploadChatFiles } from "../../services/FileService";
import { v4 as uuidv4 } from 'uuid';
import { t } from '../../i18n';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import emojiRegex from 'emoji-regex';
import Moment from 'moment';
import { MdAttachFile } from "react-icons/md";
import FileIcon from "../../components/FileIcon";
import { size } from "lodash";
import { flushSync } from "react-dom";

function getGuid() {
  return uuidv4();
}
function challengesSteps(props: any) {

  // const getNewDate = () => {
  //   const now = new Date();
  //   const day = String(now.getDate()).padStart(2, '0');
  //   const month = String(now.getMonth() + 1).padStart(2, '0');
  //   const year = now.getFullYear();
  //   return `${year}-${month}-${day}`;
  // };


  // const arr = [
  //   { name: "Content", link: "/information" },
  //   { name: "Challenges", link: "/challenges" },
  //   { name: "Classes", link: "/classes" },
  // ];
  //const media = useSelector((store: any) => store.MediaReducer);
  const [enrollPopup, setEnrollPopup] = useState(false);
  const [LeaderboardPopup, setLeaderboardPopup] = useState(false);
  const [description, setDescription] = useState('');
  const [requirement, setRequirement] = useState('');
  const [goals, setGoals] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startingValue, setStartingValue] = useState(0);
  const [metric, setMetric] = useState("");
  const [enrollDate, setEnrollDate] = useState("");
  const [targetValue, setTargetValue] = useState("")
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [GetStepChallengesData, setGetStepChallengesData] = useState([] as any);
  const EmpGuid = localStorage.getItem('EmpGuid') as string;
  const [stepId, setStepId] = useState(null)
  const [enrollId, setEnrollId] = useState(null)
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');
  const [errorEndDate, setErrorEndDate] = useState('');
  const [rejectReasonPopup, setRejectReasonPopup] = useState(false);
  const [rejectReasonPopupData, setRejectReasonPopupData] = useState(null as any);
  const [attachments, setAttachments] = useState('' as string);
  const [erroeMesssgeHide, setErrorMessageHide] = useState(false)
  const [erroeMesssgeEditHide, setErrorMessageEditHide] = useState(false)
  const [errorValueMessage, setErrorValueMessage] = useState('')
  const [errorValueMesssgeHide, setErrorValueMessageHide] = useState(false)
  const [challengeType, setChallengeType] = useState('')
  const [leaderBoardList, setLeaderBoardList] = useState([] as any)
  const [fileType, setFileType] = useState("")

  const dispatch = useDispatch();
  const formData = new FormData();
  const location = useLocation();
  const loc = location.state;
  let EmpId: number | null = null; // Initialize EmpId as null or number
  const EmpIdString = localStorage.getItem('EmpId');
  const [chat, setChat] = useState<any[]>([]);
  const fileUL = useRef<HTMLDivElement>();
  const [text, setText] = useState('');
  const inputRef = useRef(null as any);
  const regexPattern = /^\s+/;
  const truncateBlank: (m: string) => string = (message: string) => message.replace(regexPattern, '');
  const [openEmoji, setOpenEmoji] = useState(false);
  const [fileError, setFileError] = useState('');
  const [fileErrorPopUp, setFileErrorPopUp] = useState(false)
  const orgGuid = localStorage.getItem('OrgGuid');
  const dateFormated = process.env.REACT_APP_DATE_FORMAT

  if (EmpIdString !== null && EmpIdString !== undefined) {
    EmpId = parseInt(EmpIdString, 10);
  }

  useEffect(() => {

    if (GetStepChallengesData && (GetStepChallengesData.length === 0)) {
      dispatch({
        type: T.ADD_LOADING
      })
      GetStepChallenges(EmpGuid, loc.ChallengeGuid, loc.stepId)
        .then((resp: any) => {
          setGetStepChallengesData([...resp.result])
          console.log("getting data", resp.result)

          if (resp.status === "success") {
            dispatch({
              type: T.REMOVE_LOADING
            })
          }
          else if (resp === "error") {
            dispatch({
              type: T.REMOVE_LOADING
            })
          }
        })
        .catch((e: any) => {
          dispatch({
            type: T.REMOVE_LOADING
          })

          return "error"
        })
    }
    GetChats();

  }, [])

  const GetChats = () => {
    if (loc.stepId) {
      // dispatch({
      //   type: T.ADD_LOADING
      // })
      GetChatMessages(loc.stepId).then((resp: any) => {
        flushSync(() => {
          setChat([...resp.result]);
        })
        
        console.log("getting data", resp.result)

        if (resp.status === "success") {
          dispatch({
            type: T.REMOVE_LOADING
          })
        }
        else if (resp === "error") {
          setChat([]);
          dispatch({
            type: T.REMOVE_LOADING
          })
        }
      })
        .catch((e: any) => {
          dispatch({
            type: T.REMOVE_LOADING
          })

          setChat([]);
        })
    }
  }

  const formatDate = (date: any) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (EmpId && GetStepChallengesData.length > 0) {
      const filteredChallenge = GetStepChallengesData.find((item: any) => item.StepId > 0);

      if (filteredChallenge) {
        const { Description, Requirement, Goal, StartDate, EndDate, LeaderBoardInfo, EnrollId, StepId, ChallengeType } = filteredChallenge;

        setDescription(Description || '');
        setRequirement(Requirement || '');
        setGoals(Goal || '');
        setStartDate(StartDate ? StartDate : '');
        setEndDate(EndDate ? EndDate : '');
        setEnrollId(EnrollId || '');
        setStepId(StepId || '');
        setChallengeType(ChallengeType || '');
        // setEnrollDate(LeaderBoardInfo.EnrollDate ? LeaderBoardInfo.EnrollDate : '')     

        if (LeaderBoardInfo) {
          const { EnrollDate, StartingValue, Metric, TargetValue, EnrollId, FileName } = LeaderBoardInfo;
          // setEnrollDate(EnrollDate ? formatDateForInput(EnrollDate) : '');
          // setStartingValue(StartingValue || '');
          // setMetric(Metric || '');
          // setTargetValue(TargetValue || '');
          setEnrollId(EnrollId || '');
          setSelectedFile(FileName || '');

        }
      }
    }

  }, [EmpId, GetStepChallengesData]);

  const handleForm = (event: any, type: string) => {
    const value = event.target.type === 'file' ? event.target.files?.[0] : event.target.value;

    if (type == "Description") {
      setDescription(value);
    }
    else if (type == "Requirements") {
      setRequirement(value);
    }
    else if (type == "Goal") {
      setGoals(value);
    }
    else if (type == "StartDate") {
      setStartDate(value);
    }
    else if (type == "EndDate") {
      setEndDate(value);
    }
    else if (type == "StartingValue") {
      setStartingValue(value);
      setErrorValueMessage('')
      setErrorValueMessageHide(false)
      setErrorMessageEditHide(false)
    }
    else if (type == "Metric") {
      setMetric(value);
    }
    else if (type == "Target") {
      setTargetValue(value);
    }
    else if (type == "selectedFile") {
      const file = event.target.files?.[0];
      if (file) {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
        if (!allowedTypes.includes(file.type)) {
          setFileError('Only Image files are allowed');
          setSelectedFile(null);
          setFileErrorPopUp(true)
          return;
        } else {
          setFileError('');
        }
        setSelectedFile(file || null); // Set the file object
        const reader = new FileReader();
        reader.onload = function (e: any) {
          setAttachments(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  const fetchStepChallengesData = () => {
    if (EmpId !== null) {

      if (GetStepChallengesData.length > 0) {
        dispatch({
          type: T.ADD_LOADING
        })
        GetStepChallenges(EmpGuid, loc.ChallengeGuid, loc.stepId)
          .then((resp: any) => {
            setGetStepChallengesData([...resp.result])
            console.log("getting data", resp.result)

            if (resp.status == "Success") {
              dispatch({
                type: T.REMOVE_LOADING
              })
            }
            else if (resp == "Error") {
              dispatch({
                type: T.REMOVE_LOADING
              })
            }
          })
          .catch((e: any) => {
            dispatch({
              type: T.REMOVE_LOADING
            })

            return "error"
          })
        dispatch({
          type: T.REMOVE_LOADING
        })
      }
    }
    console.log(GetStepChallengesData)

  };

  const saveHandleStepChallenge = () => {
    dispatch({
      type: T.ADD_LOADING
    })
    SaveStepChallenge(formData)
      .then((resp: any) => {
        fetchStepChallengesData();
        if (rejectReasonPopup) {
          setRejectReasonPopup(false);
          setRejectReasonPopupData(null);
        }
        if (resp == "success") {
          dispatch({
            type: T.REMOVE_LOADING
          })
          GetChats();
          dispatch(ShowToast({ type: 'Message', title: 'Status', message: 'Step Challenges Updated Success' }) as any)

        }
        else if (resp == "Error") {
          dispatch(ShowToast({ type: 'Message', title: 'Status', message: 'something Went wrong' }) as any)
          dispatch({
            type: T.REMOVE_LOADING
          })
        }
      })
  }

  const SaveEnrollChallenges = (e: any) => {
    e.preventDefault();
    if (!enrollDate) {
      setErrorEndDate("Please make enroll Date")
      setErrorMessageHide(true)
      return
    }
    const formattedDate = Moment(enrollDate).format('DD-MM-YYYY') //formatDate(date);
    const isDateAlreadySelected = GetStepChallengesData?.some((item: any) => {
      const enrollDated = Moment(item.LeaderBoardInfo.EnrollDate).format('DD-MM-YYYY')
      const t = enrollDated.split('-').join('') === formattedDate.split('-').join('');
      return t
    })

    if (isDateAlreadySelected) {
      setErrorEndDate("Already Enrolled For The Date");
      setEnrollDate(enrollDate);
      setErrorMessageHide(true)
      return
    }

    if (selectedFile) {
      formData.append('LeaderBoardInfo.File', selectedFile); // Assuming you want to upload the entire file
      formData.append('LeaderBoardInfo.FileName', selectedFile.name);
      formData.append('LeaderBoardInfo.Type', selectedFile.type);
      formData.append('LeaderBoardInfo.Size', selectedFile.size.toString());
    }

    formData.append('LeaderBoardInfo.StartingValue', startingValue.toString());
    formData.append('EmpGuid', EmpGuid || '');
    let date = new Date(enrollDate);
    if (!isNaN(date.getTime())) {
      const isoDate = date.toISOString().split('T')[0];
      formData.append("LeaderBoardInfo.EnrollDate", isoDate);
    }
    const UTCTime = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes());

    formData.append('UTCEnrollTime', UTCTime.toString());
    formData.append('offset', new Date().getTimezoneOffset().toString());

    // formData.append('LeaderBoardInfo.EnrollDate', enrollDate ? Moment(enrollDate).format('DD-MM-YYYY')  : '');
    formData.append('StepId', stepId === null || stepId === undefined ? '0' : stepId);
    formData.append('LeaderBoardInfo.EnrollId', enrollId !== 0 ? '0' : enrollId);
    date = new Date();
    if (!isNaN(date.getTime())) {
      const isoDate = Moment(enrollDate).format('YYYY-MM-DD');
      formData.append('StartDate', isoDate);
      formData.append('EndDate', isoDate);
    }
    console.log(enrollId)
    if (startingValue > 0) {
      saveHandleStepChallenge()
      setStartingValue(0)
      setMetric("")
      setEnrollDate("")
      setAttachments('');
      setSelectedFile(null)
      setEnrollDate('')
    } else {
      setErrorValueMessage("value must be greather than 0");
      setEnrollDate(enrollDate)
      setErrorValueMessageHide(true)
    }

  }

  const EditEnrollChallenges = (e: any) => {
    e.preventDefault();

    if (selectedFile) {
      formData.append('LeaderBoardInfo.File', selectedFile); // Assuming you want to upload the entire file
      formData.append('LeaderBoardInfo.FileName', selectedFile.name);
      formData.append('LeaderBoardInfo.Type', selectedFile.type);
      formData.append('LeaderBoardInfo.Size', selectedFile.size.toString());
    }

    formData.append('LeaderBoardInfo.StartingValue', startingValue.toString());
    formData.append('LeaderBoardInfo.Metric', metric || "");
    formData.append('EmpGuid', EmpGuid || '');
    let date = new Date(enrollDate);
    if (!isNaN(date.getTime())) {
      const isoDate = date.toISOString().split('T')[0];
      formData.append("LeaderBoardInfo.EnrollDate", isoDate);
    }
    const UTCTime = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes());

    formData.append('UTCEnrollTime', UTCTime.toString());
    formData.append('offset', new Date().getTimezoneOffset().toString());
    formData.append('StepId', stepId === null || stepId === undefined ? '0' : stepId);
    formData.append('LeaderBoardInfo.EnrollId', enrollId === null || enrollId === undefined ? '0' : enrollId);
    // formData.append('StartDate', startDate);
    // formData.append('EndDate', endDate);
    if (startingValue > 0) {
      saveHandleStepChallenge()
      setStartingValue(0)
      setMetric("")
      setEnrollDate("")
      setAttachments('');
      setSelectedFile(null)
      setErrorMessageEditHide(false)
    } else {
      setErrorEndDate("Enroll must be at least 1 value")
      // setEnrollDate(enrollDate)
      setErrorMessageEditHide(true)
    }

  }

  const handleImageClick = (ImageUrl: any) => {
    setModalImageUrl(ImageUrl);
    setModalVisible(true);
  };

  const handleEditRejectedStep = (item: any) => {
    //const date = formatDateForInput(item.LeaderBoardInfo.EnrollDate);
    setEnrollDate(item.LeaderBoardInfo.EnrollDate);
    setStartingValue(item.LeaderBoardInfo.StartingValue);
    setMetric(item.LeaderBoardInfo.Metric);
    setEnrollId(item.LeaderBoardInfo.EnrollId);
    setFileType(item.LeaderBoardInfo.ContentType)
  };

  const handleRejectRejectionPopup = (item: any) => {
    if (item.LeaderBoardInfo.Status === 'rejected') {
      setRejectReasonPopup(true);
      handleEditRejectedStep(item);
      setRejectReasonPopupData(item);
      setErrorMessageHide(false);
    }
  };

  const handleDate = (date: any) => {
    const formattedDate = Moment(date).format('MM-DD-YYYY') //formatDate(date);

    if (date) {
      const isDateAlreadySelected = GetStepChallengesData?.some((item: any) => {
        const enrollDate = Moment(item.LeaderBoardInfo.EnrollDate).format('MM-DD-YYYY')
        const t = enrollDate.split('/').join('') === formattedDate.split('/').join('');
        return t
      })

      if (isDateAlreadySelected) {
        setErrorEndDate("Already Enrolled For The Date");
        setEnrollDate(formattedDate);
        setErrorMessageHide(true)
      } else {
        setEnrollDate(formattedDate);
        setErrorEndDate('');
        setErrorMessageHide(false)
      }
    }
    const isFutureDate = Moment(date).isAfter(Moment(), 'day');

    if (isFutureDate) {
      setErrorEndDate("Future dates are not allowed.");
      setEnrollDate('');
      setErrorMessageHide(true);
      return;
    }
  };

  const parseDate = (dateString: any) => {
    const [day, month, year] = dateString.split('/');
    return `${month}-${day}-${year}`;
  };

  const parseDateString = (dateString: any) => {
    const datePart = dateString.split('T')[0]; // Split by 'T' and take the first part
    const [year, month, day] = datePart.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const parseEnrollDateString = (dateString: any) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${day}-${month}`);
  };

  const handleOnclick = (event: any) => {
    event.target.value = null
  }

  function onUpload(e: any) {
    e.preventDefault();

    console.log(e.target.files);
    const formData = new FormData();
    for (const file of e.currentTarget.files) {
      formData.append('files', file, file.name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
    }
    //   dispatch({
    //     type: T.ADD_LOADING
    // })
    UploadChatFiles(formData).then((resp) => {
      console.log('UploadChatFiles  ', resp);
      resp.forEach((f: any) => {
        sendMessage('_MEDIA_' + f + '_MEDIA_');
      });
      //    dispatch({
      //     type: T.REMOVE_LOADING
      // });
    }).catch((err) => {
      //   dispatch({
      //     type: T.REMOVE_LOADING
      // })
    })

  }

  const sendMessage = (msg: string) => {
    console.log("sendMessage ");

    const msgId = getGuid();
    msg = getstring(msg);
    const jsonMsg: any = { text: msg, UUID: msgId };
    // if (ssFiles && ssFiles.length > 0) {
    //     jsonMsg.files = ssFiles;
    // }
    const msgStr = JSON.stringify(jsonMsg);
    const retMsg = { text: msg, messageType: "TEXT", SenderName: 'me', SenderGuid: EmpId, TimeStamp: Date.now().toString() };

    // chatClient.sendPeerMessage(msgStr, props.selEmpGuid).then((retMsg: any) => {
    //     if (!retMsg) {
    //         console.log('error in sending message', chatClient._logined);
    //         return;
    //     }
    SaveChallengeChatMessages({
      "EmpGuid": EmpGuid,
      "StepId": loc.stepId,
      "Message": msgStr,
      "FileUrl": "",
      "TimeStamp": retMsg.TimeStamp
    }).then(() => {
      GetChats();
    })
    //   const form = new FormData();
    //   form.append('Message', 'msgStr');
    //   form.append('EmpGuid', EmpGuid);
    //   form.append('ChatMessageId', '0');
    //   form.append('TimeStamp', retMsg.TimeStamp);
    //   form.append('UUID', msgId);
    //   form.append('StepId', loc.stepId);
    //   form.append('EmpId', msgId);
    //   form.append('FileUrl', '');
    //   form.append('IsDeleted', '0');
    //   form.append('IsSystem', '0');
    //   form.append('SenderName', '');
    //   console.log("sendMessage 222");
    //  // setChat([...chat,retMsg])
    //  // dispatch(StoreMsg({ ...retMsg, SenderGuid: empId, UUID: msgId }, props.selEmpGuid) as any);
    //   console.log("sendMessage 333");
    //   SaveChallangeChat(form).then(() =>{});
    //   console.log("sendMessage 444");
    // });

    setText('');
  }
  const handleChange = (event: any) => { setText(event.target.value) }
  const handleSend = (event: any) => {
    const val = truncateBlank(text)
    val.length > 0 && sendMessage(val);
  }
  const handleSelectEmoji = (emoji: any) => {
    const input = inputRef.current;
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const newText = text.substring(0, startPos) + emoji.native + text.substring(endPos)
    input.focus();
    input.setSelectionRange(startPos + emoji.native.length, startPos + emoji.native.length);
    input.focus();
    setText(newText);

  };
  const getstring = (msg: string) => {
    const regex = emojiRegex();
    regex.lastIndex = 0;
    const matches = msg.match(regex) || [];
    const emojis = matches.map((match) => match.trim());
    let native = '' as any
    let code = '';
    let idx = 0;
    emojis.forEach(emoji => {
      native = emoji as any
      code = native.codePointAt(0).toString(16);
      idx = msg.indexOf(native);
      if (idx == 0) {
        msg = '_EMOJ_' + code + '_EMOJ_' + msg.substring(idx + emoji.length);
      }
      else {
        msg = msg.substring(0, idx) + '_EMOJ_' + code + '_EMOJ_' + msg.substring(idx + emoji.length);
      }
    })
    return Linkify(msg);
  }
  const Linkify = (children: any) => {
    const words = children.split(' ')
    const formatedWords = words.map((w: any, i: any) => addMarkup(w))
    const html = formatedWords.join(' ')
    return html;
  }
  const isUrl = (word: any) => {
    const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return word.match(urlPattern)
  }
  const addMarkup = (word: any) => {
    return isUrl(word) ?
      `_LINK_${word}_LINK_` :
      word
  }

  const handleLeaderBoard = () => {
    const validOrgGuid = orgGuid ?? '';
    const validStepId = stepId ?? 0;

    dispatch({
      type: T.ADD_LOADING,
    });
    GetLeaderBoard(validOrgGuid, validStepId)
      .then((resp: any) => {
        setLeaderBoardList([...resp.result]);
        if (resp.status === "success") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        } else if (resp === "error") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          type: T.REMOVE_LOADING,
        });

        return "error";
      });
    setLeaderboardPopup(true)
  }
  console.log(leaderBoardList)

  const handleCloseEnrollPopup = () => {
    setEnrollPopup(false);
    setStartingValue(0)
    setEnrollDate("")
    setErrorMessageHide(false)
    setErrorEndDate('')
  }

  return (
    <>
      <section className="myProfilePage position-relative d-flex mih-100">
        <div className="container">
          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-10">
                <div className="cRoomHeader pt-2">
                  <div className="cRsuteLogo align-self-center">
                    <Link to={"/challenges/"}>
                      <img src="/assets/media/images/logo.png" alt="logo" />
                    </Link>
                  </div>
                  <div className="cRTitle p-3">
                    <h5>{challengeType.length > 0 ? `Wellness initiatives / ${challengeType}` : "Wellness initiatives / [Step Wellness initiatives]"}</h5>
                  </div>
                </div>
                <div className="col-10 ml-auto p-0">
                  <div className="challenge-form">
                    <div className="col-12 p-0">
                      <h5>Description</h5>
                      <label className="form-control challenges-textarea"
                      >
                        {description}
                      </label>
                    </div>
                    <div className="col-12 mt-3 p-0">
                      <h5>Requirements</h5>
                      <label className="form-control challengesview-input"
                      >{requirement}</label>
                    </div>
                    <div className="col-12 mt-3 p-0">
                      <h5>Target / Goal</h5>
                      <label className="form-control challengesview-input"
                      >{goals}</label>
                    </div>
                    <div className="col-12  row mt-3 m-0 p-0">
                      <div className="col-6 pl-0">
                        <h5>Start Date </h5>
                        <label
                          className="form-control challengesview-input"
                        >{Moment(startDate).format(dateFormated)}</label>
                      </div>
                      <div className="col-6 pr-0 p-0">
                        <h5>End Date</h5>
                        <label
                          className="form-control challengesview-input"
                        >{Moment(endDate).format(dateFormated)}</label>
                      </div>
                    </div>
                    <div className="col-12  row mt-3 m-0 p-0">
                      <div className="col-6 pl-0">
                        <h5>
                          Participants
                          {/* <span className="participent-count">11</span> */}
                        </h5>
                        {GetStepChallengesData.map((item: any, index: number) => (
                          item.LeaderBoardInfo.FirstName && (
                            <img
                              key={index}
                              src={item.LeaderBoardInfo.ProfileUrl || "/assets/media/images/sute_user_default.png"}
                              className="participent-img"
                              alt="Profile"
                              title={`${item.LeaderBoardInfo.FirstName} ${item.LeaderBoardInfo.LastName}`}
                            />
                          )
                        ))}

                      </div>
                      <div className="col-3 p-0 m-auto">
                        <button
                          type="button"
                          className="btn enroll-btn w-100  text-justify-center"
                          onClick={() => {
                            setEnrollPopup(true);
                          }}
                        >
                          {GetStepChallengesData.find((item: any) => item.EmpId == EmpId) ? "LOG" : 'ENROLL'}
                        </button>
                      </div>
                      <div
                        className="col-3 pr-0 m-auto"
                        onClick={handleLeaderBoard}
                      // onClick={() => {
                      //   setLeaderboardPopup(true);
                      // }}
                      >
                        <a className="leaderboard-link">Leader Board</a>
                      </div>
                    </div>
                    <div className="col-12 mt-3 p-0">
                      <h5>Message / Posts</h5>

                      <input
                        className="form-control challengesview-input mb-2"
                        type="text" value={text}
                        placeholder={t("chat.placeholder")}
                        ref={inputRef}
                        onKeyPress={async (evt: any) => {
                          if (evt.key === 'Enter') {
                            setOpenEmoji(false);
                            const val = truncateBlank(text)
                            val.length > 0 && sendMessage(val);
                          } else {
                            // props.typing();
                          }
                        }}
                        onChange={handleChange}
                      />
                      <span className="send-icon c-pointer" style={{ right: '84px', padding: '5px 13px' }} onClick={handleSend}>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="32"
                            d="M470.3 271.15 43.16 447.31a7.83 7.83 0 0 1-11.16-7V327a8 8 0 0 1 6.51-7.86l247.62-47c17.36-3.29 17.36-28.15 0-31.44l-247.63-47a8 8 0 0 1-6.5-7.85V72.59c0-5.74 5.88-10.26 11.16-8L470.3 241.76a16 16 0 0 1 0 29.39z"
                          ></path>
                        </svg>
                      </span>
                      <span className="send-icon c-pointer" onClick={() => { fileUL.current?.click() }} style={{ right: '39px', padding: '5px 13px' }}>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                          <path d="M355.9 161.1c-8.6 0-15.6 7-15.6 15.6v194.4c0 20.3-8 40.4-22 55.1-13.9 14.6-35.2 23.7-55.5 23.7h-13.6c-19.6 0-39.5-8.9-54.8-24.6-15.2-15.5-23.9-35.6-23.9-55.2V119.3c0-14.9 6.2-28.9 17.3-39.5 11.1-10.5 25.7-16.3 41-16.3 15.1 0 29.3 5.8 39.8 16.2 10.5 10.5 16.4 24.6 16.4 39.6v234.2c0 17.1-13.6 32.2-29 32.2-13.6 0-28.2-12.9-28.2-32.2V219.9c0-8.6-7-15.6-15.6-15.6s-15.6 7-15.6 15.6v133.6c0 35.5 25.5 62.3 59.4 62.3 16.5 0 31.7-6.1 42.7-17.3 11.3-11.4 17.5-27.4 17.5-45V119.3c0-23.4-9.1-45.4-25.7-61.8C274 41 252 32 228.5 32s-45.5 9-62.2 25.5c-16.7 16.5-25.9 38.4-25.9 61.9v250.8c0 28.7 12.2 57.9 32.6 78 20.9 20.6 47.9 31.9 76.1 31.9h13.6c27.6 0 55.3-11.7 75.9-32.1 10.2-10.1 18.2-21.7 23.9-34.6 5.9-13.5 8.9-27.7 8.9-42.1V176.7c.1-8.6-6.9-15.6-15.5-15.6z"></path>
                        </svg>
                        <input ref={fileUL} type="file" name="input-name" onClick={(e: any) => e.target.value = null} onChange={(e) => onUpload(e)} id="input-id" multiple className="d-none" />

                      </span>
                      <span className="send-icon c-pointer" onClick={() => setOpenEmoji(!openEmoji)} style={{ right: '0', padding: '5px 7px' }}>😀</span>

                    </div>
                    <div className="messages-chat">
                      <ChallangeChat messages={chat} peerId={EmpGuid} />
                      {/* <div id={"el"} ref={el} /> */}

                    </div>
                    {openEmoji ? <>
                      <ul className="emji-chat wellness-emji">
                        <li> <Picker data={data} onEmojiSelect={handleSelectEmoji}
                        /></li></ul> </> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {enrollPopup ? (
          <div className="SideNav text-left" style={{ width: "23%" }}>
            <div className="sideNavHeader">
              <div
                className="CloseNav"
                onClick={handleCloseEnrollPopup}
              >
                <img
                  src="/assets/media/svg/x-close.svg"
                  alt="Close Side Nav"
                  title="Close"
                />
              </div>
            </div>
            <div className="sideNavBody">
              <div className="row mt-4 small">
                <div className="col-12 mb-2 text-light fontsize-18">Enroll</div>
              </div>
              <div className="row mt-3 small">
                <div className="col-12 mt-2">
                  <h6 className="color-white fontsize-14">Value</h6>
                  <input type="number" className="form-control" id="txt-starting-value"
                    value={rejectReasonPopupData ? '' : startingValue}
                    onChange={(event) => { handleForm(event, "StartingValue") }}
                  />
                </div>
                {/* <div className="col-12 mt-2">
                  <h6 className="color-white fontsize-14">Metric</h6>
                  <input type="text" className="form-control"
                   value={metric}
                   onChange={(event) => { handleForm(event,"Metric") }}
                  />
                </div> */}
                {/* <div className="col-12 mt-2">
                  <h6 className="color-white fontsize-14">Date</h6>
                  <input type="date" className="form-control" id="date-enroll-date" disabled={rejectReasonPopupData}
                  value={rejectReasonPopupData ? '' : enrollDate}
                  onChange={(event) => { handleForm(event,"enrollDate") }}
                  />                   
                </div> */}
                <div className="col-12 mt-2">
                  <h6 className="color-white fontsize-14">Date</h6>
                  <DatePicker className="SuteInputPrimary h40px"
                    // selected={new Date(startDate)}
                    onChange={date => handleDate(date)}
                    name="enrollDate"
                    dateFormat="dd-MM-yyyy"
                    value={rejectReasonPopupData ? '' : enrollDate
                      ? Moment(enrollDate).format(dateFormated) : ''}
                    minDate={startDate ? new Date(startDate) : null}
                    maxDate={endDate ? new Date(endDate) : null}
                  />
                </div>
                {erroeMesssgeHide && (<div className="alert alert-danger text-center">{errorEndDate}</div>)}
                {errorValueMesssgeHide && (<div className="alert alert-danger text-center">{errorValueMessage}</div>)}
                <div className="col-12 text-center mt-2">
                  <div className="d-flex justify-content-center align-items-center">
                    <label htmlFor="fileInput" className="btn enroll-btn mr-auto">
                      Browse
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      className="color-white"
                      style={{ display: 'none' }}
                      onChange={(event) => { handleForm(event, "selectedFile") }}
                      onClick={handleOnclick} />
                    <button type="button" className="btn enroll-btn ml-auto"
                      onClick={(e: any) => SaveEnrollChallenges(e)}>
                      Save
                    </button>
                  </div>
                </div>
                {/* <div className="row mt-3 small"> */}
                {GetStepChallengesData.find((item: any) => item.EmpId == EmpId) &&
                  (<div className="col-12 text-center mt-2 p-0" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <table className="table border-secondary w-100 small filesTable mb-0 sidenav-table-width">
                      <thead>
                        <tr>
                          <th>Value</th>
                          {/* <th>Metrics</th> */}
                          <th>Date</th>
                          <th></th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {GetStepChallengesData && GetStepChallengesData.length > 0 &&
                          GetStepChallengesData.map((item: any, index: number) => {
                            if (item.LeaderBoardInfo.StartingValue !== 0) {
                              return (
                                <tr key={index}>
                                  <td>{item.LeaderBoardInfo.StartingValue}</td>
                                  {/* <td>{item.LeaderBoardInfo.Metric}</td> */}
                                  <td>{Moment(item.LeaderBoardInfo.EnrollDate).format(dateFormated)}</td>
                                  <td >
                                    {item.LeaderBoardInfo.FileUrl && item.LeaderBoardInfo.FileUrl.length > 0 ?
                                      (
                                        item.LeaderBoardInfo.ContentType === "application/pdf" ? (
                                          <a target="_blank" rel="noreferrer" href={item.LeaderBoardInfo.FileUrl} className="enroll-grid-file-icon">
                                            <FileIcon name={item.LeaderBoardInfo.Type} />
                                          </a>
                                        ) : (
                                          <img
                                            alt=""
                                            src={item.LeaderBoardInfo.FileUrl}
                                            className="participent-img"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleImageClick(item.LeaderBoardInfo.FileUrl)}
                                          />
                                        )
                                      ) : null}
                                  </td>
                                  <td>
                                    <span onClick={(e) => { handleRejectRejectionPopup(item) }} className={item.LeaderBoardInfo.Status === 'approved' ? "green dot" : item.LeaderBoardInfo.Status === 'rejected' ? 'red dot customcursor' : 'orange dot'}
                                      title={item.LeaderBoardInfo.Status === 'approved' ? "Approved" : item.LeaderBoardInfo.Status === 'rejected' ? 'Click here to see reject reason' : 'Pending'}></span>
                                  </td>
                                </tr>)
                            } else {
                              return null; // Skip rendering this row
                            }
                          })
                        }
                      </tbody>
                    </table>
                  </div>)
                }
                {modalVisible &&
                  <Modal backdrop="static" onHide={() => setModalVisible(false)} show={modalVisible}>
                    {/* <Modal.Header >
                                    <span className="close" style={{color:"white", cursor: "pointer" }} title="Close" onClick={() => setModalVisible(false)}>&times;</span>
                                </Modal.Header> */}
                    <Modal.Body >
                      <span className="close" style={{ color: "white", cursor: "pointer" }} title="Close" onClick={() => setModalVisible(false)}>&times;</span>
                      <img src={modalImageUrl} style={{ maxWidth: '400px', maxHeight: '400px' }} alt="Profile" />
                    </Modal.Body>
                  </Modal>

                }
                {fileErrorPopUp &&
                  <Modal backdrop="static" onHide={() => setFileErrorPopUp(false)} show={fileErrorPopUp}>
                    {/* <Modal.Header >
                                    <span className="close" style={{color:"white", cursor: "pointer" }} title="Close" onClick={() => setModalVisible(false)}>&times;</span>
                                </Modal.Header> */}
                    <Modal.Body >
                      <span className="close" style={{ color: "white", cursor: "pointer" }} title="Close" onClick={() => setFileErrorPopUp(false)}>&times;</span>
                      <h3 className="color-white">{fileError}</h3>
                    </Modal.Body>
                  </Modal>

                }
              </div>
            </div>
          </div>

          // </div>
        ) : null}
        {LeaderboardPopup ? (
          <div className="SideNav SideNavLeft" id="GroupsSideNav" style={{ width: "23%" }}>
            <div className="sideNavHeader">
              <div
                className="CloseNav"
                onClick={() => {
                  setLeaderboardPopup(false);
                }}
              >
                <img
                  src="/assets/media/svg/x-close.svg"
                  alt="Close Side Nav"
                  title="Close"
                />
              </div>
            </div>
            <div className="sideNavBody" id="GroupsList">
              <div className="row m-0">
                <div className="col-12 mb-2 text-light fontsize-18 p-0">
                  Leader Board
                </div>
              </div>

              <div className="row d-none">
                <div className="col-12 small ParticipantsSearch">
                  <input
                    className="SuteInputPrimary"
                    type="text"
                    placeholder="Search"
                    value=""
                  />
                </div>
              </div>
              {leaderBoardList && leaderBoardList.length > 0 ? (
                leaderBoardList.map((item: any, index: number) => (
                  item.NameOffEmployee && (
                    <div className="row mt-3 small" key={index}>
                      <div className="col-12 pb-1 pt-1 contactsLoop border-bottom border-secondary d-flex">
                        <div>
                          <svg
                            className="ParticipantImage stroke c-pointer active"
                            viewBox="0 0 100 100"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <defs>
                              <pattern
                                id={`pattern-${item.TotalStartingValue}`}
                                patternUnits="userSpaceOnUse"
                                width="100"
                                height="100"
                              >
                                <image
                                  href={item.FileUrl || "/assets/media/images/sute_user_default.png"}
                                  x="-25"
                                  width="150"
                                  height="100"
                                />
                              </pattern>
                            </defs>
                            <polygon
                              id={`hex-${item.TotalStartingValue}`}
                              points="50 1 95 25 95 75 50 99 5 75 5 25"
                              fill={`url(#pattern-${item.TotalStartingValue})`}
                            ></polygon>
                          </svg>
                        </div>
                        <div>
                          <p className="ParticipantName">{item.NameOffEmployee}&nbsp;&nbsp;<FaDiamond />&nbsp;&nbsp;{item.TotalStartingValue}</p>
                        </div>
                      </div>
                    </div>
                  )
                ))
              ) : (
                <div className="text-center no-data-text-center">
                  <h5 className="color-white">No Leader found</h5>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </section>
      <Modal show={rejectReasonPopup} backdrop="static">
        <Modal.Body>
          <div className="row">
            <div className="col-12 m-auto popup-settings">
              <div className="card bgCadet border-1 ">
                <div className="card-body p-0">
                  <div className="row m-0">
                    <div className="col-12">
                      <div className="my-1 d-flex">
                        <p className="text-white mr-2">Rejected by  </p>
                        <p className="text-white">{rejectReasonPopupData?.LeaderBoardInfo?.UpdatedBy} on {rejectReasonPopupData?.LeaderBoardInfo?.UpdatedDate}</p>
                      </div>
                      <div className="my-1 d-flex">
                        <p className="text-white mr-2">Reason: </p>
                        <p className="text-white">{rejectReasonPopupData?.LeaderBoardInfo?.Reason}</p>
                      </div>

                    </div>
                    <div className="col-12 mt-2">
                      <div className="row">
                        <div className="col-3 my-1 d-flex align-items-center">
                          <span className="color-white fontsize-14 mb-0">Value</span>
                        </div>
                        <div className="col-9 my-1 ">
                          <input type="number" className="form-control"
                            value={startingValue}
                            onChange={(event) => { handleForm(event, "StartingValue") }}
                          />
                        </div>
                        <div className="col-3 my-1 d-flex align-items-center">
                          <span className="color-white fontsize-14 mb-0">Date</span>
                        </div>
                        <div className="col-9 my-1">
                          <span className="color-white fontsize-14 mb-0">{Moment(enrollDate).format(dateFormated)}</span>
                        </div>
                      </div>
                    </div>
                    {erroeMesssgeEditHide && (<div className="alert alert-danger text-center">{errorEndDate}</div>)}
                    <div className="col-12 text-center mt-2 mb-3">
                      <div className="d-flex justify-content-start align-items-center">
                        <label htmlFor="fileInput" className="btn enroll-btn mr-3 mb-0">
                          Browse
                        </label>
                        <input
                          type="file"
                          id="fileInput"
                          className="color-white"
                          style={{ display: 'none' }}
                          onChange={(event) => { handleForm(event, "selectedFile") }} 
                          onClick={(e: any) => e.target.value = null}
                          />
                        <span className="text-white">
                          {(attachments || (rejectReasonPopupData?.LeaderBoardInfo?.FileUrl && rejectReasonPopupData.LeaderBoardInfo.FileUrl.length > 0)) ? (
                            rejectReasonPopupData?.LeaderBoardInfo?.ContentType === "application/pdf" ? (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={rejectReasonPopupData.LeaderBoardInfo.FileUrl}
                                className="enroll-grid-file-icon"
                              >
                                <FileIcon name={rejectReasonPopupData.LeaderBoardInfo.Type} />
                              </a>
                            ) : (
                              <img
                                alt=""
                                src={attachments || rejectReasonPopupData?.LeaderBoardInfo?.FileUrl}
                                className="participent-img"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleImageClick(attachments || rejectReasonPopupData?.LeaderBoardInfo?.FileUrl)}
                              />
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex align-content-center justify-content-end">
                      <button type="button" className="btn btn-SeaGreen mr-2 px-3" onClick={(e) => [
                        setRejectReasonPopup(false),
                        setEnrollDate(''),
                        setRejectReasonPopupData(null),
                        setStartingValue(0),
                        setAttachments('')
                      ]}>Cancel</button>
                      <button type="button" className="btn btn-SeaGreen px-3" onClick={(e) => [
                        EditEnrollChallenges(e)
                      ]}>Re-Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default challengesSteps;
