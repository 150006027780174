import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Mslogin from "./Mslogin";
import { Login as Login1 } from "../../actions/AuthActions";
import { t } from '../../i18n';
import { GetIP, removeSuccessStatus } from "../../services/ContactsService";

interface LoginProps {
    username: string;
    password: string
}
interface formError {
    message: string;
}

function Login(props: any) {
    // ;
    const [values, setValues] = useState({
        username: "",
        password: ""
    } as LoginProps);
    const [errors, setErrors] = useState({} as formError);
    const history = useNavigate();
    const dispatch = useDispatch();
    const [showPwd, setShowPwd] = useState(false);
    useEffect(() => {
        if (props.user && props.user.empData && props.user.empData.EmpGuid.length > 0 && props.user.isAuthenticated) {
            history("/pod");
        }
        setErrors({ message: '' });
        dispatch(removeSuccessStatus() as any)

    }, []);

    useEffect(() => {
        if (props.user.error) {
            setErrors({ ...props.user.error });
        } else {
            setErrors({ message: '' });
        }
        if (sessionStorage.getItem('msal.login.error')) {
            setErrors({ message: sessionStorage.getItem('msal.login.error') || '' });
        }
    }, [props.user?.error])


    useEffect(() => {
        if (props.user.empData.EmpGuid.length > 0 && props.user.isAuthenticated) {

            if (localStorage.getItem("afterLoginRedirect")) {
                const rd = localStorage.getItem("afterLoginRedirect");
                localStorage.removeItem("afterLoginRedirect");
                history(rd || "/pod");
            } else {
                history("/pod");
            }
        }
    }, [props.user.isAuthenticated])

    const handleSubmit = (e: any, login: boolean) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (e.key === 'Enter' || login) {
            e.preventDefault();
            if (values.username == '' && !values.password) {
                setErrors({ message: 'Enter valid email address and password' })
                return
            }
            if (values.username == '' || !emailRegex.test(values.username)) {
                setErrors({ message: 'Enter valid email address' })
                return
            }
            if (!values.password) {
                setErrors({ message: 'Enter valid password' })
                return
            }
            const userData = {
                username: values.username,
                password: values.password,
            };
            dispatch(removeSuccessStatus() as any)
            props.loginSubmit(userData, history);
            // dispatch(GetIP() as any)
            //console.log('do validate');
        }

    }

    const handleChange = (e: any) => {
        e.persist();
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }));
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (values.username.length >= 0 || emailRegex.test(values.username)) {
            setErrors({ message: '' })
        }
        if (values.password) {
            setErrors({ message: '' })
        }
    };

    const zoomOAuth = (event: any) => {
        event.preventDefault();
        var returnURL = window.location.hostname;
        //alert(returnURL);
        if (returnURL === 'localhost') {
            returnURL = 'http://' + window.location.hostname + ':3000/zoom';
        } else {
            returnURL = 'https://' + window.location.hostname + '/zoom';
        }
        //alert(returnURL);
        window.location.href = "https://zoom.us/oauth/authorize?response_type=code&client_id=QA0ttVF1Q_WGUL4dziZyNg&redirect_uri=" + returnURL;

    };

    const errormsg = (<div className="alert alert-danger col-4 ml-auto mr-auto mt-3 text-center">
        {errors.message}
    </div>);

    return (<>
        <section className="bgCadet min-vh-100 position-relative d-flex">
            <div className="container align-self-center">
                {/* <PublicHeader title="Login" /> */}
                {errors.message && errormsg}
                <div className="row ">
                    <div className="col-sm-4 m-auto">
                        <div className="card loginBox pb-3">
                            <div className="card-body pl-5 pr-5">
                                <div className="row">
                                    <div className="col-12 text-center"> <img className="loginLogo" src="/assets/media/images/logo-sm.png" alt="Sute" style={{ width: "47px", height: "53px" }} /> </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-4 mt-4 text-center text-white">
                                        <h5 className="font-weight-normal">{t('login.sign_in')}</h5>
                                        <p>{t('login.welcome_msg')}</p>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="text-light small m-0">{t('login.email')}</label>
                                    <input className="form-control fontsize-16" type="text" name="username" onChange={handleChange} /> </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="text-light small m-0">{t('login.password')} </label>
                                    <input className="form-control fontsize-16 password-input" type={showPwd ? "text" :"password"} name="password" onChange={handleChange} onKeyDown={(e) => handleSubmit(e, false)} />
                                    <span className="show-password"  onClick={() => setShowPwd(!showPwd)}>
                                        <svg className={showPwd ? "" : 'd-none'} stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                        <svg className={showPwd ? "d-none" : ''} stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                                    </span>
                                </div>
                                <div className="form-check text-light">
                                    <input type="checkbox" className="form-check-input" name="rememberme" id="rememberme" />
                                    <label htmlFor="rememberme" className="form-check-label">{t('login.keep_logged_in')}</label>
                                </div>
                                <div className="form-group mt-3"> <button onClick={(e) => handleSubmit(e, true)} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-right">{t('button.login')}</button> </div>
                            </div>
                            <div className="form-check text-center">
                                <Link className="text-muted" to="/forget-password">{t('login.forget_password')}</Link>
                            </div>
                        </div>
                        <div className="form-group">
                            {/* <div className="mt-4 p-0 small text-center text-light">{t('login.or_login_with')}
                                <Mslogin history={history} /> */}

                                {/*       <img className="c-pointer" alt="" src="/assets/media/svg/slack.svg" height="36" />
                                <img onClick={zoomOAuth} className="c-pointer" alt="" src="/assets/media/svg/zoom.svg" height="36" /> 
                            </div>
                            </div>*/}
                            {/*  <div className="form-group m-0">
                            <p className="text-light small text-center">{t('forgetpassword.dont_have_an_account')} <Link className="text-light ml-1" to="/signup">{t('forgetpassword.sign_up')}</Link></p>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer
});

const mapDispatchToProps = (dispatch: any, history: any) => ({
    loginSubmit: (stateobj: any, history: any) => {
        dispatch(Login1(stateobj, history))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
