import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import store from "./store/Store";
import Toast from "./components/toast";
import Gloader from "./components/Gloader";
import GuestRoute from "./utils/GuestRoute";
import PrivateRoute from "./utils/PrivateRoute";
import Home from "./pages";
import Login from "./pages/login/Login";
import Pod from "./pages/dashboard/Pod";
import User from "./pages/hr/User";
import CompanyInfo from "./pages/settings/CompanyInfo";
import CustomRoom from "./pages/rooms/customRoom";
import Files from "./pages/myoffice/Files";
import Users from "./pages/hr/Users";
import HelpFeedBack from "./pages/helpFeedback/helpFeedback";
import Resources from "./pages/resources/resources";
import Chat from "./pages/chat/chat";
import FeedBack from "./pages/feedback/feedback";
import MyProfile from "./pages/dashboard/MyProfile";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Companies from "./pages/hr/Companies";
import Company from "./pages/hr/Company";
import HangoutRoom from "./pages/rooms/HangoutRoom";
import Produce from "./pages/myoffice/Produce";
import UserProfile from "./pages/settings/UserProfile";
import Calendar from "./pages/calendar/Calendar";
import EarnLearn from "./pages/rooms/EarnLearn";
import Learn from "./pages/learn/learn";
import Leadership from "./pages/leadership/leadership";
import Diversity from "./pages/diversity/diversity";
import Philanthropy from "./pages/philanthropy/philanthropy";
import SuteSelections from "./pages/suteSelections/suteSelections";
import SoundBathMeditations from "./pages/soundBathMeditations/soundBathMeditations";
import MentalHealth from "./pages/mentalHealth/mentalHealth";
import LifeBalance from "./pages/lifeBalance/lifeBalance";
import GuidedMeditations from "./pages/guidedMeditations/guidedMeditations";
import PersonalGrowth from "./pages/personalGrowth/personalGrowth";
import IncomingCallDialog from './pages/myoffice/IncomingCallDialog';
import ForgetPassword from './pages/forgetPassword/ForgetPassword';
import WindowFocusHandler from "./components/WindowsFocusHandler";
import { useEffect } from "react";
import Checkins from './pages/myoffice/Checkins';
import CheckinHistory from "./pages/myoffice/CheckinHistory";
import TeamCheckinHistory from "./pages/myoffice/TeamCheckinHistory";
import CompanyFiles from "./pages/orgFiles/CompanyFiles";
import ManageBulletinBoard from "./pages/orgFiles/ManageBulletinBoard";
import Admin from "./pages/dashboard/Admin";
import Groups from "./pages/hr/Groups";
import Assessment from "./pages/wellness/assessment";
import HealthTracking from "./pages/wellness/healthTracking";
import Information from "./pages/wellness/information";
import YourActivity from "./pages/wellness/yourActivity";
import SelfHelp from "./pages/wellness/selfHelp";
import BuddyChallenges from "./pages/wellness/buddyChallenge";
import happyHours from "./pages/wellness/happyHours";
import FitnessVideos from "./pages/wellness/fitnessVideos";
import chatroulette from "./pages/wellness/chatroulette";
import Challenges from "./pages/wellness/Challenges";
import Classes from "./pages/wellness/Classes";
import WellnessPartners from "./pages/wellness/WellnessPartners";
import Leaderboard from "./pages/wellness/Leaderboard";
import CuratedChallenges from "./pages/wellness/CuratedChallenges";
import challengesSteps from "./pages/wellness/challengesSteps";
import IncomingChatDialog from "./pages/myoffice/incomingChat";
import ChatBot from "./pages/chat/chatBot";
import { useClearCache } from 'react-clear-cache';
import myMeetings from "./pages/myMeetings/myMeetings";
import ChallengesMenu from "./pages/hr/ChallengesMenu";
import NewChallenges from "./pages/hr/NewChallenges";
import OngoingChallenges from "./pages/hr/OngoingChallenges";
import CompletedChallenges from "./pages/hr/CompletedChallenges";
import OngoingChallengesDetails from "./pages/hr/OngoingChallengesDetails";
import MettingRoom from "./pages/rooms/MettingRoom";
import CollaborationHub from "./pages/collaborationHub/CollaborationHub";
import NewChallengesList from "./pages/hr/NewChallengesList";
import InvitePeople from "./pages/myoffice/InvitePeople";
import registration from "./pages/myoffice/registration";

//import MettingRoom from "./pages/rooms/customRoom";
console.log("#TEST");
function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {

    const NEWver = process.env.REACT_APP_VERSION as string;
    let OLDver = localStorage.getItem('SUTE_APP_VERSION') as string;

    if (!OLDver) {
      chacheClear(NEWver)
    }
    else {
      if (OLDver.trim() != NEWver.trim()) {
        chacheClear(NEWver)
      }

    }
    console.log(OLDver, NEWver);
    console.log("#TEST App");

  }, [])

  const chacheClear = (NEWver: any) => {
    emptyCacheStorage();
    localStorage.setItem('SUTE_APP_VERSION', NEWver);
  }

  return (
    <Provider store={store}>
      <Router>
        <Gloader />
        <Routes>
          <Route path="/" element={<GuestRoute component={Home} />} />
          <Route path="/login" element={<GuestRoute component={Login} />} />
          <Route path="/pod" element={<PrivateRoute component={Pod} />} />
          <Route path="/admin" element={<PrivateRoute component={Admin} />} />
          {/* <PrivateRoute  path='/companySetup' element={<Home/>} /> */}
          {/* <RootProvider> */}
          <Route
            path="/conference/:meetingPId"
            element={<GuestRoute component={MettingRoom} />}
          />
          <Route
            path="/customrm/:meetingPId"
            element={<GuestRoute component={MettingRoom} />}
          />
          <Route
            path="/companyInfo"
            element={<PrivateRoute component={CompanyInfo} />}
          />
          <Route path="/Induction" element={<PrivateRoute component={CompanyFiles} />}
          />
          <Route path="/user" element={<PrivateRoute component={User} />} />
          <Route path="/files" element={<PrivateRoute component={Files} />} />
          <Route
            path="/help-feedback"
            element={<PrivateRoute component={HelpFeedBack} />}
          />
          <Route
            path="/resources"
            element={<PrivateRoute component={Resources} />}
          />
          <Route path="/chat" element={<PrivateRoute component={Chat} />} />
          <Route
            path="/feedback"
            element={<PrivateRoute component={FeedBack} />}
          />
          <Route
            path="/my-profile"
            element={<PrivateRoute component={MyProfile} />}
          />
          <Route
            path="/hangoutroom"
            element={<PrivateRoute component={HangoutRoom} />}
          />
          <Route
            path="/produce"
            element={<PrivateRoute component={Produce} />}
          />
          <Route
            path="/checkins"
            element={<PrivateRoute component={Checkins} />}
          />
          <Route
            path="/checkinhistory"
            element={<PrivateRoute component={CheckinHistory} />}
          />
          <Route
            path="/teamcheckinhistory"
            element={<PrivateRoute component={TeamCheckinHistory} />}
          />
          <Route
            path="/userProfile"
            element={<PrivateRoute component={UserProfile} />}
          />
          <Route
            path="/calendar"
            element={<PrivateRoute component={Calendar} />}
          />
          <Route
            path="/earn-learn"
            element={<PrivateRoute component={EarnLearn} />}
          />
          <Route path="/learn" element={<PrivateRoute component={Learn} />} />
          <Route
            path="/leadership"
            element={<PrivateRoute component={Leadership} />}
          />
          <Route
            path="/diversity"
            element={<PrivateRoute component={Diversity} />}
          />
          <Route
            path="/philanthropy"
            element={<PrivateRoute component={Philanthropy} />}
          />
          <Route
            path="/life-balance"
            element={<PrivateRoute component={LifeBalance} />}
          />

          {/* </RootProvider> */}

          <Route path="/users" element={<PrivateRoute component={Users} />} />
          <Route path="/companies" element={<PrivateRoute component={Companies} />} />
          <Route path="/company" element={<PrivateRoute component={Company} />} />
          <Route
            path="/forget-password"
            element={<GuestRoute component={ForgetPassword} />}
          />
          <Route
            path="/reset-password/:tokenId"
            element={<GuestRoute component={ResetPassword} />}
          />
          <Route
            path="/sute-selections"
            element={<PrivateRoute component={SuteSelections} />}
          />
          <Route
            path="/soundbath-meditations"
            element={<PrivateRoute component={SoundBathMeditations} />}
          />
          <Route
            path="/mental-health"
            element={<PrivateRoute component={MentalHealth} />}
          />
          <Route
            path="/guided-meditations"
            element={<PrivateRoute component={GuidedMeditations} />}
          />
          <Route
            path="/personal-growth"
            element={<PrivateRoute component={PersonalGrowth} />}
          />
          {/* <Route
            path="/peer-peer/:fromId/:toId"
            element={<PrivateRoute component={p2pRoom} />}
          /> */}
          {/* <Route
            path="/wellness"
            element={<GuestRoute component={CustomRoom} />}
          /> */}
          <Route
            path="/ManageBulletinBoard"
            element={<PrivateRoute component={ManageBulletinBoard} />}
          />
          <Route
            path="/groups" element={<PrivateRoute component={Groups} />}
          />
          <Route
            path="/AdminChallenges" element={<PrivateRoute component={ChallengesMenu} />}
          />
          <Route
            path="/NewChallenges" element={<PrivateRoute component={NewChallenges} />}
          />
          <Route
            path="/OngoingChallengesDetails/:orgGuidParam/:stepId" element={<PrivateRoute component={OngoingChallengesDetails} />}
          />
          <Route
            path="/OngoingChallenges" element={<PrivateRoute component={OngoingChallenges} />}
          />
          <Route
            path="/CompletedChallenges" element={<PrivateRoute component={CompletedChallenges} />}
          />

          <Route
            path="/healthtracking"
            element={<PrivateRoute component={HealthTracking} />}
          />
          <Route
            path="/information"
            element={<PrivateRoute component={Information} />}
          />
          <Route
            path="/youractivity"
            element={<PrivateRoute component={YourActivity} />}
          />
          <Route
            path="/selfhelp"
            element={<PrivateRoute component={SelfHelp} />}
          />
          <Route
            path="/buddychallenges"
            element={<PrivateRoute component={BuddyChallenges} />}
          />
          <Route
            path="/happyhours"
            element={<PrivateRoute component={happyHours} />}
          />
          <Route
            path="/wellnessMedia"
            element={<PrivateRoute component={FitnessVideos} />}
          />
          <Route
            path="/chatroulette"
            element={<PrivateRoute component={chatroulette} />}
          />
          <Route
            path="/assessment"
            element={<PrivateRoute component={Assessment} />}
          />
          <Route
            path="/challenges"
            element={<PrivateRoute component={Challenges} />}
          />
          <Route
            path="/classes"
            element={<PrivateRoute component={Classes} />}
          />
          <Route
            path="/wellnesspartners"
            element={<PrivateRoute component={WellnessPartners} />}
          />
          <Route
            path="/leaderboard"
            element={<PrivateRoute component={Leaderboard} />}
          />
          <Route
            path="/curatedchallenges"
            element={<PrivateRoute component={CuratedChallenges} />}
          />
          <Route
            path="/challengesSteps"
            element={<PrivateRoute component={challengesSteps} />}
          />
           <Route
            path="/myMeetings"
            element={<PrivateRoute component={myMeetings} />}
          />
           <Route
            path="/collaboration-hub"
            element={<PrivateRoute component={CollaborationHub} />}
          />
           <Route
            path="/Challenge" element={<PrivateRoute component={NewChallengesList} />}
          />
          <Route path="/invitepeople" element={<PrivateRoute component={InvitePeople} />} />
          <Route path="/registration" element={<GuestRoute component={registration} />} />
        </Routes>
        <WindowFocusHandler />
        <Toast />
        <ChatBot />       
        <IncomingCallDialog />
      </Router>
    </Provider>
  );
}

export default App;
