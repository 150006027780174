import { Table } from "../../components/Table";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import ConfirmPopup from "../../layout/ConfirmPopup";
import { GetPreferredContacts, removeSuccessStatus } from "../../services/ContactsService";
import { DeleteFile, DeleteFolder, DownloadFile, GetFileListByEmpGuid, GetFileSharedContacts, GetFolderListByEmpGuid, GetSharedFileByContact, MoveFileToSelectedFolder, RenameFile, SaveFile, SaveFolder, ShareFile, SyncGoogleDrive, UploadNewFiles } from "../../services/FileService";
import { globalStore } from "../../store/global_store";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from 'classnames';
import { getEmployeeCalanderEvents } from "../../services/CalenderSevice";
import { t } from '../../i18n';
import { getAsyncEmpDetailsByEmpGuid } from "../../services/UserActions";
import FileIcon from "../../components/FileIcon";
import { useNavigate, useParams , useLocation} from "react-router-dom";
import { result } from "lodash";
import { GetFileSharedByFileID, IsFileDuplicate,IsFileNameDuplicate,SaveLabel, GetLabelList,GetEmployeeLabels , SaveEmployeeLabel } from "../../services/FileService";
import { useDispatch, useSelector } from "react-redux";
import * as T from "../../actions/Types";
import Select from 'react-select';
import { GetContacts } from "../../services/UserActions";
import { stringify } from "querystring";
import { Modal } from 'react-bootstrap';
import Moment from 'moment';

var deleteFileId: number;
var deleteFolderId: number;
var renameFileName: boolean;
var renameFolderName: boolean;
var FileId: string;
interface Folder {
    FolderId: string;
    FolderGuid: string;
    FolderName: string;
    EmpGuid: string;
    EmpId: string;
    CanCreate: boolean;
}

export interface EmpFile {
    EmpGuid: string;
    FileId: string;
    EmpId: string;
    FileGuid: string;
    UploadFile: string;
    FileName: string;
    OriginalName: string;
    FileURL: string;
    Path: string;
    FileType: string;
    ContentType: string;
    FileSize: string;
    FolderId: string;
    CanSave: boolean;
}

interface ToFolder {
    FolderId: string;
    FileId: string;
}

interface ShareFile {
    FileId: string;
    Contacts: string[];
    EmpGuid: string;
}

const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer,
    employee: state.EmployeeReducer
});

const mapDispatchToProps = (dispatch: any) => ({
    GetFoldersByEmpGuid: (empGuid: any) => {
        dispatch(GetFolderListByEmpGuid(empGuid))
    },
    SaveFolder: (params: Folder) => {
        dispatch(SaveFolder(params))
    },
    SaveFile: (params: File) => {
        dispatch(SaveFile(params))
    },
    UploadNewFiles: (params: File) => {
        dispatch(UploadNewFiles(params))
    },
    RenameFile: (params: File) => {
        dispatch(RenameFile(params))
    },
    DeleteFile: (empGuid: string, fileId: number) => {
        dispatch(DeleteFile(empGuid, fileId))
    },
    GetFilesByEmpGuid: (params: FilesTableBody, empGuid: string, FolderId: number) => {
        dispatch(GetFileListByEmpGuid(params, empGuid, FolderId , JSON.stringify({})))
    },
    DownloadFile: (params: File) => {
        dispatch(DownloadFile(params))
    },
    MoveFileToSelectedFolder: (params: ToFolder) => {
        dispatch(MoveFileToSelectedFolder(params))
    },
    SyncGoogleDrive: (email: string, empId: string) => {
        dispatch(SyncGoogleDrive(email, empId))
    },
    DeleteFolder: (empGuid: string, folderId: number) => {
        dispatch(DeleteFolder(empGuid, folderId))
    },
    getEmployeeCalanderEvents: (empId: string) => {
        dispatch(getEmployeeCalanderEvents(empId,new Date().getTimezoneOffset()));
    },
    GetPreferredContacts: (empId: string) => {
        dispatch(GetPreferredContacts(empId));
    },
    ShareFile: (fileId: string, empId: string, contacts: string, Externalmails: string) => {
        dispatch(ShareFile(fileId, empId, contacts, Externalmails));
    },
    GetFileSharedContacts: (empId: string,) => {
        dispatch(GetFileSharedContacts(empId));
    },
    GetSharedFileByContactGuid(empGuid: any, contactGuid: any) {
        dispatch(GetSharedFileByContact(empGuid, contactGuid))
    },
    RemoveSuccessStatus: () => {
        dispatch(removeSuccessStatus());
    },
})

function Files(props: any) {
    let params: any = useParams();


    const empId = localStorage.getItem('EmpGuid') || '';
    const orgId = localStorage.getItem('OrgGuid') || '';
    const employeeId = localStorage.getItem('EmpId') || 0 ;
    const [createFolder, setCreateFolder] = useState({ FolderId: '', FolderGuid: '00000000-0000-0000-0000-000000000000', FolderName: '', EmpGuid: '00000000-0000-0000-0000-000000000000', EmpId: '', CanCreate: false } as Folder)
    const [saveFile, setSaveFile] = useState({} as EmpFile)
    const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
    const [showConfirmFileDelete, setShowConfirmFileDelete] = useState(false);
    const [showConfirmFolderDelete, setShowConfirmFolderDelete] = useState(false);
    const [newFileName, setNewFileName] = useState('');
    const [newFolderName, setNewFolderName] = useState('');
    const [selectedFolderId, setselectedFolderId] = useState('0');
    const [fileToFolder, setFileToFolder] = useState({ FileId: '', FolderId: 'select' } as ToFolder)
    const [showCreateFolder, setShowCreateFolder] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [contacts, setContacts] = useState<any>([]);
    const [showSharedTable, setShowSharedTable] = useState(false);
    const [sharedContact, setSharedContact] = useState<any>({});
    const [showFolderList, setShowFolderList] = useState(true);
    //const [FileLabel, setFileLabel] = useState('');
    const [externalUser, setExernalUser] = useState(false);
    const [mail, setMail] = useState('');
    const [extMails, setExtMails] = useState<any>([]);
    const [contactsOptions, setContactsOptions] = useState<any>([]);
    const [sltContacts, setSltContacts] = useState<any>([]);
    const [duplicateFiles, setDuplicateFiles] = useState<any>([]);
    const [duplicatedOption, setDuplicatedOption] = useState('');
    const [LabelName,setLabelName] = useState('');
    const [EmpLabelsoptions,setEmpLabelsOptions] = useState<any>([]);
    const [EmpLabels,setEmpLabels] = useState<any>([]);
    const api_domian = process.env.REACT_APP_API_HOST as string;
    const api_port = process.env.REACT_APP_API_PORT as string;
    const history = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state: any) => ({ user: state?.AuthReducer }));
    const location = useLocation();
    const loc = location.state;
    const [isSaveInProgress, setSaveInProgress ] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const formattedDate = process.env.REACT_APP_DATE_FORMAT 
    const [fileSerch,setFileSearch] = useState([] as any)


    useEffect(() => {
        props.GetFoldersByEmpGuid(empId);
        //props.GetFileSharedContacts(empId);
        if (props.employee.events.length == 0 || props.employee.events == undefined) {
            // props.getEmployeeCalanderEvents(empId);
        }
        if ((!props.user.prefcontacts || props.user.prefcontacts.length === 0)) {
            props.GetPreferredContacts(empId);
        }
        if (((params || {}).id || '').length > 0) {
            props.GetSharedFileByContactGuid(empId, params.id);
            console.log('shared userdid', params.id);
            getAsyncEmpDetailsByEmpGuid(params.id).then((usrDtls: any) => {
                setSharedContact({ empGuid: usrDtls.EmpGuid, ProfileImageURL: usrDtls.ProfileImageURL, name: usrDtls.FirstName });
            });
            setShowSharedTable(true);
        } else {
            props.GetFilesByEmpGuid(filesTableParams, empId, 0);
        }

        dispatch(GetContacts(orgId, empId) as any);

        GetLabelList(orgId,employeeId).then((resp:any)=>{
            if (resp.status === "success"){
                const result = removeDuplicateLabels(resp?.result) || [];
                setEmpLabelsOptions(result?.map((obj:any) => {return { value: obj.LabelId, label: obj.LabelName }}));
            }
        })
    }, [])

    function removeDuplicateLabels(data: any) {
        let uniqueNames: any = {};
        let result = [];
        for (let obj of data) {
            if (!uniqueNames[obj.LabelName]) {
                result.push(obj);
                uniqueNames[obj.LabelName] = true;
            }
        }
        return result;
    }

    useEffect(() => {
        console.log("props.user.folderList");
        console.log(props.user.success.result);
        if (props.user.success.message === 'Folder Created Successfully.') {
            globalStore.showToast({
                type: 'Folder',
                title: "Create Folder",
                message: "Folder Created Successfully."
            });
            console.log("props.user.folderList 44444");
            document.getElementById('createfldrCloseBtn')?.click();
            //setShowCreateFolder(true);
            setNewFolderName('');
            setCreateFolder({ ...createFolder, FolderName: '' });
            console.log("props.user.folderList 111");
            props.GetFoldersByEmpGuid(empId);
            console.log("props.user.folderList 2222");
            console.log(props.user.folderList?.result);
            props.GetFilesByEmpGuid(filesTableParams, empId, 0);
            props.RemoveSuccessStatus();
            setSaveInProgress(false)
            
        }
        else if (props.user.success === 'File Saved Successfully.') {
            console.log("toast1")
            if(duplicateFiles.length === 0){
                document.getElementById('FileUploadCloseBtn')?.click();
            }
            
            globalStore.showToast({
                type: 'File',
                title: "Save File",
                message: "File Saved Successfully."
            });

            props.GetFilesByEmpGuid(filesTableParams, empId, selectedFolderId);
            props.RemoveSuccessStatus();
        }
        else if (props.user.success.message === 'Files fetched successfully.') {

        }
        else if (props.user.success.message === 'File name changed successfully.') {
            //props.GetFilesByEmpGuid(filesTableParams, empId, 0);
            document.getElementById('renameFileCloseBtn')?.click();
            setNewFileName('');
            renameFileName = false;
            props.GetFilesByEmpGuid(filesTableParams, empId, selectedFolderId);
            props.RemoveSuccessStatus();
        }
        else if (props.user.success.message === 'Folder name updated Successfully.') {
            props.GetFoldersByEmpGuid(empId);
            document.getElementById('renameFolderCloseBtn')?.click();
            setNewFolderName('');
            renameFolderName = false;
            props.GetFilesByEmpGuid(filesTableParams, empId, selectedFolderId);
            props.RemoveSuccessStatus();
        }
        else if (props.user.success.message === 'File Deleted Successfully.') {
            globalStore.showToast({
                type: 'File',
                title: "Delete File",
                message: "File Deleted Successfully."
            });
            props.GetFilesByEmpGuid(filesTableParams, empId, selectedFolderId);
            props.RemoveSuccessStatus();
        }
        else if (props.user.success.message === 'Folder Deleted Successfully.') {
            globalStore.showToast({
                type: 'Folder',
                title: "Delete Folder",
                message: "Folder Deleted Successfully."
            });
            props.GetFoldersByEmpGuid(empId);
            props.GetFilesByEmpGuid(filesTableParams, empId, 0);
            props.RemoveSuccessStatus();
        }
        else if (props.user.success === 'Sync successfull') {
            globalStore.showToast({
                type: 'Sync',
                title: "Sync Drive",
                message: "Drive Sync Successfull"
            });
            props.GetFoldersByEmpGuid(empId);
            props.GetFilesByEmpGuid(filesTableParams, empId, 0);
            props.RemoveSuccessStatus();
        }
        else if (props.user.success.message === 'File moved successfully.') {
            globalStore.showToast({
                type: 'File',
                title: "Move File",
                message: "File Moved Successfully."
            });
            document.getElementById('moveFileCloseBtn')?.click();
            props.GetFilesByEmpGuid(filesTableParams, empId, selectedFolderId);
            props.RemoveSuccessStatus();
        }
        else if (props.user.success === 'FilesShared') {
            globalStore.showToast({
                type: 'File',
                title: "Share File",
                message: "File Shared Successfully."
            });
            document.getElementById('closeContactspopup')?.click();
            props.GetFilesByEmpGuid(filesTableParams, empId, selectedFolderId);
            props.RemoveSuccessStatus();
        }
    }, [props.user.folderList, props.user.success])

    useEffect(() => {
        if (createFolder.CanCreate && createFolder.FolderName && createFolder.FolderId == '0') {
            const formData = new FormData();
            formData.append('EmpGuid', createFolder.EmpGuid);
            formData.append('FolderId', createFolder.FolderId);
            formData.append('FolderName', createFolder.FolderName);
            const fileIdValue = fileToFolder.FileId && Number(fileToFolder.FileId) > 0 ? fileToFolder.FileId : 0;
            formData.append('FileId', fileIdValue.toString());
            props.SaveFolder(formData);
            setFileToFolder({...fileToFolder,FileId : ''})
        }
        if (createFolder.CanCreate && createFolder.FolderName == '') {
            // setError('Enter Folder Name to Create');
            // setShowError(true);
        }
        if (!createFolder.CanCreate && createFolder.FolderName && createFolder.FolderId != '0' && renameFolderName) {
            const formData = new FormData();
            // console.log(createFolder.FolderName);
            formData.append('EmpGuid', createFolder.EmpGuid);
            formData.append('FolderId', createFolder.FolderId);
            formData.append('FolderName', createFolder.FolderName);
            formData.append('FileId', "0");
            props.SaveFolder(formData);
        }
    }, [createFolder])

    useEffect(() => {
        // if (saveFile.CanSave && saveFile.EmpGuid && saveFile.FileId == '0') {
        //     const formData = new FormData();
        //     //console.log(saveFile);
        //     formData.append('EmpGuid', saveFile.EmpGuid);
        //     formData.append('FolderId', saveFile.FolderId);
        //     formData.append('FileId', saveFile.FileId);
        //     formData.append('FileName', saveFile.FileName);
        //     formData.append('OriginalName', saveFile.OriginalName);
        //     formData.append('FileType', saveFile.FileType);
        //     formData.append('FileSize', saveFile.FileSize);
        //     formData.append('File', saveFile.UploadFile);
        //     formData.append('ContentType', saveFile.ContentType);
        //     props.SaveFile(formData);
        // }
        // if (!saveFile.CanSave && saveFile.EmpGuid && saveFile.FileId != '0' && newFileName != '' && renameFileName) {
        //     const formData = new FormData();
        //     //console.log(saveFile);
        //     formData.append('EmpGuid', saveFile.EmpGuid);
        //     formData.append('FileId', saveFile.FileId);
        //     formData.append('OriginalName', saveFile.OriginalName);
        //     props.RenameFile(formData);
        // }
    }, [saveFile])

    // useEffect(() => {
    //     props.GetFilesByEmpGuid(filesTableParams, empId, selectedFolderId);
    // }, [filesTableParams])

    useEffect(() => {
        setContactsInSelect();
    }, [user.contacts])

    useEffect(() => {
        if (duplicateFiles.length > 0) {
            console.log("duplicateFiles",duplicateFiles);
           const modal = new (window as any).bootstrap.Modal(document.getElementById('FileUpload'));
           modal.show();
        }
    }, [duplicateFiles]);

    const setContactsInSelect = () => {
        if (user.contacts) {
            const cont = user.contacts.map((con: any) => {
                if (con.EmpGuid !== empId) {
                    return { value: con.EmpGuid, label: `${con.FirstName} ${con.LastName}` };
                }
                else {
                    return;
                }
            }).filter((item: any) => item !== undefined);
            setContactsOptions([...cont]);
        }
    }

    const clearContactFileSearch = () => {
        setShowSharedTable(false);
        setSharedContact({});
        props.GetFilesByEmpGuid(filesTableParams, empId, 0);
        history('/files');
    }

    const SaveFolder = () => {
        //props.user.folderList --> filter from this array and check whether exist or not
        if(createFolder.FolderName) {
            setSaveInProgress(true);
            let folderNames: any = [];
            props.user.folderList.result.forEach((folder: any) => {
                folderNames.push(folder.FolderName);
            });
            var isExist = folderNames.includes(createFolder.FolderName.trimStart());
            if (!isExist) {
                setCreateFolder({ ...createFolder, FolderId: '0', EmpGuid: empId, CanCreate: true });
                setError('');
            }
            else {
                setError('Folder Name already Exist');
                setShowError(true);
                setSaveInProgress(false);
            }
        }else {
            setError('Enter Folder Name to Create');
            setShowError(true);
        }
    }

     const RenameFileName = async () => {
        // let fileNames: any = [];
        // (props.user.userFiles || []).forEach((file: any) => {
        //     if (saveFile.FileId != file.FileId) {
        //         fileNames.push(file.OriginalName);
        //     }
        // });
//var isFileExist = fileNames.includes(newFileName.trim());

        
        if (newFileName.length > 0) {
            const resp :any = await IsFileNameDuplicate(empId,newFileName,saveFile.FileId)
            if (!resp.result) {
                renameFileName = true;
                //setSaveFile({ ...saveFile, CanSave: false, EmpGuid: empId, OriginalName: newFileName });
                const formData = new FormData();
                //console.log(saveFile);
                formData.append('EmpGuid', empId);
                formData.append('FileId', saveFile.FileId);
                formData.append('OriginalName', newFileName);
                formData.append('Tag', '');
                props.RenameFile(formData);
                setError('');
            }
            else {
                setError('File Name already Exist');
                setShowError(true);
            }
        }
        else {
            setError('Enter a Valid File Name');
            setShowError(true);
        }
    }
    const LabelEmpFile = async () => {
        // let Labels: any = [];
        // (props.user.userFiles || []).forEach((file: any) => {
        //     if (saveFile.FileId != file.FileId) {
        //         Labels.push(file.Tag);
        //     }
        // });
        // var isFileExist = Labels.includes(FileLabel.trim());
        // if (FileLabel.length > 0) {
        //     if (!isFileExist) {
        //         // renameFileName = true;
        //         //setSaveFile({ ...saveFile, CanSave: false, EmpGuid: empId, OriginalName: newFileName });
        //         const formData = new FormData();
        //         //console.log(saveFile);
        //         formData.append('EmpGuid', empId);
        //         formData.append('FileId', saveFile.FileId);
        //         formData.append('OriginalName', '');
        //         formData.append('Tag', FileLabel);
        //         props.RenameFile(formData);
        //         setError('');
        //     }
        //     else {
        //         setError('Label Name already Exist');
        //         setShowError(true);
        //     }
        // }
        // else {
        //     setError('Enter a Valid Label');
        //     setShowError(true);
        // }
        if(EmpLabels.length > 0) {
            const tempLabelId = EmpLabels?.map((obj :any) => {return obj.value})
            const resp : any = await SaveEmployeeLabel(employeeId,saveFile.FileId,tempLabelId.toString())
            if(resp.status === "success"){
                document.getElementById('labelFileCloseBtn')?.click();
            }
        }else {
            setError('Please select any label.')
            setShowError(true);
        }
    }
    const RenameFolderName = () => {
        let folderNames: any = [];
        props.user.folderList.result.forEach((folder: any) => {
            folderNames.push(folder.FolderName);
        });
        var isExist = folderNames.includes(newFolderName.trim());
        if (newFolderName.length > 0) {
            if (!isExist) {
                renameFolderName = true;
                setCreateFolder({ ...createFolder, CanCreate: false, EmpGuid: empId, FolderName: newFolderName });
                setError('');
                
            }
            else {
                setError('Folder Name already Exist');
                setShowError(true);
            }
        }
        else {
            setError('Enter a Valid Folder Name');
            setShowError(true);
        }       
    }

    const handleShowFileDelete = (e: any, File: any) => {
        deleteFileId = File.FileId;
        setShowConfirmFileDelete(!showConfirmFileDelete);
    }

    const handleShowFolderDelete = (e: any, Folder: any) => {
        deleteFolderId = Folder.FolderId;
        setShowConfirmFolderDelete(!showConfirmFolderDelete);
    }

    const RenameFile = (e: any, File: any) => {
        setError('');
        setShowError(false);
        setNewFileName(File.OriginalName);
        setSaveFile({ ...saveFile, FileId: File.FileId })
        let a = document.getElementById('renameFileBtn');
        a?.click();
    }
    const LabelFile =async (e: any, File: any) => {
        // setFileLabel(File.Tag);
        setError('');
        setShowError(false);
        setLabelName('');
       const resp:any = await GetEmployeeLabels(employeeId,File.FileId);
       if(resp.status === "success"){
        setEmpLabels(resp.result?.map((obj:any) =>{ return { value: obj.LabelId ,label: obj.LabelName }}))
       }
        setSaveFile({ ...saveFile, FileId: File.FileId })
        let a = document.getElementById('labelFileBtn');
        a?.click();
    }
    const RenameFolder = (e: any, Folder: any) => {
        setError('');
        setNewFolderName(Folder.FolderName);
        setCreateFolder({ ...createFolder, FolderId: Folder.FolderId })
        let a = document.getElementById('renameFolderBtn');
        a?.click();

    }

    const deleteFile = () => {
        props.DeleteFile(empId, deleteFileId);
        handleShowFileDelete(0, File);
    }

    const deleteFolder = () => {
        props.DeleteFolder(empId, deleteFolderId);
        handleShowFolderDelete(0, File);
        setselectedFolderId('0');
        setShowSharedTable(false);
    }

    const DownloadFile = (e: any, File: any) => {

        props.DownloadFile(File);
    }

    const moveToFolder = (e: any, File: any) => {
        setError('');
        setShowError(false);
        setFileToFolder({ ...fileToFolder, FileId: File.FileId, FolderId: 'select' })
        let a = document.getElementById('moveToFolder');
        a?.click();
    }

    const MoveFileToSelectedFolder = () => {
        if (fileToFolder.FolderId && fileToFolder.FileId) {
            props.MoveFileToSelectedFolder(fileToFolder);
        }
        else {
            //console.log('successful');
            setError('Please select a Folder');
            setShowError(true);
        }
    }

    const selectFolderToMove = (folder: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = folder.target.value;
        if (selectedValue === "select") {
            setFileToFolder({ ...fileToFolder, FolderId: selectedValue });

        } else {
            setFileToFolder({ ...fileToFolder, FolderId: selectedValue });
            setError(''); // Clear the error message
            setShowError(false);
        }
    };

    const handleClosePopup = (e: any) => {
        setShowConfirmFileDelete(!showConfirmFileDelete);
    }

    const handleCloseFolderPopup = (e: any) => {
        setShowConfirmFolderDelete(!showConfirmFolderDelete);
    }

    const CreateFolderInMoveToFolder = (e: any) => {
        setShowCreateFolder(false);////true
        setError('');
        setShowError(false);
    }

    const SyncGoogleDrive = (email: string) => {
        props.SyncGoogleDrive(email, empId);
    }

    const handleCloseCreateModal =(e: any) => {
        setError('');
        setShowError(false);
        setCreateFolder({ ...createFolder, FolderName: '' });
    }

    const handleFileNameExide = (e: any) => {    
        setShowPopup(false);
    }

    async function UploadFile(e: any) {
       // e.preventDefault();
        const filesToUpload = e.files;
        try {
            const formData = new FormData();
            formData.append('EmpGuid', empId);
            formData.append('FolderId', selectedFolderId);
            const duplicates: any[] = [];
            const NonDuplicate: any[] = [];
            for (const file of filesToUpload) {
                const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
                const fileExtension = file.name.split('.').pop().toLowerCase();
                let transformedName = '';
                if (fileNameWithoutExtension.length < 100) {
                     transformedName = fileNameWithoutExtension.split(' ').join('').toLowerCase();
                    try {
                        const resp: any = await IsFileDuplicate(empId, transformedName, fileExtension);
                        if (resp.status == "success") {
                            if (resp.result) {
                                duplicates.push(file);
                            } else {
                                NonDuplicate.push(file);
                                formData.append('files', file, file.name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
                            }
                        } else if (resp == "Error") {
    
                        }
                      } catch (err) {
                        console.error("Error during duplicate check:", err);
                    }
                } else {
                    // Display an error message                   
                    setShowPopup(true);
                 }                    
            }
            if (NonDuplicate.length > 0) {
                props.UploadNewFiles(formData);
            }
            if (duplicates.length > 0) {
                setDuplicateFiles([...duplicates]);
            }
        } catch (err) {
            console.error("Error during file upload:", err);
        }

    }
    function headerRender() {
        return (
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Modified</th>
                <th className="mobile-none">Folder</th>
                <th className="mobile-none">Location</th>
                <th className="mobile-none"></th>
            </tr>);
    }

    function sharedFilesHeaderRender() {
        return (
            <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Modified</th>
                <th className="mobile-none">Folder</th>
                <th className="mobile-none">Shared From</th>
                <th className="mobile-none"></th>
            </tr>);
    }

    function GetFilesByFolderId(obj: any) {
        if (obj.FolderName == 'Shared with me') {
            setShowSharedTable(true);
        }
        else {
            setShowSharedTable(false)
        }
        setselectedFolderId(obj.FolderName == 'Shared with me'? "0" : obj.FolderId);
        setFileToFolder({ ...fileToFolder, FolderId: obj.FolderId })
        props.GetFilesByEmpGuid(new FilesTableBody(), empId, obj.FolderId);
    }

    function onContactChecked(event: any, contact: any) {

        if (event.target.checked) {
            setContacts([...contacts, contact.EmpGuid]);
        }
        else {
            var list = contacts;
            var a = list.indexOf(contact.EmpGuid);
            list.splice(a, 1);
            setContacts([...list]);
        }
        console.log(contact);
    }

    // function ShareFiles() {
    //     let selectedContacts = contacts.toString();
    //     props.ShareFile(FileId, empId, selectedContacts);
    // }

    function ShareFiles() {
        setSaveInProgress(true);
        let selectedContacts = contacts.toString();
        let Externalmail = extMails.toString();
        if (selectedContacts.length === 0 && Externalmail.length === 0) {
            // If selectedContacts array is empty, return without calling ShareFile
            return;
        } else {
            // If selectedContacts array is not empty, call ShareFile with the arguments
            props.ShareFile(FileId, empId, selectedContacts, Externalmail);
            setContactsInSelect();
            setTimeout(() => {
                setSaveInProgress(false);
            }, 2000);
        }
    }

    const handleClose = () => {
        setFileToFolder({ ...fileToFolder, FolderId: "select" })
    }

    const handleCreateFolder = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setCreateFolder({ ...createFolder, FolderName: e.target.value, FolderId: '' });
        setError(''); // Clear the error message
        setShowError(false);
    };

    const ShareFile = (e: any, File: any) => {
        //clear existing selection

        var cbshareprefcontacts = document.getElementsByName('cbshareprefcontact');

        for (var i = 0; i < cbshareprefcontacts.length; i++) {
            var cbshareprefcontact = cbshareprefcontacts[i] as HTMLInputElement;
            cbshareprefcontact.checked = false;
        }
        FileId = File.FileId;

        dispatch({
            type: T.ADD_LOADING
        })

        GetFileSharedByFileID(empId, FileId).then((resp: any) => {
            if (resp.status == "success") {
                if (resp?.result.length > 0) {
                    const emails: string[] = [];
                    const employeeGuids: string[] = [];
                    const sltcont: any[] = [];
                    resp.result.forEach((obj: any) => {
                        if (obj.IsExternal) {
                            emails.push(obj.ExternalEmail);
                        } else {
                            employeeGuids.push(obj.EmpGuid);
                            if(obj.Name !== ' ') sltcont.push({ value: obj.EmpGuid, label: obj.Name });
                            
                        }
                    });
                    setContacts([...employeeGuids]);
                    setExtMails([...emails]);
                    setSltContacts([...sltcont]);
                    (emails?.length > 0) ? setExernalUser(true) : setExernalUser(false);
                 
                }
                else {
                    setExernalUser(false);
                    setContacts([]);
                    setExtMails([]);
                    setSltContacts([]);
                }
                const contacts = contactsOptions.filter((e: any) => !(e.label.includes(File.Location)));
                setContactsOptions(contacts);
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
            else if (resp == "Error") {
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
        });

        //setContacts([]);
      
        let a = document.getElementById('selectContacts');
        a?.click();
    }

    const GetSharedFilesByContactGuid = (e: any) => {
        props.GetSharedFileByContactGuid(empId, e);
    }

    function rowRender(row: any, index: number) {
        const ft = row.FileName.split('.').pop().toUpperCase();
        return (<tr key={index}>
            <td title={ft} style={{ width: "40px" }}><FileIcon name={ft} /></td>
            <td style={{maxWidth: "350px", minWidth: "auto"}}>{row.OriginalName}</td>
            <td>{Moment(row.CreatedDate).format(formattedDate)}</td>
            <td className="mobile-none">{row.FolderName}</td>
            <td className="mobile-none">{row.Location}</td>
            <td className="text-right dropdown mobile-none">
                <svg id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                </svg>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    {(row.Location || '') == 'sute' ? <a onClick={e => RenameFile(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Rename</a> : <></>}
                    {(row.Location || '') == 'sute' ? <a onClick={e => handleShowFileDelete(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> Delete</a> : <></>}

                    {/* <a onClick={e => RenameFile(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Rename</a>
                    <a onClick={e => handleShowFileDelete(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> Delete</a>*/}
                    {row.FolderName != "Shared with me" ? <a onClick={e => moveToFolder(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M5 8.5l2 2 3.5-4m-10-4v10a1 1 0 001 1h12a1 1 0 001-1v-8a1 1 0 00-1-1h-6l-2-2h-4a1 1 0 00-1 1z" stroke="currentColor"></path></svg> Move to folder</a> : ''}
                    <a target="_blank" href={`${api_domian}${api_port}/api/file/DownloadFile?FileGuid=${row.FileGuid}`} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 10.5l-3.25-3m3.25 3l3-3m-3 3V1m6 6v6.5h-12V7" stroke="currentColor"></path></svg> Download file</a>
                    { <a onClick={e => LabelFile(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Label</a> }
                    {(row.DriveFileId || '').length == 0 ? <a onClick={e => ShareFile(e, row)} className="dropdown-item small pl-3 c-pointer"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 7.5h4M11 4L8.5 7.495 11 11m3.5-8.501a2 2 0 01-4 0 2 2 0 014 0zm0 9.993a2 2 0 01-4 0 2 2 0 014 0zm-10-4.997a2 2 0 01-4 0 2 2 0 014 0z" stroke="currentColor" strokeLinecap="square"></path></svg> Share file</a> : <></>}
                </div>
            </td>
        </tr>);
        //  onClick={e => DownloadFile(e, row)}

    }

    function sharedFilesRowRender(row: any, index: number) {
        const ft = row.FileName.split('.').pop().toUpperCase();
        return (<tr key={index}>
            <td title={ft} style={{ width: "40px" }}><FileIcon name={ft} /></td>
            <td >{row.OriginalName}</td>
            <td>{row.CreatedDate}</td>
            <td>{row.FolderName}</td>
            <td>{row.Location}</td>
            <td className="text-right dropdown">
                <svg id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                </svg>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    {/* <a onClick={e => RenameFile(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Rename</a> */}
                    {/* <a onClick={e => handleShowFileDelete(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> Delete</a> */}
                    {row.FolderName != "Shared with me" ? <a onClick={e => moveToFolder(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M5 8.5l2 2 3.5-4m-10-4v10a1 1 0 001 1h12a1 1 0 001-1v-8a1 1 0 00-1-1h-6l-2-2h-4a1 1 0 00-1 1z" stroke="currentColor"></path></svg> Move to Folder</a> : ''}
                    <a target="_blank" href={`${api_domian}${api_port}/api/file/DownloadFile?FileGuid=${row.FileGuid}`} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 10.5l-3.25-3m3.25 3l3-3m-3 3V1m6 6v6.5h-12V7" stroke="currentColor"></path></svg> Download File</a>
                    { <a onClick={e => LabelFile(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Label</a> }
                    <a onClick={e => ShareFile(e, row)} className="dropdown-item small pl-3 c-pointer"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 7.5h4M11 4L8.5 7.495 11 11m3.5-8.501a2 2 0 01-4 0 2 2 0 014 0zm0 9.993a2 2 0 01-4 0 2 2 0 014 0zm-10-4.997a2 2 0 01-4 0 2 2 0 014 0z" stroke="currentColor" stroke-linecap="square"></path></svg> Share File</a>
                </div>
            </td>
        </tr>);
    }
    const closeFolderList = () => {
        setShowFolderList(!showFolderList);
    }

    const addExternalUser = (e: any) => {
        setSaveInProgress(true);
        e.preventDefault();

        const isPrefCon = props.user?.contacts?.find((contact: any) => contact.OfficeEmail.toLowerCase() === mail.toLowerCase());
        if (isPrefCon != undefined) {
            if (!contacts.includes(isPrefCon.EmpGuid)) {
                setContacts([...contacts, isPrefCon.EmpGuid]);
                setSltContacts([...sltContacts, { value: isPrefCon.EmpGuid, label: `${isPrefCon.FirstName} ${isPrefCon.LastName}` }])
            }
        }
        else if (!extMails.includes(mail)) {
            setExtMails([...extMails, mail]);
        }
        setMail('');
        console.log("extMails", extMails);
        setSaveInProgress(false);

    }

    const removeParticipant = (index: any) => {
        const list = [...extMails];
        list.splice(index, 1);
        setExtMails(list);
    }

    const _handleChangeContact = (option: any) => {
        setSltContacts(option);
        setContacts(option.map((opt: any) => { return opt.value }))
    }
    const handleChangeLabel = (option:any)=>{
        setEmpLabels(option);
    }
    const cancelFileUpload = ()=>{
       
        if(duplicateFiles.length > 0){ 
            const newArray = [...duplicateFiles];
            newArray.shift();
            setDuplicateFiles([...newArray]);
        }
        setDuplicatedOption("");
    }

    const handleDuplicateFiles = ()=>{
        if(duplicateFiles.length > 0){
            const formData = new FormData();
            formData.append('EmpGuid', empId);
            formData.append('FolderId', selectedFolderId);
            formData.append('DuplicateFile',duplicatedOption);
            formData.append('files', duplicateFiles[0], duplicateFiles[0].name.toLowerCase().replace(/[^\w\d_\-\.]+/ig, ''));
            props.UploadNewFiles(formData);
            const newArray = [...duplicateFiles];
            newArray.shift();
            setDuplicateFiles([...newArray]);
        }
        setDuplicatedOption("");
    }

    const Addlabel = async (e: any) => {
        e.preventDefault();
        if (LabelName.length > 0) {
            if (!EmpLabelsoptions.some((ele: any) => ele.label.toLowerCase() === LabelName.toLowerCase())) {
                setSaveInProgress(true);
                const resp: any = await SaveLabel(LabelName, false, orgId, employeeId, false);
                if (resp.result) {
                    const result = removeDuplicateLabels(resp.result);
                    const empLabelopt = result?.map((obj: any) => {
                        return { value: obj.LabelId, label: obj.LabelName };
                    });
                    
                    // Proper filtering of differing elements
                    const differingElements = empLabelopt.filter((item2: any) => 
                        !EmpLabelsoptions.some((item1: any) => 
                            item1.label.toLowerCase() === item2.label.toLowerCase()
                        )
                    );
                    
                    console.log("differingElements", differingElements);
                    if (differingElements.length > 0) {
                        setEmpLabels([...EmpLabels, differingElements[0]]);
                    }
                    setEmpLabelsOptions(empLabelopt);
                }
                setLabelName('');
                setSaveInProgress(false);
                // setShowError(false);
            } else {
                setError('Label already exists');
                setShowError(true);
            }
        }
    }
    

    const resetErrorMessage = (e: any) => {
        setError('');
        setShowError(false);
        setCreateFolder({...createFolder,FolderName:''})
console.log(showError, ">>>>");

    }

    const hanbelLabelName = (e : any) =>{        
        setLabelName(e.target.value)
         setShowError(false);
     }

     useEffect(() => {
        if (props.user.userFiles  && props.user.userFiles .length > 0) {
            const newFiles = props.user.userFiles.map((challenge: any, i: number) => ({
                ...challenge,
                TotalCount: props.user.userFiles.length,
                RowNumber: i + 1
            }));

            setFileSearch(newFiles?.filter((newChallenge: any) =>
                newChallenge.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
                && newChallenge.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
            );

        } else
        setFileSearch([]);
    }, [props.user.userFiles]);

    useEffect(() => {
        if (props.user.userFiles && props.user.userFiles.length > 0) {
            const searchQuery = filesTableParams.Search.toLowerCase().trim();
    
            const newFileList = props.user.userFiles.filter((file: any) =>
                (file.FileName?.toLowerCase().includes(searchQuery) ?? false) ||
                (file.FolderName?.toLowerCase().includes(searchQuery) ?? false) ||
                (file.Location?.toLowerCase().includes(searchQuery) ?? false)
            );
    
            newFileList.forEach((newChallenge: any, i: any) => {
                newChallenge.TotalCount = newFileList.length;
                newChallenge.RowNumber = i + 1;
            });
    
            setFileSearch(
                newFileList.filter((newChallenge: any) =>
                    newChallenge.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize) &&
                    newChallenge.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize)
                )
            );
        }
        console.log(fileSerch);
    }, [filesTableParams]);

    return (<>
        <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
            <button onClick={(e: any) => resetErrorMessage} type="button" id="renameFileBtn" data-target="#RenameFile" data-toggle="modal" style={{ opacity: 0 , pointerEvents: "none"}}></button>
            <button onClick={(e: any) => resetErrorMessage} type="button" id="renameFolderBtn" data-target="#RenameFolder" data-toggle="modal" style={{ opacity: 0 , pointerEvents: "none"}}></button>
            <button type="button" id="moveToFolder" data-target="#moveFileToFolder" data-toggle="modal" style={{ opacity: 0, pointerEvents: "none" }}></button>
            <button type="button" id="selectContacts" data-target="#shareFile" data-toggle="modal" style={{ opacity: 0 , pointerEvents: "none"}}></button>
            <button type="button" id="labelFileBtn" data-target="#LabelFile" data-toggle="modal" style={{ opacity: 0 , pointerEvents: "none"}}></button>
            <button type="button" id="FileUploadBtn" data-target="#FileUpload" data-toggle="modal" style={{ opacity: 0, pointerEvents: "none" }}></button>
            <div className="open-sidemenu" onClick={closeFolderList}>
                <img src="/assets/media/images/folder.png" />
            </div>
            <div className={showFolderList ? "SideNav text-left d-block position-fixed" : "SideNav text-left position-fixed d-none"}>
                <div className="sideNavHeader">
                    <div className="CloseNav"><img title="Close" src="assets/media/svg/x-close.svg" onClick={closeFolderList}/> </div>
                </div>
                <div className="sideNavBody">
                    <div className="row mt-4">
                        <div className="col-12 mb-2 text-white" onClick={resetErrorMessage}>
                            <h6 className="m-0" title="Create Folder">{t('files.folders')} <svg data-toggle="modal" data-target="#newFolder" className="float-right c-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="#fff"></path></svg></h6> </div>
                        <div className="col-12 text-left overflow-auto mh-100px" style={{ maxHeight: 'calc(100vh - 330px)' }}>
                            <p onClick={(e) => GetFilesByFolderId({ FolderId: 0 })} className="folderList mb-2">{selectedFolderId != '0' ? <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M1.5 1A1.5 1.5 0 000 2.5v10A1.5 1.5 0 001.5 14h12a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0013.5 3H7.707l-2-2H1.5z" fill="#6264a5"></path></svg> : <img src="assets/media/images/open-folder.svg" width="16" />} <span className={clsx('pl-1', selectedFolderId === '0' ? 'folder-active' : '')}>{t('button.all')}</span></p>
                            {(props.user.folderList != undefined && props.user.folderList.result != undefined) ? (props.user.folderList.result).map((obj: any, index: number) => {
                                return (
                                    <p key={index} onClick={(e) => GetFilesByFolderId(obj)} className="folderList mb-2">
                                        {selectedFolderId != obj.FolderId ? <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M1.5 1A1.5 1.5 0 000 2.5v10A1.5 1.5 0 001.5 14h12a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0013.5 3H7.707l-2-2H1.5z" fill="#6264a5"></path></svg>
                                            : <img src="assets/media/images/open-folder.svg" width="16" />}    <span className={clsx('pl-1', selectedFolderId === obj.FolderId ? 'folder-active' : '')}>{obj.FolderName}</span>
                                       {obj.FolderName == "Shared with me" ? null : <span className="text-right float-right dropdown">
                                            <svg id="dropdownFloder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                                            </svg>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownFloder">
                                                <a onClick={e => RenameFolder(e, obj)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> {t('button.rename')}</a>
                                                <a onClick={(e) => handleShowFolderDelete(e, obj)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> {t('button.delete')}</a>
                                            </div>
                                        </span>}
                                        </p>)
                            }) :
                                (<p className="col-12 text-white mb-0"></p>)}
                        </div>
                        <div className="col-12 p-0" style={{ display: 'none' }}>
                            <hr className="border-secondary" /> </div>
                    </div>
                    <div className="row small" style={{ display: 'none' }}>
                        <div className="col-12 text-left text-light">
                            <p>Cloud Storage</p>
                            {/* <p className="text-muted mb-2"> <img src="https://p.sfx.ms/images/favicon.ico" width="15px" /> OneDrive <svg className="float-right c-pointer" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M.5 7.5A7 7 0 0113 3.17m1.5 4.33A7 7 0 012 11.83m3-.33H1.5V15m12-15v3.5H10" stroke="#fff"></path></svg></p> */}
                            {(props.employee.events || []).length > 0 ? (props.employee.events || []).map((obj: any, index: number) => {
                                if (obj.IsShow) {
                                    return (<p key={index} className="text-muted"> <img src="/assets/media/svg/checkin/google-drive.svg" width="15px" /> {obj.Email} <svg onClick={(e: any) => SyncGoogleDrive(obj.Email)} className="float-right c-pointer" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M.5 7.5A7 7 0 0113 3.17m1.5 4.33A7 7 0 012 11.83m3-.33H1.5V15m12-15v3.5H10" stroke="#fff"></path></svg></p>
                                    )
                                }
                            }) : undefined}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade small" id="newFolder" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0 text-white">{t('files.create')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" title="Close" onClick={handleCloseCreateModal}>
                                <span className="text-white" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <input type="text" className="SuteInputPrimary fs12 h-100 mb-2 pt-2" value={createFolder.FolderName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            e.preventDefault();
                                            e.target.value = e.target.value.trimStart();``
                                            setCreateFolder({ ...createFolder, FolderName: e.target.value, FolderId: '' });
                                            setError(''); // Clear the error message
                                            setShowError(false);
                                        }} placeholder={t('files.enter_folder_name')} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-SeaGreen" id="createfldrCloseBtn" data-dismiss="modal" onClick={handleCloseCreateModal}>{t('button.cancel')}</button>
                            <button className="btn btn-SeaGreen" disabled={isSaveInProgress} onClick={SaveFolder}>{t('button.save')}</button>
                        </div>
                        {showError && <p className="col-12" style={{color:'red'}}>{error}</p>}
                    </div>
                </div>
            </div>

            <div className="modal fade small" id="RenameFile" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0 text-white">{t('files.rename_file')}</h5>
                            <button type="button" className="close" title="Close" data-dismiss="modal" aria-label="Close">
                                <span className="text-white" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <input type="text" className="SuteInputPrimary fs12 h-100 mb-2 pt-2" value={newFileName}
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setNewFileName(e.target.value); setError(''); setShowError(false);
                                        }} placeholder={t('files.enter_file_name')} maxLength={100}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-SeaGreen" id="renameFileCloseBtn" data-dismiss="modal">{t('button.cancel')}</button>
                            <button className="btn btn-SeaGreen" onClick={RenameFileName}>{t('button.save')}</button>
                        </div>
                        {showError ? <p className="col-12 text-white">{error}</p> : <></>}
                    </div>
                </div>
            </div>
            <div className="modal fade small" id="LabelFile" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0 text-white">Label</h5>
                            <button type="button" className="close" title="Close" data-dismiss="modal" aria-label="Close">
                                <span className="text-white" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                   {/* { <input type="text" className="SuteInputPrimary fs12 h-100 mb-2 pt-2" value={FileLabel}
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setFileLabel(e.target.value); setError(''); setShowError(false);
                                        }} placeholder="Enter File Label" />} */}
                                    
                                    <Select 
                                     isMulti options={EmpLabelsoptions}
                                             onChange={handleChangeLabel}
                                             value={EmpLabels}
                                             isClearable={true}
                                    ></Select>
                                    
                                </div>
                                
                                <div className="col-12 mt-3">
                                {/* <div className="custom-control custom-checkbox mt-2 p-0">

                                </div> */}
                                    {
                                   
                                    <form onSubmit={Addlabel}>
                                        <div className="col-12 row m-0 p-0">
                                            <input type="text" className="form-control mt-2" placeholder="Enter New Label" value={LabelName} onChange={hanbelLabelName} required />
                                            <button type="submit" className="btn btn-primary add-btn" disabled={isSaveInProgress}>Add</button>
                                        </div>
                                    </form>
                                }
                                    
                              
       
                            </div>
                               
                            </div>
                        </div>
                        <div className="modal-footer">
                        {showError && <p className="col-12 text-white">{error}</p>}

                            <button className="btn btn-SeaGreen " id="labelFileCloseBtn" data-dismiss="modal">{t('button.cancel')}</button>
                            <button className="btn btn-SeaGreen" onClick={LabelEmpFile}>{t('button.save')}</button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade small" id="RenameFolder">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0 text-white">{t('files.rename_folder')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="text-white" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <input type="text" className="SuteInputPrimary fs12 h-100 mb-2 pt-2" value={newFolderName}
                                        onChange={(e: any) => {
                                            e.preventDefault();
                                            setNewFolderName(e.target.value); setError(''); setShowError(false);
                                        }} placeholder={t('files.enter_folder_name')} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-SeaGreen" id="renameFolderCloseBtn" onClick={resetErrorMessage} data-dismiss="modal">{t('button.cancel')}</button>
                            <button className="btn btn-SeaGreen" onClick={RenameFolderName}>{t('button.save')}</button>
                        </div>
                        {showError ? <p className="col-12 text-white">{error}</p> : <></>}
                    </div>
                </div>
            </div>
             <div className="modal fade small" id="FileUpload" data-backdrop="static" data-keyboard="false" tabIndex={-1} role="dialog">
                <div className="modal-dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0 text-white">Duplicate File Upload</h5>
                            <button type="button" data-dismiss="modal" className="close" onClick={cancelFileUpload}>
                                <span className="text-white" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 color-white">
                                    {duplicateFiles[0]?.name} already exist in the location.
                                    Do you want to replace the existing file with a new version or keep both files?
                                </div>
                                <div className="col-12 mt-3">
                                <input
                                    type="radio"
                                    value="Replace Existing File"
                                    className="radio-custom"
                                    checked={duplicatedOption === "Replace Existing File"}
                                    onChange={(e) => { setDuplicatedOption(e.target.value) }}
                                />
                                <label className="color-white bg-none border-none">
                                    Replace Existing File
                                </label>
                                <br />
                                </div>
                                <div className="col-12">
                                <input
                                    type="radio"
                                    value="Keep Both File"
                                    className="radio-custom"
                                    checked={duplicatedOption === "Keep Both File"}
                                    onChange={(e) => { setDuplicatedOption(e.target.value) }}
                                />
                                <label className="color-white bg-none border-none">
                                    Keep Both File
                                </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button id="FileUploadCloseBtn" data-dismiss="modal" style={{ opacity: 0 }}></button>
                            <button className="btn btn-SeaGreen"  data-dismiss="modal" onClick={cancelFileUpload}>{t('button.cancel')}</button>
                            <button className="btn btn-SeaGreen" onClick={handleDuplicateFiles} disabled={(duplicatedOption.length > 0)?false:true}>Upload</button>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="modal fade small"
                id="moveFileToFolder"
                data-backdrop="static" data-keyboard="false"
            >
                <div className="modal-dialog" data-backdrop="static" data-keyboard="false">
                    {!showCreateFolder ? <>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0 text-white w-100">{t('button.move_folder')} <button onClick={CreateFolderInMoveToFolder} data-toggle="modal" data-target="#newFolder" data-dismiss="modal" className="btn btn-SeaGreen float-right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="currentColor"></path></svg> {t('button.new_folder')}</button></h5>
                                <button type="button" onClick={handleClose} className="close" data-dismiss="modal" aria-label="Close" title="Close">
                                    <span className="text-white" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div align-center={true.toString()} className="col-12">

                                        <select onChange={selectFolderToMove} value={fileToFolder.FolderId} className="SuteInputPrimary h40px w-100">
                                            <option value="select">Select</option>
                                            {props.user.folderList != undefined && props.user.folderList.result != undefined ?
                                                props.user.folderList.result.map((obj: any, index: number) => {
                                                    return (<option key={index} value={obj.FolderId}>{obj.FolderName}</option>)
                                                })
                                                : undefined
                                            }
                                        </select>
                                        {/*props.user.folderList != undefined && props.user.folderList.result != undefined ? props.user.folderList.result.map((obj: any, index: number) => {
                                            return (<>
                                                <p key={index} onClick={(e) => GetFilesByFolderId(obj)} className="folderList mb-2">
                                                    <input type="radio" value={obj.FolderId}
                                                        checked={selectedFolderId === obj.FolderId}
                                                        onChange={(e: any) => {
                                                            selectFolderToMove(obj);
                                                            setError(''); setShowError(false);
                                                        }} className="mr-2 align-text-top" />
                                                    {selectedFolderId != obj.FolderId ?
                                                        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M1.5 1A1.5 1.5 0 000 2.5v10A1.5 1.5 0 001.5 14h12a1.5 1.5 0 001.5-1.5v-8A1.5 1.5 0 0013.5 3H7.707l-2-2H1.5z" fill="#6264a5"></path></svg>
                                                        : <img src="assets/media/images/open-folder.svg" width="16" />}    <span className={clsx('pl-1 text-white', selectedFolderId === obj.FolderId ? 'folder-active' : '')}>{obj.FolderName}</span>  </p></>)
                                        })
                                            :
                                    (<p className="col-12 text-white mb-0">{t('files.no_custom_files')}</p>)*/}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" >
                                <button className="btn btn-SeaGreen" id="moveFileCloseBtn" data-dismiss="modal" onClick={handleClose}>{t('button.cancel')}</button>
                                <button className="btn btn-SeaGreen" onClick={MoveFileToSelectedFolder} disabled={(fileToFolder.FolderId == "select") ? true : false}>{t('button.move')}</button>
                            </div>
                            {showError ? <p className="col-12 text-white">{error}</p> : <></>}
                        </div>
                    </> :
                        <>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0 text-white">{t('files.create')}</h5>
                                    <button type="button" className="close" onClick={(e) => [setShowCreateFolder(false), setError(''), setShowError(false), setCreateFolder({ ...createFolder, FolderId: '', FolderName: '' })]} aria-label="Close">
                                        <span className="text-white" aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <input type="text" className="SuteInputPrimary fs12 h-100 mb-2 pt-2" value={createFolder.FolderName}
                                                onChange={handleCreateFolder}
                                                placeholder={t('files.enter_folder_name')} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-SeaGreen" onClick={(e) => [setShowCreateFolder(false), setError(''), setCreateFolder({ ...createFolder, FolderId: '', FolderName: '' })]}>{t('button.cancel')}</button>
                                    <button className="btn btn-SeaGreen" onClick={SaveFolder}>{t('button.save')}</button>
                                </div>
                                {showError ? <p className="col-12 text-white">{error}</p> : <></>}
                            </div>
                        </>}
                </div>
            </div>

            <div className="container-fluid">
                <div className="row mt-4">
                    {!showSharedTable ? <Table
                        id='files'
                        DND='true'
                        data={fileSerch || []}
                        contactsList={props.user.SharedToContactList || []}
                        headerRender={headerRender}
                        rowRender={rowRender}
                        onUpload={(e: any) => UploadFile(e)}
                        getSharedByContactGuid={(e: any) => GetSharedFilesByContactGuid(e)}
                        onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
                        onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
                        header="Files"
                        //subHeader="Recent"
                        noDataMsg="no files found"
                        selectedFolderId = {selectedFolderId}
                        rootUrl= {loc}
                        {...props}>
                    </Table> :
                        <Table
                            id='shared_files'
                            data={props.user.userFiles || []}
                            contactsList={props.user.SharedToContactList || []}
                            headerRender={sharedFilesHeaderRender}
                            rowRender={sharedFilesRowRender}
                            onUpload={(e: any) => UploadFile(e)}
                            clearContactSearch={clearContactFileSearch}
                            sharedContact={sharedContact}
                            onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
                            onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
                            header="Files"
                           // subHeader="Recent"
                            noDataMsg="no files found"
                            selectedFolderId = {selectedFolderId}
                            {...props}>
                        </Table>}
                </div>
            </div>
            <ConfirmPopup show={showConfirmFileDelete} close={handleClosePopup} okHandle={deleteFile} message="Do you want to delete this file?" />
            <ConfirmPopup show={showConfirmFolderDelete} close={handleCloseFolderPopup} okHandle={deleteFolder} message="Do you want to delete this folder? <br> <br> NOTE: Deleting a folder deletes all the contents of that folder." />
            <div className="modal fade" id="shareFile" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title text-light" id="shareFile">Share file</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={() => { setExtMails([]); setMail(''); setExernalUser(false) }}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" >
                            {/* <input type="text" className="SuteInputPrimary fs12 w-100" placeholder="Search contacts" /> */}
                            <div className="col-12 p-0">
                                {/* <table className="table border-secondary small filesTable mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '30px' }}>
                                                 <div className="custom-control custom-checkbox mt-2">
                                                     <input type="checkbox" className="custom-control-input" id="customCheck" name="example1" />
                                                     <label className="custom-control-label"></label>
                                                 </div> 
                                            </th>
                                             <th>Select Contact</th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                         {props.user.prefcontacts && props.user.prefcontacts.map((event: any) => {
                                            if (event.EmpGuid != empId) {
                                                console.log("props.user.prefcontacts",props.user.prefcontacts);
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="custom-control custom-checkbox mt-2">
                                                                <input type="checkbox" className="custom-control-input" onChange={e => onContactChecked(e, event)} name="cbshareprefcontact" checked={contacts.includes(event.EmpGuid)}/>
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </td>
                                                        <td>{event?.FirstName + ' ' + event?.LastName}</td>
                                                    </tr>);
                                            }
                                        })} 
                                        <Select isMulti options={contactsOptions}
                                            onChange={_handleChangeContact}
                                            value={sltContacts}
                                            isClearable={true}
                                        ></Select>
                                    </tbody>

                                </table> */}
                                <Select isMulti options={contactsOptions}
                                            onChange={_handleChangeContact}
                                            value={sltContacts}
                                            isClearable={true}
                                        ></Select>

                            </div>
                            <div className="col-12 external-main mt-3 p-0">
                                <div className="custom-control custom-checkbox mt-2 p-0">
                                    {/* <input type="checkbox" className="custom-control-input" onClick={()=>{setExernalUser(!externalUser)}} checked={externalUser}  /> */}
                                    <div className="col-12 row m-0 p-0">
                                        <div className="col-2 p-0">
                                            <div className="progressbar-div m-0 w-100"><label className="switch">
                                                <input type="checkbox"  onClick={() => { setExernalUser((extMails.length > 0) ? true : !externalUser) }} checked={(extMails.length > 0) ? true : externalUser} /><span className="slider round"></span></label></div>
                                        </div>
                                        <div className="col-9 p-0">
                                            <label className="custom-control-label external-user p-0">External User</label>
                                        </div>
                                    </div>

                                </div>
                                {(externalUser) ?
                                    <form onSubmit={addExternalUser}>
                                        <div className="col-12 row m-0 p-0">
                                            <input type="email" className="form-control mt-2" placeholder="Enter Email" onChange={(e) => { setMail(e.target.value) }} value={mail} required />
                                            <button type="submit" className="btn btn-primary add-btn" disabled={isSaveInProgress}>Add</button>
                                        </div>
                                    </form>
                                    : <></>}
                                {extMails.map((email: any, index: any) => (
                                    <div className="teamEmailHolder" key={index}>
                                        <span style={{ color: 'white' }}>{email}</span>
                                        {
                                            <img
                                                className="c-pointer"
                                                onClick={() => removeParticipant(index)}
                                                src="assets/media/svg/onboarding/x-close.svg"
                                                alt="Remove"
                                            />
                                        }
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-SeaGreen" id="closeContactspopup" data-dismiss="modal" onClick={() => { setExtMails([]); setMail(''); setExernalUser(false); setContactsInSelect()}}>Cancel</button>
                            <button type="button" onClick={ShareFiles} className="btn btn-SeaGreen" disabled={isSaveInProgress || !(contacts?.length > 0 || extMails?.length > 0)}>Share</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            {showPopup && (
                 <Modal backdrop="static" onHide={() => setShowPopup(false)} show={showPopup}>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', backgroundColor: 'black' }}>
                        <h5 style={{ color: "white", marginBottom: '20px' }}>File name must be below 100 characters.</h5>
                        <button type="button" style={{ color: "black", backgroundColor: "#30D5C8", border: 'none', padding: '10px 20px', borderRadius: '5px' }} onClick={() => setShowPopup(false)}>
                            OK
                        </button>
                    </Modal.Body>
             </Modal>
            )}

           </div>
        </section>
    </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Files);