const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION as string;
const build_version = BUILD_VERSION ? BUILD_VERSION : '0.0.1';
const en = {
  'toast': {
    'room_you_joined': 'Joined room successfully',
    'room_you_left': 'You left the room',
    'room_locked_msg': 'Room locked successfully',
    'room_unlocked_msg': 'Room unlocked successfully',
  },
  'button': {
    'member_login': 'Member login',
    'start': 'Start',
    'event': 'Event',
    'task': 'Task',
    'reminder': 'Reminder',
    'skip': 'Skip',
    'save': 'Save',
    'close': 'Close',
    'edit': 'Edit',
    'rename': 'Rename',
    'delete': 'Delete',
    'cancel': 'Cancel',
    'move': 'Move',
    'public': 'Public',
    'private': 'Private',
    'send_invite': 'Send invite',
    'login': 'Login',
    'submit': 'Submit',
    'signup': 'Sign up',
    'create': 'Create',
    'add': 'Add',
    'join_now': 'Join now',
    'yes': 'Yes',
    'no': 'No',
    'all': 'All',
    'new_folder': 'New folder',
    'move_folder': 'Move to folder',
    'settings': 'Settings',
    'rooms': 'Rooms',
    'contacts': 'Contacts',
    'update_room': 'Update room',
    'apps': 'Apps',
    'inbox': 'Inbox',
    'checkins': 'Check-Ins',
    'checkin': 'Check-In',
    'checkinhistory': 'Check-In history',
    'checkinhistoryteam': 'Team check-ins',
    'sute_link': 'Sute link',
    'all_files': 'See all files',
    'launch_meeting': 'Launch meeting',
    'create_meeting': 'Create meeting',
    'copy_invite_link': 'Copy invite link',
    'next': 'Next',
    'join_room': 'Join room',
    'org_files': 'Files',
  },
  'title': {
    'description': 'Description',
    'conference': 'Conference room',
    'conference_room': 'Conference room',
    'toggle_audio_mute_unmute': 'Toggle audio mute/unmute',
    'toggle_video': 'Toggle video',
    'toggle_chat_window': 'Toggle chat window',
    'toggle_participants': 'Toggle participants',
    'screenshare': 'Screenshare',
    'whiteboard': 'Whiteboard',
    'toggle_participants_side_bar': 'Toggle participants side bar',
    'toggle_full_screen': 'Toggle full screen',
    'search': 'Search',
    'mute': 'Mute',
    'available': 'Available',
    'unmute': 'Unmute',
    'close': 'Close',
    'files': 'Files',
    'file': 'File',
    'tasks': 'Tasks',
    'admit': 'Admit',
    'end_call': 'End Call',
    'video_off': 'Video Off',
    'video_on': 'Video On',
    'audio_call': 'Audio call',
    'video_call': 'Video call',
    'remove_contact': 'Remove contact',
    'profile': 'Profile',
    'accept_audio_call': 'Accept audio call',
    'accept_video_call': 'Accept video call',
    'reject_call': 'Reject call',
    'decline_reject': 'Decline / Reject',
    'share_sute_link': 'Share sute link',
    'waiting_admit': 'Waiting to admit',
  },

  'calendar': {
    'start_date': 'Start date',
    'end_date': 'End date',
    'calendar': 'Calendar',
    'my_calendars': 'My calendars',
    'other_calendars': 'Other calendars',
    'google_calendar': 'Google calendar',
    'microsoft_calendar': 'Microsoft calendar',
    'schedule': 'Schedule',
    'scheduling': 'Calendar',
    'schd_appointment': 'Schedule appointment',
    'all_calendars': 'All calendars',
    'create_new_event': 'Create new event',
    'edit_event': 'Edit event',
    'available_slots': 'Select time',
    'calender_integration': 'Calender integration',
    'select': 'Select',
    'event_name': 'Event name',
    'event_description': 'Event description',
    'event_details': 'Enter event details',
    'add_sute_meeting_link': 'Add link to room',
    'start_meeting': 'Click here to start meeting',
    'copy_url': 'or Copy the below URL to browser',
    'all_day': 'All day',
    'participants': 'Participants',
    'end_time': 'End time',
    'start_time': 'Start time',
    'auto_status': 'Automatically set your status based on your calender',
    'attachments': 'Attachments',
    'meeting_link': 'Meeting link',
  },
  'invitetomeeting': {
    'meeting': 'Meeting',
    'invite_others': 'Invite others',
    'share_meeting_link': 'Share your meeting link',
    'copy_link': 'Copy this link and send it to people you want to meet with.',
    'share_msg_link': 'Share your new meeting',
    'share_msg': 'Copy this link and send it to people you want to meet with. Be sure you save it so you can use it later.',
  },
  'minichatwindow': {
    'messages': 'Messages',
  },
  'sidebarright': {
    'invite_others': 'Invite others',
  },
  'changepassword': {
    'change_password': 'Change password',
    'old_password': 'Old password',
    'new_password': 'New password',
    'confirm_password': 'Confirm password',
  },
  'addcontact': {
    'add_contact': 'Add contact',
    'email_address': 'Email address',
    'enter_email_address': 'Email address',
  },
  'createroom': {
    'create_room': 'Create room',
    'room_name': 'Room name',
    'background_color': 'Background color',
  },
  'myprofile': {
    'total_sessions': 'Total sessions',
    'avg_sessions_week': 'Average sessions per week',
    'avg_session_length': 'Average length per session',
    'sentiment_tracker': 'Sentiment tracker',
    'engagement_tracker': 'Engagement tracker',
    'prod_vs_nonprod': 'Productive Vs Non productive',
    'about': 'About',
    'email': 'Email',
    'mobile': 'Mobile',
    'dob': 'Date of birth',
    'good': 'Good',
    'profile_image': 'Profile image',
  },
  'pod': {
    'total_online': 'Total online',
    'your_team_online': 'Your team online',
    'session_length': 'Session length',
    'avg_session_length': 'Avg. session length',
    'notifications': 'Notifications',
    'produce': 'My desk',
    'admin_dashboard':' Admin dashboard',
    'help_resources':'Help & Resources',
    'hr_admin_employees':'HR admin employees',
    'companies':'Companies',
    'logout':'Exit',
    'my_files':'Files',
    'my_meetings':'My calendar',
    'my_check-ins':'Check-ins',
    'conference_room':'Conference room',
    'private_rooms':'Private Rooms',
    'office':'My desk',
    'collaboration_hub':'Collaboration hub',
    'wellness':'Wellness center',
    'lobby':'Office lobby',
    'hangout_room':'Hangout room',
    'public_rooms':'Public rooms',
    'online':'Online'
    
  },
  'forgetpassword': {
    'forget_password': 'Forgot password',
    'reset_msg': "Please provide your email, we'll send you the reset link",
    'email': 'Email',
    'dont_have_an_account': 'Dont have an account?',
    'sign_up': 'Sign up'
  },
  'login': {
    'sign_in': 'SIGN IN',
    'welcome_msg': 'Welcome back',
    'email': 'Email',
    'password': 'Password',
    'forget_password': 'Forgot password ?',
    'keep_logged_in': 'Remember login',
    'or_login_with': 'Or login with',
  },
  'resetpassword': {
    'reset_password': 'Reset password',
    'welcome_reset': 'Welcome back! Please reset your password to get started',
    'new_password': 'New password',
    'confirm_password': 'Confirm password',
  },
  'signup': {
    'registration_form': 'Registration form',
    'createuser_form': 'Create user',
    'first_name': 'First name',
    'last_name': 'Last name',
    'phone_number': 'Phone number',
    'company_name': 'Company name',
    'org_name': 'Organisation name',
    'company_profile': 'Company profile',
    'employee_profile': 'Employee profile',
    'register': 'Register',
    'submit': 'Submit',
    'save':'Save',
    'onboard': 'Onboard employee',
    'resetPassword': 'Reset password',
    'or_register_with': 'Or register with',
    'had_account': 'Already have an account?',
    'reporting_to': 'Reporting to',
  },
  'signupsuccess': {
    'signup_success': 'Signup successfully',
    'acc_success_sute': 'Account created successfully, click login to SUTE',
  },
  'teamsignup': {
    'team_member_signup': 'Team member signup',
    'designation': 'Function',
  },
  'activity': {
    'activity': 'Activity',
  },
  'addgroupform': {
    'create_group': 'Create group',
    'select_type': '- Select type -',
  },
  'addmemberpopup': {
    'add_members_to_room': 'Add members to room',
  },
  'chattranscrit': {
    'delete': 'Delete',
    'reply': 'Reply',
    'forward': 'Forward',
    'no_messages': 'No messages',
    'meeting_is_started': 'Meeting is started',
  },
  'contacts': {
    'contacts': 'Contacts',
  },
  'chat': {
    'placeholder': 'Enter message',
    'chat': 'Chat',
    'direct_call': 'Join meeting',
    'video_call': 'Video call',
  },
  'publicheader': {
    'about': 'About',
    'membership': 'Membership',
    'feature': 'Feature',
    'downloads': 'Downloads',
    'windows_app': 'Windows app',
    'mac_desktop': 'Mac desktop',
    'linux': 'Linux(Ubuntu) installer',
  },
  'files': {
    'folders': 'Folders list',
    'create': 'Create folder',
    'rename_file': 'Rename file',
    'rename_folder': 'Rename folder',
    'enter_folder_name': 'Enter folder name',
    'enter_file_name': 'Enter file name',
    'no_custom_files': 'No custom folder is created',
  },
  'groupforminfo': {
    'room_info': 'Room info',
    'members': 'Members',
    'room_edit': 'Edit room',
    'create_team _meet': 'Create team meet',
    'delete_room': 'Delete room',
    'exit_room': 'Exit room',
    'room_events': 'Room events',
    'delete_message': 'Do you want to delete this room ?',
    'exit_message': 'Do you want to exit from the room ?',
  },
  'groups': {
    'collaborate': 'Collaborate',
    'private_rooms': 'Private rooms',
    'create_new_room': 'Create new room',
  },
  'inbox': {
    'inbox': 'Inbox',
  },
  'hangoutmenu': {
    'sute_selections': 'SUTE selections',
    'wellness_partners': 'Wellness partners',
    'hangout_room': 'Hangout room',
  },
  'rightmenu': {
    'add_team_member': 'Add team member',
    'audio_video': 'Audio & Video',
  },
  'earnlearn': {
    'earn_learn': 'Lobby',
    'managebulletin': 'Manage bulletin board',
    'bulletin1': 'Company',
    'bulletin2': 'updates',
    // 'bulletin3': 'ments',
    'directory': 'Directory',
    'earn': 'Earn',
    'learn': 'Learn',
    'exit_room': 'Exit from the room',
    'leave_room': 'Leave room',
  },
  'hangoutroom': {
    'chill': 'Chill',
    'socialize': 'Socialize',
    'play': 'Play',
    'health': 'Health $ Wellness',
    'community': 'Community $ Culture',
    },
  'launchmeeting': {
    'verified_user': 'Verified user',
    'meeting_id': 'Meeting id',
    'change_account': 'Change account',
    'invite_via': 'Invite via',
  },
  'roomedit': {
    'edit_group': 'Edit group',
    'wait_admit': 'Standby!',
  },
  'index': {
    'head_line': 'Pioneering a new way of working',
    'take_look': 'Take a look around',
    'visit': 'Ready to visit in person?',
    'visit_line': 'Book a tour with our friendly team.',
    'book_tour': 'BOOK A TOUR',
  },
  'setupcomponents': {
    'company_setup': 'Company setup',
    'add_logo': 'Add logo',
    'remove_img': 'Remove image',
    'name': 'Name',
    'tagline': 'Tagline',
    'brand_logo': 'Brand logo',
    'avatar_setup': 'Avatar setup',
    'profile_image': 'Profile image'
  },
  'settingscomponent': {
    'a_v_settings': 'Audio / Video settings',
    'no_prmsn_video': 'No permission for video input',
    'a_input': 'Audio input',
    'v_input': 'Video input',
    'mic_built': 'Internal microphone (built-In)',
    'msg_a': 'Please configure your audio output through your system preferences.',
    'msg_av': 'Once your grant us permission for audio and video, your device will show up.',
    'show_task': 'Show task bar (Desktop app only)',
    'select_screen': ' Click to select a screen or application to share',
    'default_screen': 'Allow drawing as screen share by default',
    'accept_msg': 'When l click into room or accept a request',
    'select_room': 'When l am pulled into a room (only possible with door open)',
    'add_team_member': 'Add team member'
  },
  'roomloginpopup': {
    'guest_msg': 'Hello guest!',
    'enter_name': 'Enter your name',
    'service_agree': 'By using this service you agree to our',
    'terms_service': 'Terms of service',
    'confirm_age_msg': 'and confirm that you are older than 16 years.',
    'are_you_sure': 'Are you sure?',
  },
  'user': {
    'user': 'Employee',
    'adminuser': 'Admin employee',
  },
  'admin':{
    'employee':'Employee',
    'onboarding':'Onboarding',
    'company_announcements':'Company updates',
    'wellness_initiatives':'Wellness initiatives',
    'teams':'Teams'
  }


}

export default en;