import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../i18n';
import { connect, useSelector, useDispatch} from "react-redux";
import { StoreClass } from '../../store/Store';
import { getEmpListReportingTo } from '../../services/UserActions';
import { NodeBuilderFlags } from 'typescript';
import { useLocation} from "react-router-dom";


const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
});

function CheckinsRightMenu(props: any) {
    let empGuid = localStorage.getItem('EmpGuid') as string;
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [reportingList, setReportingList] = useState<any>([] as any);
    const dispatch = useDispatch();
    const location = useLocation();
    const loc = location.state;

    useEffect(() => {
        const fetchReportingList = async () => {
            try {
                if (!user.empListReportingTo) {
                    await dispatch(getEmpListReportingTo(empGuid) as any);
                }

                const reportingList = (user.empListReportingTo || []).map((emp: any) => ({
                    value: emp.EmployeeId,
                    label: `${emp.FirstName} ${emp.LastName}`
                }));
                setReportingList(reportingList);

                const reportingToFirstNames = (user.empListReportingTo || []).map((emp: any) => {

                    return `${emp.ReportingToFirstName} ${emp.ReportingToLastName}` ? `${emp.ReportingToFirstName} ${emp.ReportingToLastName}` : "";
                });
                const reportingToString = reportingToFirstNames.join(", ");
                props.ReportingListMenu(reportingToString.trim());
                console.error("Error fetching reporting list rakesh:", reportingToFirstNames);

            } catch (error) {
                console.error("Error fetching reporting list:", error);
                // Handle error, e.g., dispatch an action or set state to indicate the error
            }
        };

        fetchReportingList();
    }, [user.empListReportingTo, props.ReportingListMenu, dispatch, empGuid, setReportingList]);




    console.log("getting data of reporting to,", user.empListReportingTo)
    useEffect(() => {

        if (user.empListReportingTo)
            setReportingList((user.empListReportingTo || []).map((emp: any) => ({ value: emp.EmployeeId, label: emp.FirstName + ' ' + emp.LastName })) || []);
        else setReportingList([])
    }, [user.empListReportingTo]);
    console.log("props", props)
    return (
        <div className="col-sm-4 mobile-none">
            <ul className="settingsMenu OnboardMenu" id="OBhexGrid">
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className={`OBhex ${props.path == 'checkins' ? 'active' : ''} ${props.path}`}>
                    <Link className="OBhexIn" to="/checkins" state={loc}>
                        <p> <span className="align-self-center">
                            <img src="assets/media/svg/plus-icon.svg" style={{ padding: '5px' }} />
                            <u className="OBhexTitle">{t('button.checkins')}</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <Link to={loc ? loc : "/produce"} className="OBhexIn">
                        <p> <span className="align-self-center">
                            <img src="/assets/media/images/logo.png" />
                        </span>
                        </p>
                    </Link>
                </li>
                <li className={`OBhex ${props.path == '/CheckinHistory' ? 'active' : ''}`}>
                    <Link className="OBhexIn" to="/CheckinHistory" state={loc}>
                        <p> <span className="align-self-center">
                            <img src="assets/media/svg/onboarding/avatar-setup.svg" />
                            <u className="OBhexTitle">{t('button.checkinhistory')}</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                {
                    reportingList.length > 0 ?
                        <li className={`OBhex ${props.path == '/TeamCheckinHistory' ? 'active' : ''}`}>
                            <Link to="/TeamCheckinHistory" state={loc} className="OBhexIn">
                                <p> <span className="align-self-center">
                                    <img src="assets/media/svg/onboarding/audio-video.svg" />
                                    <u className="OBhexTitle">{t('button.checkinhistoryteam')}</u> </span> </p>
                            </Link>
                        </li> :
                        <li className="OBhex cursor-default">
                            <a className="OBhexIn" ></a>
                        </li>}
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" >
                        <p> <span className="align-self-center">
                            <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7 7V1h1v6h6v1H8v6H7V8H1V7h6z" fill="#6264a5"></path>
                            </svg>
                        </span> </p>
                    </a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
                <li className="OBhex cursor-default">
                    <a className="OBhexIn" ></a>
                </li>
            </ul>
        </div>
    );
}

export default connect(mapStateToProps)(CheckinsRightMenu);